<template>
	<Nav />
	<router-view />
	<Footer />
</template>

<script setup>
import { watch } from 'vue';
// @ is an alias to /src
import Nav from '@/components/layouts/Nav';
import Footer from '@/components/layouts/Footer';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();
// import { useRouter } from 'vue-router';

// const router = useRouter();
// router.beforeEach((to, from, next) => {
// 	const lang = to;
// 	const asaa = from;
// 	console.log(lang,'abnf',asaa)
// 	next()
// });
watch(() => {
	if (locale.value == 'en') {
		document.getElementById('app').style.cssText = 'font-family: Lexend, sans-serif';
	} else {
		document.getElementById('app').style.cssText = 'font-family: mi, sans-serif';
	}
});
</script>

<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding: 0;
	margin: 0;
	width: 100%;
	// padding-top: 80px;

	#particles-js {
		width: 100vw;
		// height: calc(100% - 100px);
		position: absolute;
	}

	div {
		box-sizing: border-box;
	}
	input {
		outline: none;
	}
	p {
		margin: 0;
	}
	a {
		cursor: pointer;
		color: #ffffff;
		text-decoration: none;
	}
	ul {
		margin: 0;
		padding-left: 16px;
		box-sizing: border-box;
		li {
			box-sizing: border-box;
			margin-bottom: 13px;
		}
	}
	.button {
		cursor: pointer;
	}
}
</style>
