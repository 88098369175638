module.exports = {
    tabList: {
        list1: '首页',
        list2: '产品',
        list3: '案例',
        list4: '新闻',
        list5: '联系',
        list6: '团队',
        list7: '资助计划',
        list8: '博客',
    },
    home: {
        slogan: "让Web3触手可及",
        number1: '活跃用户',
        number2: '全球节点',
        number3: '线上线下',
        number4: '合作生态',
        box_title: '与M3 DAO一起发掘Web3潜力',
        box_content: "M3 DAO 是一个融合元宇宙、Layer 2 公链和数字资产管理的去中心化自治组织，以MarsVerse、MarsChain、MarsProtocol（MarsLab）三大板块为核心，M3 DAO 正在构建一个多元互动的 Web3 全新生态系统。在社区自治、多元化、创新、开放的价值理念下，M3 DAO将携手全球用户共同推动Web3与现实世界的深度连接，打造未来Web3版图。",
        introduce: 'U-topia 是世界上第一家 MediaFi 公司，拥有大量IP资源，通过区块链技术，将Web2与Web3结合打造头部级 MediaFi 平台。',
        helloArray: [
            '区块链技术',
            '去中心化金融',
            '去中心化应用',
            '非同质化代币',
            '去中心化自治组织',
            '智能合约',
            '基础设施和工具',
            '隐私和安全',
            '跨链解决方案',
            '元宇宙',
            '去中心化存储',
            '去中心化身份',
            '去中心化计算',
            '虚拟现实资产',
            '去中心化社交',
            '去中心化媒体',
            '去中心化预测市场',
            '去中心化治理'
        ],
        dq_title: '全球社区',
        blog: "投研报告",
        bottom_title: '探索Web3的无限可能',
        bottom_font1: 'Web3正在重塑世界，我们相信通过去中心化技术，我们将构建一个更加开放、公平和自主的数字世界。',
        bottom_font2: '与Web3同行，与创新同行',
        button_button: "联系我们"
    },
    Product: {
        mv1: "Avatar",
        mv2: "元宇宙",
        mv3: "星际殖民",
        mv4: "RWA平台",
        mc1: "Layer2",
        mc2: "DID",
        mc3: "基础设施",
        mc4: "配套生态",
        mp1: "MarsLab",
        mp2: "Web3投资与孵化",
        mp3: "数字支付方案",
        mp4: "DAO基金会",
    },
    Portfolio: {
        slogan1: "我们期待与拥有创新精神的项目或公司合作",
        slogan2: "点击按钮即可提交资料",
        submit: "提交项目",
        title: "案例",
        list1: {
            title: '数据隐私与安全',
            font: '利用Layer2与去中心化身份（DID）技术，确保用户数据的自主权和隐私保护。',
        },
        list2: {
            title: "社区自治",
            font: "全球用户，社区成员共同参与治理，实现民主决策。"
        },
        list3: {
            title: "高效区块链基础设施",
            font: "高性能的 Layer 2 解决方案，为大规模应用和高频交易保驾护航。"
        },
        list4: {
            title: "现实资产数字化",
            font: "支持现实资产的上链管理，实现透明、高效的资产管理。与400余家实体企业达成合作。"
        },
        list5: {
            title: "数字资产管理",
            font: "为全球用户和优质项目投研、投资、孵化等全面的数字资产管理服务，满足多样化需求。"
        },
        list6: {
            title: "项目孵化",
            font: "通过 MarsLab孵化平台，为新兴 Web3 项目提供资金、品牌、运营和技术支持。"
        }
    },
    news: {
        title: '新闻'
    },
    support: {
        title: '支持',
        font1: '如果您对我们有更好的建议，请向我们提出，我们会及时处理。',
        input1: '请输入标题',
        input2: '请输入问题',
        input3: '请留下您的电子邮件地址，我们的工作人员会回复您',
        input4: '提交成功',
        button2: '返回',
        button1: '提交',
    },
    team: {
        font1: 'M3 DAO团队',
        font2: 'M3 DAO团队由全球顶级的Web3行业领导者组成，致力于为全球用户带来独一无二的科技与创新体验，全面推动 Web3 的发展。我们期待，能与M3 DAO的全体成员携手重新定义去中心化技术的发展，并一同体验无与伦比的区块链变革。',
        item1: {
            name: 'EMMANUEL QUEZADA',
            title1: `M3 DAO 成员 `,
            title2: `IP & Branding Lead`,
            font: 'U-topia Ecosystem 的创始人，他从事区块链技术工作 7 年，是一名认证区块链专家，同时也是 KYC、CFT 和 AML 事务的区块链顾问。'
        },
        item2: {
            name: 'L.A',
            title1: `M3 DAO 早期成员 `,
            title2: `Ecosystem Lead`,
            font: 'BTC早期布道者、比特币矿场主、马来西亚企业家，拥有超过400家实体企业、多家上市公司总裁、全球社区领导者'
        },
        item3: {
            name: 'MICHAEL GORD',
            title1: `M3 DAO 成员 `,
            title2: `Investment Lead`,
            font: '连续创业者（3 次退出 + 3 次收购）、全球公民颠覆性技术投资者/顾问（80 多家投资组合公司/4 个种子期 -> 独角兽）、公共演讲者和金融家（7000 万美元以上直接融资、2.1 亿美元以上结构化融资），专注于数字资产和区块链技术的大规模采用。'
        },
        item4: {
            name: 'DARREN SMITH',
            title1: `M3 DAO 成员 `,
            title2: `IT Lead`,
            font: '视频游戏行业资深人士。在任天堂工作了 20 年，致力于 Nintendo 64、Wii、DSi、3DS 等游戏机以及应用程序和游戏的开发。2018 年加入 Epik Prime，致力于将 NFT 引入游戏。'
        },
        item5: {
            name: 'GC',
            title1: `M3 DAO 早期成员 `,
            title2: `Golden Carrot Capital 创始人`,
            font: '作为一名加密货币企业家，他在区块链领域拥有 8 年经验，并在 Binance 和 MEXC Global 等领先加密货币交易所担任要职，积累了丰富的金融专业知识。'
        },
        item6: {
            name: 'OWEN MA',
            title1: `M3 DAO 成员 `,
            title2: `IP Lead`,
            font: 'U-topia 生态系统运营和财务副总裁。在知识产权许可、资产融资、和奖励计划、音乐节和房地产方面拥有丰富的经验。他成功打造了 Web3 中最大的企业 B2B 生态系统，将游戏和品牌融为一体。'
        },
        item7: {
            name: 'ALEXIS SIRKIA',
            title1: `M3 DAO 成员 `,
            title2: `Financial Lead`,
            font: '一位经验丰富的企业家，在加密货币行业有着良好的业绩记录，他成功驾驭了不断变化的行业环境，从 XRP 的早期投资者发展成为领先的加密货币交易和做市公司，最终收购了 Yellow Group 旗下领先的区块链基础设施公司 OpenWare。'
        },
        item8: {
            name: 'Ference',
            title1: `M3 DAO 核心成员`,
            title2: `社区领袖`,
            font: "是一个拥有二十年经验的营销经验。对业务发展战略充满热情，拥有丰富的传统经济学、Web3和信息技术背景知识。并且组建了一个由全球经验组成的社区。"
        },
        item9: {
            name: 'James',
            title1: `M3 DAO 核心成员`,
            title2: `U-topia BD`,
            font: "毕业于阿莱德大学(University of Adelaide)，主修战略管理。深耕加密行业，拥有丰富得加密从业经验。曾于Bybit担任高管，在战略发展、社区拓展以及生态建设等方面颇有建树。"
        }
    },
    grant: {
        one: 'MarsLab 资助计划',
        two: '立即申请',
        title1: {
            bolder: 'MarsLab 资助',
            content: "该计划旨在通过培育和支持具有远见的项目，从而鼓励开发人员和团队积极参与基础设施建设，从而走在区块链和技术领域的最前沿。我们的目标不仅仅是向社区提供实用和创新的解决方案；我们的目标是对行业产生实质性影响，引导区块链技术的演进方向。"
        },
        title2: {
            font1: "该计划分为12个阶段，每个阶段的奖金为50万美元", font2: "第一期"
        },
        Goal: {
            title: "目标",
            content1: "成为区块链、去中心化应用程序(dApps) 、Web3 及相关技术领域的创新者和领导者。",
            content2: "支持项目从概念化到部署的每个阶段，确保它们产生重大影响。",
            content3: "培养一个资源丰富、充满活力的开发者、创造者和创新者社区，加强协作和知识交流。"
        },
        Grant: {
            title: "补助金类别",
            content1: "技术创新",
            content2: "工具",
            content3: "社区建设"
        },
        Qualification: {
            title: "资格",
            content1: "向所有基于区块链构建的项目开放，强调多链互操作性和创新。",
            content2: "参赛者必须提交原创作品。严禁抄袭。",
            content3: "提交的内容应包括详细的项目计划、演示（如果有）和团队背景。",
            content4: "项目必须表现出明确的实用性、创新性和潜在影响。"
        },
        Process: {
            title: "过程",
            content1: "提交",
            content2: "审查",
            content3: "投票",
            content4: "支持",
        },
        Funding: {
            title: "资金结构",
            content1: "资金将根据项目里程碑和可交付成果分阶段分配。",
            content2: "总奖池将在每个周期开始时公布，个别赠款金额根据项目需求和潜在影响而有所不同。",
            content3: "除了资金支持外，获奖者还将获得技术支持和营销推广，以确保项目成功。",
        },
        Community: {
            title: "社区参与和支持",
            content1: "M3社区",
            content2: "研讨会和工作坊",
            content3: "展示",
            content4: "此外，我们将定期跟进中标项目的进展情况，确保后续发展顺利。",
        },
    },
    grantForm: {
        title1: "MarsLab Grant 申请",
        title2: "请填写此表格进行申请。我们期待了解您的项目，以及我们如何帮助您。",
        input1: {
            place1: "项目名称",
            place2: "项目推特",
            place3: "项目网页",
            place4: "项目简介",
        },
        input2: {
            place1: "请用 50 个字符以内描述您的项目。它解决了什么问题？",
            place2: "它是如何运作的？",
        },
        input3: {
            label: "您的项目属于什么类别？",
        },
        input4: {
            label: "请说明您的项目阶段",
        },
        input5: {
            label: "请告诉我们相关团队的情况。有多少人？",
            label2: "核心团队成员的姓名、职位和背景。"
        },
        input6: {
            label: "请告诉我们您需要 MarsLab 提供什么样的支持",
        },
        input7: {
            label: "项目联系人",
            place1: "名字",
            place2: "邮箱",
            place3: "电报",
            place4: "钱包地址",
        },
        input8: {
            label: "您的推荐人信息（如果有)",
        },
        back: "返回",
        next: "下一页",
    },
    grantFinish: {
        font1: "申请提交",
        font2: "您提交的内容已收到，我们会进行审核。非常感谢您抽出时间填写！",
        button1: "返回 GRANT",
        button2: "提交新申请",
    },
    blog: {
        AMANews: "M3 DAO 资讯",
        item1: {
            title: 'MarsVerse x U-topia：深入了解MarsVerse生态系统和Avatar Alliance',
            topic: "此次活动概述了MarsVerse生态系统，正式启动了Avatar Alliance合作模式。详细介绍了MarsVerse & U-topia Avatar Alliance，并深入探讨了U-topia项目的未来发展以及U-coin的发展计划和代币经济学。",
            body: "本次活动深入介绍了Marsverse生态系统，并庆祝了Avatar Alliance合作模式的正式启动。活动中还介绍了向大家Marsverse x U-topia联名卡，让与会者一瞥U-topia项目的未来发展。会议最后详细讨论了U-coin的发展计划和代币经济学，阐明了项目的战略愿景和财务框架。",
            one: {
                title: "活动亮点：",
                ul: [
                    "为了庆祝M3 DAO和U-topia的合作，U-topia的首席执行官加入了直播，分享了对U-topia项目及其未来发展的见解。",
                    "此次活动还标志着 MarsVerse x U-topia Avatar Alliance及其相关空投活动的正式启动。",
                    "最后，为了解答大家对Utopia价值的疑问，U-topia的首席执行官详细讨论了U-coin的发展计划和代币经济学。"
                ],
            },
            two: {
                title: "关于Avatar Alliance：",
                ul: [
                    "在MarsVerse战略路线图的核心是Avatar Alliance计划，这是一项与富有远见的潜在合作伙伴合作的突破性举措。该计划旨在通过联合品牌的Avatar联盟推动MarsVerse合作伙伴的业务增长，增强品牌影响力，并促进与去中心化网络的互动。",
                    "Avatar Alliance计划预示着跨社区协同合作的新时代，通过战略联合品牌、多渠道营销和全球活动，推动MarsVerse及其合作伙伴的共同增长与创新。"
                ],
            },
            three: {
                title: "关于MarsVerse x U-topia联名卡：",
                ul: [
                    "MarsVerse x U-topia联名卡为投资者提供了奢华的福利和代币奖励。该联名卡的发布包括从7月1日至7月20日的空投活动，铸造特定Avatar的用户可获得额外奖励。由于持有这些卡片所带来的高价值、稀缺性和巨额奖励，这在我们的社区内引起了巨大的需求。这次活动显著提升了U-topia的Ucoin代币的价值，该代币总供应量为10亿枚。"
                ],
            },
        },
        item2: {
            title: "MarsVerse x U-topia：元宇宙的未来：下一次革命是什么？",
            topic: "此次讨论了在不同元宇宙平台之间创建无缝体验的挑战和机遇，审视了虚拟商品和服务市场在NFT之后的演变，并深入探讨了元宇宙将如何影响未来的社交互动和人际关系。",
            body: "此次讨论深入探讨了在不同元宇宙平台上创造无缝体验的挑战与机遇。并审视了超越NFT的虚拟商品和服务市场的演变，并探讨了元宇宙将如何塑造未来的社交互动和人际关系，全面展现了这一快速发展的数字景观的潜力和影响。",
            one: {
                title: "活动亮点：",
                ul: [
                    "MarsVerse的高层携手U-topia项目代表空降MarsVerse Twitter Space，分享了元宇宙中的挑战与机遇以及元宇宙的未来。",
                    "他们还就用户关于未来元宇宙演变的问题作出了解答。"
                ],
            },
            two: {
                title: "关于元宇宙：",
                ul: [
                    "元宇宙是一个集体虚拟共享空间，由虚拟增强的物理现实和物理持久的虚拟空间融合而成。它包括所有虚拟世界、增强现实和互联网的总和。在元宇宙中，用户可以与计算机生成的环境和其他用户实时互动，体验与现实世界完美融合的身临其境的数字环境。"
                ],
            },
            three: {
                title: "关于非同质化代币（NFTs）：",
                ul: [
                    "非同质化代币（NFTs）是使用区块链技术代表特定物品或内容（如艺术品、音乐、视频或虚拟地产）所有权的独特数字资产。与比特币或以太坊等加密货币不同，NFT是不可分割的，不能一对一互换，这使每个代币都独一无二且有价值。NFTs 提供了一种认证和证明数字创作所有权的方式，开启了新的数字交易和互动形式。"
                ],
            },
        },
        item3: {
            title: "探索 M3 DAO：愿景与创新",
            topic: "此次活动详细介绍了 M3 DAO 的产品和价值观，解释了 M3 DAO 如何实现社区治理，并讨论了其在塑造 Web3 行业未来中的作用。", body: "The discussion delved into the challenges and opportunities of creating seamless experiences across various metaverse platforms. It examined the evolution of virtual goods and services markets beyond NFTs and explored how the metaverse will shape future social interactions and relationships, offering a comprehensive look at the potential and impact of this rapidly evolving digital landscape.",
            body: "这是 M3 DAO 首次将其产品和价值观带给日本用户，并与他们进行交流。推特空间的热烈反响也表明，日本用户非常认同 M3 DAO 的核心价值观，并期待 M3 DAO 带来的元宇宙革命。这标志着 M3 DAO 成功打开日本市场的第一步，我们期待 M3 DAO 在日本的进一步发展。",
            one: {
                title: "活动亮点：",
                ul: [
                    "M3 DAO 的首次日语 AMA，M3 DAO 代表空降日本 KOL 的 Twitter Space，并分享了 M3 DAO 的愿景、使命和价值观，强调了社区治理对 M3 DAO 的重要性。",
                    "针对日本观众关于 M3 DAO 核心产品 MarsVerse、MarsChain 和 MarsProtocol 的问题进行了解答。"
                ],
            },
            two: {
                title: "关于M3 DAO的愿景、目标和使命？",
                ul: [
                    "愿景：成为全球最具影响力的投资和孵化 DAO，推动创新和可持续发展。",
                    "目标：孵化出市值排名全球前十的 Web3 独角兽项目。",
                    "使命： 通过去中心化的治理模式，汇聚全球资源，支持和孵化具有潜力的创新项目，推动技术进步和社会福祉。"
                ],
            },
            three: {
                title: "社区治理之于M3 DAO意味着什么？",
                ul: [
                    "M3 DAO 的社区治理允许成员参与决策过程，提出修改意见，并就关键问题进行投票。以上内容都是通过 MarsVerse 来实现。同时，MarsVerse 实施全球社区治理机制，使用户能够影响生态系统的发展和政策。"
                ],
            },
        },
        item4: {
            title: "M3 线上联播会议：U-Topia中文社区专场",
            topic: "本场活动介绍了M3 DAO生态合作项目U-Topia，以及即将推出MarsVerse x U-Topia 联名系列Avatar卡牌和相关空投活动放送！",
            body: "本次会议介绍了 U-Topia 作为 M3 DAO 的联名合作伙伴以及即将推出的 MarsVerse x U-Topia 联名卡。会议还强调了相关空投活动的发布细节，为激动人心的合作奠定了基础。",
            one: {
                title: "活动亮点：",
                ul: [
                    "为了庆祝M3 DAO和U-topia的合作，U-topia的首席执行官加入了直播，分享了对U-topia项目及其未来发展的见解。",
                    "此次活动还标志着 MarsVerse x U-topia Avatar Alliance及其相关空投活动的正式启动。"
                ],
            },
            two: {
                title: "关于M3 DAO：",
                ul: [
                    "M3 DAO 是一个去中心化的自治组织，专注于识别和支持高潜力的区块链初创企业。M3 DAO 利用其全球社区的集体智慧做出投资决策，为风险投资提供了一种更加民主和包容的方法。"
                ],
            },
            three: {
                title: "关于U-topia：",
                ul: [
                    "U-topia是世界上第一家MediaFi公司，它是GameFi、AI音乐和视频娱乐领域中全球创新IP授权的融合，背后支持的是包含7万种现实世界资产奖励的目录。U-topia利用了Web2中的顶级知识产权（IP），如职业球队的体育比赛内容、万代南梦宫等的视频内容，以及耐克等全球百强零售商的内容，同时还整合了Web3中的顶级NFT收藏品。"],
            },
        },
        item5: {
            title: "MarsVerse x Utopia生态分享会: M3 MarsVerse生态发展计划全面推进，Avatar联名系列正式上线",
            topic: "了解MarsVerse x U-topia Avatar联合品牌Avatar及促销活动。了解更多关于U-topia生态系统和U-coin代币经济学的信息。",
            body: "社区用户同U-topia代表一起，深入了解MarsVerse x U-topia联名卡及其精彩的推广活动。探索U-topia生态系统，并深入研究U-coin代币经济学，全面理解其战略影响。",
            one: {
                title: "活动亮点：",
                ul: [
                    "U-topia项目代表突袭直播间，分享了MarsVerse x U-topia联名Avatar及相关推广活动。",
                    "同时，还解答了用户关于U-topia生态系统和U-coin代币经济学的问题。"
                ],
            },
            two: {
                title: "关于MarsVerse x U-topia联名卡：",
                ul: [
                    "MarsVerse x U-topia联名卡为投资者提供了奢华的福利和代币奖励。此次发布包括从7月1日至7月20日的空投活动，铸造特定Avatar的用户可获得额外奖励。由于这些卡片所具有的高价值、稀缺性以及丰厚的奖励，在我们的社区中需求极为旺盛。这次活动大幅提升了U-topia的Ucoin代币的价值，该代币总供应量为10亿枚。"
                ],
            },
            three: {
                title: "关于U-coin：",
                ul: [
                    "代币符号： U-coin",
                    "总供应量： 10亿枚",
                    "上交易所日期： 8月初",
                    "U-coin 代币分配：",
                    "战略融资： 1.25%、团队/顾问： 17%、种子轮： 2.78%、社区分配： 7.5%、公开销售： 4.75%、生态系统： 35.4%、私人销售： 4.32%、合作伙伴： 8%、质押奖励： 3%、中心化交易所流动性： 10%、流动性提供者奖励： 2%、去中心化交易所流动性： 4%"
                ],
            },
        },
        item6: {
            title: "M3 火星中文社区共识大会：M3 DAO大人物来做客！",
            topic: "G.C Capital 创办人暨M3 DAO Investment Lead-GC及M3 DAO欧洲社区大咖成员Ference 空降直播间，分享加密市场近期投资趋势及社区共识搭建的关键！",
            body: "G.C Capital创始人兼M3 DAO投资主管GC，以及M3 DAO欧洲社区成员Ferenc，亲临现场，分享了近期加密市场投资趋势的见解，并探讨了建立社区共识的关键策略。他们的讨论为如何在不断变化的加密货币投资领域中砥砺前行，以及如何构建强大、团结的社区提供了独特的视角和宝贵的见解。",
            one: {
                title: "活动亮点：",
                ul: [
                    "GC Capital的创始人兼M3 DAO投资主管GC空降直播间，分享了他对近期加密市场投资趋势的专业见解。",
                    "M3 DAO欧洲社区的重要成员Ferenc展示了如何快速扩展社区并建立社区共识。"
                ],
            },
            two: {
                title: "什么是 Web3 投资？",
                ul: [
                    "Web3投资专注于资助和开发去中心化技术，这些技术构成了下一代互联网的基础。这包括区块链项目、加密货币、去中心化金融（DeFi）平台、非同质化代币（NFTs）等促进用户对数字资产和数据的所有权、隐私和控制权的技术。Web3投资旨在改变我们在线互动的方式，将权力从中心化实体转移到个人用户和社区手中。"
                ],
            },
            three: {
                title: "什么是社区共识？",
                ul: [
                    "社区共识是指在一个群体或社区内达成一致意见的过程，通常在去中心化和协作环境中使用。这一过程涉及开放沟通、参与和协商，以确保决策反映社区成员的集体意愿和利益。在区块链和Web3项目中，社区共识对于验证交易、实施变更以及维护网络内的信任和透明度至关重要。"
                ],
            },
        },
        item7: {
            title: "M3 火星中文社区共识大会：M3 DAO大人物来做客！",
            topic: "M3 DAO 奥地利社区长Harry携手欧美艺人CJ空降直播间，与大家共同分享社区拓展，社区共识搭建的经验。如何在30天内实现加密资产裂变式增长，只有要不要，没有能不能！",
            body: "M3 DAO奥地利社区负责人Harry和欧洲-美国艺人CJ共同参加了直播，分享了他们在社区扩展和共识建立方面的经验。他们讨论了在短短30天内实现加密资产爆炸性增长的策略，强调了“只问想不想，而非能不能”的心态。他们的见解为推动社区的快速和可持续增长提供了宝贵的指导。",
            one: {
                title: "活动亮点：",
                ul: [
                    "M3 DAO 奥地利社区负责人Harry空降直播间，分享了他在社区扩展和共识建立方面的经验。他讲解了如何在30天内实现加密资产的快速增长，强调“只问想不想，而非能不能”的心态。",
                    "CJ是一位欧美艺人，目前在M3非常积极全力布道，同时经营着跨越三大洲不同国家的社区。他也在直播中分享了他在资产增长方面的经验，并讨论了M3的各种可能性。"
                ],
            },
            two: {
                title: "什么是社区共识？",
                ul: [
                    "社区共识是指在一个群体或社区内达成一致意见的过程，通常在去中心化和协作环境中使用。这一过程涉及开放沟通、参与和协商，以确保决策反映社区成员的集体意愿和利益。在区块链和Web3项目中，社区共识对于验证交易、实施变更以及维护网络内的信任和透明度至关重要。"
                ],
            },
            three: {
                title: "关于M3 DAO：",
                ul: [
                    "M3 DAO将元宇宙、Layer 2公链和数字资产管理结合起来，创建了一个由社区治理、具有多样性、创新性和开放性的数字生态系统，连接Web3与现实世界。其三大主要产品包括：MarsVerse，一个专注于星际殖民的元宇宙生态系统；MarsChain，一个提供安全高效基础设施的Layer 2区块链解决方案；以及MarsProtocol，一个涵盖投资、DeFi、质押、资产管理和风险投资的核心金融协议。M3 DAO正在构建一个多样化和互动的Web3生态系统，邀请所有好奇和富有创意的人士共同参与塑造未来。"
                ],
            },
        },
        item8: {
            title: "M3火星全球社区共识大会： M3 DAO领导人来访！",
            topic: "M3 DAO的两位核心成员L.A.和Emmanuel将讨论MetaMars元宇宙生态计划和即将在8月进行的种子轮代币认购招募！",
            body: "M3 DAO 的两位核心成员 L.A. 和 Emmanuel 讨论了 MetaMars Metaverse 生态计划和即将于 8 月进行的种子轮代币认购招募。他们的见解为 M3 DAO 生态系统的战略愿景和机遇提供了宝贵的信息，为未来的发展和创新奠定了基础。",
            one: {
                title: "活动亮点：",
                ul: [
                    "M3 DAO 的两位核心成员 L.A. 和 Emmanuel 空降直播间，讨论 MetaMars Metaverse 生态计划。",
                    "针对用户关于即将在 8 月进行的种子轮代币认购招募的问题做出了解答。"
                ],
            },
            two: {
                title: "关于 MetaMars 元宇宙？",
                ul: [
                    "MetaMars是一个以火星为主题的Web3元宇宙平台。通过整合虚拟现实、加密经济学和社交网络，MetaMars开创了一种全新的数字互动和价值创造方式。这个创新的数字世界将火星探索与区块链技术结合，为用户提供了独特的沉浸式体验。",
                    "在MetaMars中，参与者可以探索精心打造的火星景观，建立自己的基地，并赚取奖励。同时，来自全球的星际旅行者协作探索宇宙的奥秘。"
                ],
            },
            three: {
                title: "关于 MCD 代币：",
                ul: [
                    "MCD代币是M3 DAO生态系统的原生实用代币。它用于治理、质押以及访问MarsVerse、MarsChain和MarsProtocol中的各种服务。"
                ],
            },
        }
    },
    footer: {
        one: {
            title: "生态",
            item1: "MarsVerse",
            item2: "MarsChain",
            item3: "MarsProtocol",
            item4: "Avatar",
            item5: "MarsLab",
            item6: "区块浏览器",
        },
        two: {
            title: "支持",
            item1: "关于我们",
            item2: "媒体资源包",
            item3: "联系我们",
        }
    }
}