import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: 'M3DAO'
    },
    component: Home,
  },
  {
    path: "/News",
    name: "news",
    meta: {
      title: 'news-M3DAO'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "news" */ "@/views/news.vue"),
  },
  {
    path: "/team",
    name: "team",
    meta: {
      title: 'team-M3DAO'
    },
    component: () =>
      import(/* webpackChunkName: "team" */ "@/views/team.vue"),
  },
  {
    path: "/support",
    name: "support",
    meta: {
      title: 'support-M3DAO'
    },
    component: () =>
      import(/* webpackChunkName: "support" */ "@/views/support.vue"),
  },
  {
    path: "/media",
    name: "media",
    meta: {
      title: 'media Kit-M3DAO'
    },
    component: () =>
      import(/* webpackChunkName: "media" */ "@/views/media.vue"),
  },
  {
    path: "/Portfolio",
    name: "portfolio",
    meta: {
      title: 'Portfolio-M3DAO'
    },
    component: () =>
      import(/* webpackChunkName: "portfolio" */ "@/views/Portfolio/index.vue"),
  },
  {
    path: "/grant",
    name: "grant",
    meta: {
      title: 'grant-M3DAO'
    },
    component: () =>
      import(/* webpackChunkName: "grant" */ "@/views/grant/index.vue"),
  },
  {
    path: "/coreteam",
    name: "coreteam",
    meta: {
      title: 'coreteam-M3DAO'
    },
    component: () =>
      import(/* webpackChunkName: "coreteam" */ "@/views/coreteam.vue"),
  },
  {
    path: "/Blog",
    name: "Blog",
    meta: {
      title: 'Blog-M3DAO'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "blog" */ "@/views/blog.vue"),
  },
  {
    path: "/avaNews",
    name: "avaNews",
    meta: {
      title: 'avaNews-M3DAO'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "avaNews" */ "@/views/avaNews.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
