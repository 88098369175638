module.exports = {
    tabList: {
        list1: 'ホーム',
        list2: '製品',
        list3: 'ポートフォリオ',
        list4: 'ニュース',
        list5: '連絡先',
        list6: 'チーム',
        list7: 'グラント',
        list8: 'ブログ',
    },
    home: {
        slogan: "web3 を指先に",
        number1: '活躍ユーザー',
        number2: 'グローバル・ノード',
        number3: ' オンライン・オフラ',
        number4: 'インエコシステム',
        box_title: 'M3 DAOでWeb3の可能性を可能性を探る',
        box_content: "M3 DAOはメタバース、レイヤー２パブリックチェーンと暗号資産管理を統合した分散型ガバナンス組織である。そして、MarsVerse、MarsChainと MarsProtocol (MarsLab) を中核とし、M3 DAOは新たなマルチ・インタラクションなWeb3エコシステムを構築している。コミュニティの自律性、多様性、革新性、開放性という価値観のもと、M3 DAOはグローバルユーザーと協力し、Web3と現実世界の深い繋がりを促進し、未来のweb3ランドスケープを創造する。",
        introduce: 'U-topiaは世界初のMediaFi企業であり、多数のIPリソースを持ち、ブロックチェーン技術によってWeb2とWeb3を融合させている。',
        helloArray: [
            'ブロックチェーン技術',
            'DeFi',
            'DApps',
            'NFTs',
            'DAOs',
            'スマートコントラクト',
            'インフラとツール',
            'プライバシーとセキュリティ',
            'クロスチェーン・ソリューション',
            'メタバース',
            '分散型スト分散型ストレージ',
            '分散型アイデンティティ',
            '分散型コンピューティング',
            'RWA',
            'ソーシャルファイ',
            'メディアファイ',
            '分散型マーケット予測',
            '分散型ガバナンス'
        ],
        dq_title: 'グローバル・コミュニティ',
        blog: "ブログ",
        bottom_title: 'Web3の可能性を探ろう',
        bottom_font1: 'Web3は世界を再構築し、分散型テクノロジーを通じて、よりオープンで公正かつ自律的なデジタル世界を構築すると信じています。',
        bottom_font2: 'Web3とともに歩み、イノベーションとともに歩む。',
        button_button: "連絡先"
    },
    Product: {
        mv1: "Avatar",
        mv2: "メタバース",
        mv3: "星間植民",
        mv4: "RWA プラットフォーム",
        mc1: "レイヤー2",
        mc2: "DID",
        mc3: "インフラ",
        mc4: "サポート・エコシステム",
        mp1: "MarsLab",
        mp2: "Web3 投資とインキュベーション",
        mp3: "デジタル決済ソリューション",
        mp4: "DAO 基金会",
    },
    Portfolio: {
        slogan1: "革新的なプロジェクトや企業とのコラボレーションを楽しみにしています。",
        slogan2: "ボタンをクリックして資料を送信してください。",
        submit: "プロジェクトを提出します",
        title: "ポートフォリオ",
        list1: {
            title: 'データ・プライバシーとセキュリティ',
            font: 'レイヤー2と分散型アイデンティティ（DID）技術を活用し、ユーザーデータの自律性とプライバシー保護を確保。',
        },
        list2: {
            title: "コミュニティ自律性",
            font: "グローバルユーザーとコミュニティメンバーがガバナンスに参加し、民主的な意思決定を実現。"
        },
        list3: {
            title: "効率的なブロックチェーンインフラ",
            font: "高性能なレイヤー2ソリューションは、大規模なアプリケーションと高頻度のトランザクションを保障。"
        },
        list4: {
            title: "現実資産のデジタル化",
            font: "現実資産のオンチェーン管理をサポートし、透明で効率的な資産管理を実現。 400社以上の不動産企業と提携。"
        },
        list5: {
            title: "暗号資産管理",
            font: "グローバルユーザーや多様なニーズに対応する高品質なプロジェクトに対し、研究、投資、インキュベーションなど総合的な暗号資産管理サービスを提供。"
        },
        list6: {
            title: "プロジェクト・インキュベーション",
            font: "MarsLabのインキュベーション・プラットフォームを通じて、新興のWeb3プロジェクトに資金、ブランディング、運営、技術サポートを提供。"
        }
    },
    news: {
        title: 'ニュース・連絡先'
    },
    support: {
        title: 'サポート',
        font1: 'もしあなたは私達に対して更に良い提案があるならば、私達に提出してください、私達は直ちに処理します。',
        input1: 'タイトル入力をお願いします。',
        input2: '質問を入力します。',
        input3: 'eメールアドレスを残してください。スタッフが返事します。',
        input4: '提出成功です',
        button2: '戻ります',
        button1: '提出します',
    },
    team: {
        font1: 'M3 DAOチーム',
        font2: 'M3 DAOチームは、ユニークなテクノロジーと革新的な体験を世界中のユーザーに提供し、Web3業界の成長を推進することに専念しするWeb3業界のトップリーダーで構成されています。 M3 DAOのメンバー全員と共に、分散型テクノロジーの発展を再定義し、ブロックチェーンの比類なき変革を共に体験できることを楽しみにしています。',
        item1: {
            name: 'EMMANUEL QUEZADA',
            title1: `M3 DAOメンバー `,
            title2: `IP&ブランディング・リード`,
            font: 'U-topiaエコシステムの創設者であり、ブロックチェーン技術に7年間携わり、認定されたブロックチェーン・エキスパートであると同時に、KYC、CFT、AML事務向けのブロックチェーン・コンサルタントでもあります。'
        },
        item2: {
            name: 'L.A',
            title1: `M3 DAO初期メンバー `,
            title2: `エコシステム・リード`,
            font: 'BTC 初期エバンジェリスト、ビットコインマイニングのオーナー、400以上のビジネス実体を持つマレーシアの起業家、複数の上場企業の社長、グローバル・コミュニティ・リード'
        },
        item3: {
            name: 'MICHAEL GORD',
            title1: `M3 DAOメンバー `,
            title2: `投資リード`,
            font: 'シリアルアントレプレナー (3 回の撤退 + 3 回の買収)、グローバルシチズンを革新するテクノロジーの投資家/アドバイザー (80 社以上のポートフォリオ企業/シード4社 -> ユニコーン)、講演者および金融家 ( 7,000 万の直接融資、2 億 1,000 万米ドルを超えるストラクチャードファイナンス) 、暗号資産とブロックチェーン技術の大規模な導入に取り組んでいます。'
        },
        item4: {
            name: 'DARREN SMITH',
            title1: `M3 DAOメンバー `,
            title2: `ITリード`,
            font: 'ゲーム業界のベテラン。 任天堂で20年間、Nintendo 64、Wii、DSi、3DSなどのゲーム機やアプリ、ゲームの開発に携わる。2018年にエピックプライムに入社し、NFTをゲームに取り入れる仕事に従事した。'
        },
        item5: {
            name: 'GC',
            title1: `M3 DAO初期メンバー 早期成员 `,
            title2: `Golden Carrot Capitalの創始人`,
            font: '暗号資産起業家として、ブロックチェーン業界の８年間の経験を持ち、BinanceやMEXC Globalといった主要な暗号通貨取引所で要職を歴任し、金融に関する豊富な専門知識を持っています。'
        },
        item6: {
            name: 'OWEN MA',
            title1: `M3 DAOメンバー `,
            title2: 'IPリード',
            font: 'U-topiaエコシステム運営・財務担当副社長。 IPライセンシング、アセットファイナンス、リワードプログラム、フェスティバル、不動産の分野で豊富な経験を持つ。 ゲームとブランドを統合し、Web3最大のエンタープライズB2Bエコシステムの構築に成功した。'
        },
        item7: {
            name: 'ALEXIS SIRKIA',
            title1: `M3 DAOメンバー `,
            title2: `財務リード`,
            font: '暗号通貨業界において実績のあるベテラン起業家であり、XRPの初期投資家から、暗号通貨取引とマーケットメイクのリーディングカンパニーに成長し、Yellow Groupが所有するブロックチェーンインフラのリーディングカンパニーであるOpenWareの買収に至った。'
        },
        item8: {
            name: 'フェレンツ',
            title1: `M3 DAOのコアメンバー`,
            title2: `コミュニティリーダー`,
            font: "20年の経験を持つマーケティングの専門家。ビジネス開発戦略に情熱を注ぎ、伝統的な経済学、Web3、情報技術に関する豊富な背景知識を持つ。グローバルな経験で構成されるコミュニティを構築した。"
        },
        item9: {
            name: 'ジェームズ',
            title1: `M3 DAOのコアメンバー`,
            title2: "U-topia BD",
            font: "アデレード大学卒業、戦略経営を専攻。暗号通貨業界に深く根ざし、豊富な経験を持つ。Bybitの元幹部として、戦略的発展、コミュニティ拡大、エコシステム構築において顕著な成果を上げる。"
        }
    },
    grant: {
        one: 'MarsLabグラント企画',
        two: '今すぐ申し込む',
        title1: {
            bolder: 'MarsLabグラント',
            content: "この企画は、先見性のあるプロジェクトを育成・支援することで、開発者やチームがインフラ構築に積極的に参加することを促し、ブロックチェーンとテクノロジー分野の最前線に立つことを目指しています。 私たちの目標は、実用的で革新的なソリューションをコミュニティに提供するだけでなく、業界に大きな影響を与え、ブロックチェーン技術の進化の方向性を導くことです。"
        },
        title2: {
            font1: "企画は12のフェーズに分かれており、各フェーズに50万ドルの賞金が用意されている。", font2: "フェーズ 1"
        },
        Goal: {
            title: "目標",
            content1: "ブロックチェーン、分散型アプリケーション（dApps）、Web3および関連技術のイノベーターであり、リーダーであること。",
            content2: "プロジェクトが大きな影響力を持つように、構想から展開までのあらゆる段階でプロジェクトをサポートする。",
            content3: "開発者、クリエイター、イノベーターのリソース豊富で活気あるコミュニティを育成し、コラボレーションと知識交換を強化する。"
        },
        Grant: {
            title: "グラント種類",
            content1: "技術革新",
            content2: "ツール",
            content3: "コミュニティ建設"
        },
        Qualification: {
            title: "資格",
            content1: "マルチチェーンの相互運用性とイノベーションに重点を置き、ブロックチェーン上で構築されたすべてのプロジェクトが対象。",
            content2: "参加者はオリジナルの作品を提出しなければならない。 盗作は厳禁。",
            content3: "詳細なプロジェクト計画、デモ（もしあれば）、チームの経歴を提出すること。",
            content4: "プロジェクトは、明確な実用性、革新性、潜在的な影響力を実証しなければならない。"
        },
        Process: {
            title: "プロセス",
            content1: "提出",
            content2: "審査",
            content3: "投票",
            content4: "サポート",
        },
        Funding: {
            title: "資金構成",
            content1: "資金は、プロジェクトのマイルストーンと成果物に基づいて段階的に配分される。",
            content2: "助成金の総額は各フェーズの初めに発表され、個別の助成金額はプロジェクトのニーズや潜在的な影響力によって異なる。",
            content3: "資金援助に加え、受賞者にはプロジェクトの成功を確実にするための技術支援とマーケティング支援も行われる。",
        },
        Community: {
            title: "コミュニティへの参加とサポート",
            content1: "M3コミュニティ",
            content2: "セミナーとワークショップ",
            content3: "展示",
            content4: "さらに、成功したプロジェクトの進捗状況を定期的にフォローアップし、これから、スムーズに展開することを確保する。",
        },
    },
    grantForm: {
        title1: "MarsLab グラント申し込み",
        title2: "このフォームに必要事項をご記入の上、お申し込みください。 あなたのプロジェクトについて、そして私たちがどのようにお役に立てるか、教えていただけることを楽しみにしています。",
        input1: {
            place1: "プロジェクト名",
            place2: "プロジェクト・ツイート",
            place3: "プロジェクト・ウェブサイト",
            place4: "プロジェクトの紹介",
        },
        input2: {
            place1: "50文字以内であなたのプロジェクトを説明してください。どうの問題を解決しましたか？",
            place2: "どうやって運用しますか？",
        },
        input3: {
            label: "どうのジャンルのプロジェクトでしょうか？",
        },
        input4: {
            label: "あなたのプロジェクトはどの段階にいますか？",
        },
        input5: {
            label: "チームの情報を教えてください。何人いますか？",
            label2: "コアチームメンバーの名前、職位、バックグラウンドを教えてください"
        },
        input6: {
            label: "MarsLabからのサポートについてお聞かせください。",
        },
        input7: {
            label: "プロジェクト連絡先",
            place1: "名前",
            place2: "メールアドレス",
            place3: "テレグラム",
            place4: "ウォレットアドレス",
        },
        input8: {
            label: "招待者の情報（オプション)",
        },
        back: "戻す",
        next: "次へ",
    },
    grantFinish: {
        font1: "提出を申請します",
        font2: "ご提出いた内容は届きました。ありがとうございました！",
        button1: "グラントへ戻す",
        button2: "新申請を申し込み",
    },
    blog: {
        AMANews: "AMA 情報",
        item1: {
            title: "MarsVerse x U-topia：MarsVerseエコシステムとAvatar Allianceの洞察",
            topic: "イベントでは、Marsverseエコシステムの概要、Avatar Allianceの公式発表、Marsverse x U-topia Avatar Allianceの紹介、U-topiaプロジェクトの今後の発展に関する洞察、そしてU-coin開発計画とトークノミクスについての詳細な議論が行われました。",
            body: "今回のイベントでは、MarsVerseのエコシステムを詳しく紹介し、Avatar Allianceというパートナーシップ・モデルの正式ローンチを祝いました。 また、Marsverse x U-topia Avatar Allianceも紹介され、参加者はU-topiaプロジェクトの将来のランドスケープを垣間見ることができました。 セッションの最後には、U-coinの開発計画とトークンエコノミクスに関する詳細なディスカッションが行われ、プロジェクトの戦略的ビジョンと財務的枠組みが明確になりました。",
            one: {
                title: "イベントのハイライト:",
                ul: [
                    "M3 DAOとU-topiaのパートナーシップを記念して、U-topiaのCEOがMarsVerseのTwitter Spaceに招待され、U-topiaプロジェクトとその将来の発展についてを共有しました。",
                    "また、Avatar Allianceのパートナーシップモデルの開始を発表し、MarsVerse x U-topia Avatar Allianceを紹介しました。",
                    "最後に、U-topiaの価値に関する質問に答えるため、U-topiaのCEOがU-coinの開発計画とトークンエコノミクスについて詳しく語りました。"
                ],
            },
            two: {
                title: "Avatar Allianceについて:",
                ul: [
                    "MarsVerseの戦略的ロードマップの中心にあるのが、Avatar Allianceプログラムです。これは、先見性のある潜在的なパートナーと協力するための画期的な取り組みです。 このプログラムは、共同ブランドのAvatar Alliancesを通じてMarsVerseパートナーのビジネスの成長を促進し、ブランドの存在感を高め、分散型ネットワークとのエンゲージメントを促進するように設計されています。",
                    "Avatar Allianceプログラムは、コミュニティ共通協力の新時代の到来を告げるもので、戦略的な共同ブランディング、マルチチャネル・マーケティングとグローバル・キャンペーンを通じて、MarsVerseとそのパートナーに共通の成長とイノベーションをもたらします。"
                ],
            },
            three: {
                title: "MarsVerse x U-topia Avatar Alliancesについて：",
                ul: [
                    "MarsVerse x U-topia Avatar Alliancesは、投資家に豪華な特典とトークン報酬を提供します。 この共Avatar Alliancesは、7月1日から7月20日までエアドロップ・キャンペーンを実施し、特定のAvatarをミントすることで追加報酬を得ることができます。 これは、これらのAvatarを保有することに関連する高い価値、希少性、および莫大な報酬により、私たちのコミュニティ内で大きな需要を生み出しました。 このイベントにより、U-topiaのUcoinトークンの価値は大幅に上昇しました。"
                ],
            },
        },
        item2: {
            title: "MarsVerse x U-topia：メタバースの未来：次の革命は何か？",
            topic: "ディスカッションでは、異なるメタバースプラットフォーム間でシームレスな体験を創出する際の課題と機会について検討し、NFTを超えたバーチャル商品とサービス市場の進化を繰り返し、メタバースが将来の社会的相互作用と人間関係にどのような影響を与えるかについて深く掘り下げました。",
            body: "今回のイベントでは、さまざまなメタバースのプラットフォーム間でシームレスな体験を創造するための課題と機会について掘り下げた。 また、NFTを超えるバーチャルグッズやサービスの市場の進化に注目し、メタバースが社会的な相互作用や人間関係の未来をどのように形作るかを探り、急速に進化するこのデジタル・ランドスケープの可能性と影響について包括的なイメージを提示した。",
            one: {
                title: "イベントのハイライト:",
                ul: [
                    "MarsVerseのエグゼクティブがU-topiaプロジェクトの代表者とともにMarsVerseのTwitterスペースにサプライズ登場し、メタバースにおける課題と機会、そしてメタバースの未来について話し合いました。",
                    "また、メタバースの将来の進化に関するユーザーの質問にも答えました。"
                ],
            },
            two: {
                title: "メタバースについて:",
                ul: [
                    "メタバースは、仮想現実と拡張現実の融合によって生まれたデジタル世界です。この空間では、ユーザーがアバターを通じてリアルタイムで他のユーザーと交流し、コンテンツを作成し、ビジネスを行うことができます。元宇宙は、インターネットの次なる進化として、エンターテイメント、教育、そして商業活動の新しい可能性を提供し、現実とデジタルが一体となる未来を描いています。"
                ],
            },
            three: {
                title: "NFTsについて:",
                ul: [
                    "NFT（非代替性トークン）は、ブロックチェーン技術を基盤としたデジタル資産で、唯一無二の価値を持つデジタルアイテムです。各NFTは、デジタルアート、音楽、映像、ゲーム内アイテムなどの所有権を表しており、偽造不可能であり、元の作成者や所有者を確認できる特性を持っています。NFTは、デジタルコンテンツの収集や取引の方法を根本的に変革し、新しいクリエイティブエコノミーの構築に貢献しています。"
                ],
            },
        },
        item3: {
            title: "M3 DAOを探索：ビジョンとイノベーション",
            topic: "M3 DAOの製品と価値観についての詳細な概要が提供され、M3 DAOがどのようにしてコミュニティガバナンスを実現しているかが説明され、また、Web3業界の未来に対するM3 DAOの貢献についても議論が行われました。",
            body: "これは、M3 DAO が初めてそのプロダクトと価値観を日本のユーザーに紹介し、交流を行った重要な機会でした。Twitter スペースでの熱い反響は、日本のユーザーが M3 DAO のコア価値観に強く共感し、M3 DAO がもたらすメタバース革命に大きな期待を寄せていることを示しています。これにより、M3 DAO は日本市場への成功の第一歩を踏み出し、今後の日本におけるさらなる発展が期待されます。",
            one: {
                title: "イベントのハイライト:",
                ul: [
                    "M3 DAO初の日本語AMAが開催され、M3 DAOの代表者が日本のKOLによるTwitter Spaceに参加し、M3 DAOのビジョン、使命、価値観について共有しました。特に、コミュニティガバナンスがM3 DAOにとって重要さを強調しました。",
                    "日本の聴衆から寄せられたM3 DAOの主要プロダクトであるMarsVerse、MarsChain、MarsProtocolに関する質問にも回答しました。"
                ],
            },
            two: {
                title: "M3 DAOのビジョン、目標、使命とは？",
                ul: [
                    "ビジョン：革新と持続可能な発展を推進し、世界で最も影響力のある投資およびインキュベーションDAOになることを目指します。",
                    "目標：世界中で時価総額トップ10に入るWeb3ユニコーンプロジェクトをインキュベートすること。",
                    "使命：分散型ガバナンスモデルを通じて、世界中のリソースを結集し、潜在能力のある革新的なプロジェクトを支援・インキュベートし、技術の進歩と社会福祉を促進すること。"
                ],
            },
            three: {
                title: "M3 DAOにおけるコミュニティガバナンスとは何か？",
                ul: [
                    "M3 DAOのコミュニティガバナンスは、メンバーが意思決定プロセスに参加し、修正提案を行い、重要な問題に対して投票することを可能にします。これらのすべては、MarsVerseを通じて実現されています。また、MarsVerseはグローバルコミュニティガバナンスメカニズムを実施しており、ユーザーがエコシステムの発展や政策に影響を与えることができるようにしています。"
                ],
            },
        },
        item4: {
            title: 'M3オンライン会議：U-Topia中国コミュニティ特別セッション',
            topic: "今回のセッションでは、M3 DAOの共同ブランドパートナーであるU-Topiaを紹介し、近日開催予定のMarsVerse x U-Topia共同ブランドシリーズのAvatarと関連エアドロップイベントのリリースについてもお知らせしました！",
            body: "今回の会議では、M3 DAOの共同ブランドパートナーとしてのU-Topiaと、近々リリースされるMarsVerse x U-Topiaの共同ブランドカードが紹介されました。 そして、関連するエアドロップ・キャンペーンの詳細も紹介され、エキサイティングなコラボレーションの基盤が築かれました。",
            one: {
                title: "イベントのハイライト：",
                ul: [
                    "M3 DAOとU-Topiaの提携を祝うため、U-TopiaのCEOがライブに参加し、U-Topiaプロジェクトとその将来の展望についての見解を共有しました。",
                    "このイベントは、MarsVerse x U-Topia Avatar Allianceおよび関連するエアドロップキャンペーンの正式な開始をも意味します。"
                ],
            },
            two: {
                title: "M3 DAOについて：",
                ul: [
                    "M3 DAO は、高い潜在力を持つプロジェクトスタートアップを特定し、支援することに焦点を当てた分散型自律組織です。M3 DAO は、グローバルコミュニティの集合知を活用して投資決定を行い、より民主的で包括的なベンチャーキャピタルの方法を提供します。"
                ],
            },
            three: {
                title: "U-topiaについて：",
                ul: [
                    "U-topiaは、世界初のMediaFi企業であり、GameFi、AI音楽、ビデオエンターテインメント分野でのグローバルな革新的IPライセンスの融合を実現しています。現実世界の7万種類の資産リワードを含むカタログがサポートされています。U-topiaは、プロスポーツチームの試合コンテンツやバンダイナムコなどのビデオコンテンツ、そしてナイキなどのグローバルトップ100リテーラーのコンテンツなど、Web2の主要な知的財産（IP）を活用しつつ、Web3のトップNFTコレクションも統合しています。"
                ],
            },
        },
        item5: {
            title: "MarsVerse x Utopia エコ・シェアリング会議：M3 MarsVerseエコシステム開発プログラムが開始、Avatar共同ブランドコレクションを発表",
            topic: "今回のイベントでは、MarsVerse x U-topia Avatar Allianceとプロモーションについてを紹介しました。そして、U-topiaエコシステムとU-coinのトークノミクスについてさらに詳しい情報を共有しました。",
            body: "MarsVerse x U-topia Avatar Alliancesとそのエキサイティングなプロモーションについて、U-topiaの担当者と一緒に了解しました。 U-topiaのエコシステムを探検し、U-coinトークンエコノミクスを掘り下げ、その戦略的インパクトを完全に理解します。",
            one: {
                title: "イベントのハイライト:",
                ul: [
                    "U-topiaプロジェクトの代表がライブ配信にサプライズ登場し、MarsVerse x U-topia Avatar Alliancesと関連プロモーション・キャンペーンを紹介しました。",
                    "同時に、U-topiaエコシステムとU-coinトークンエコノミクスに関するユーザーの質問にも答えました。"
                ],
            },
            two: {
                title: "MarsVerse x U-topia Avatar Alliancesについて：",
                ul: [
                    "MarsVerse x U-topia Avatar Alliancesは、投資家に豪華な特典とトークン報酬を提供します。 また、7月1日から7月20日まで、特定のAvatarをミントすることで追加報酬が得られるエアドロップ・キャンペーンを実施します。 これらのAvatarは高価値、希少性、及び豊かな報酬が連れてくるにより、コミュニティの間で非常に高い需要があります。 このキャンペーンにより、U-topiaのUcoinトークンの価値は大幅に上昇しました。"
                ],
            },
            three: {
                title: "U-coinについて:",
                ul: [
                    "トークン・シンボル：U-coin",
                    "総供給量: 10億枚",
                    "上場日： 8月の前半",
                    "U-coin トークン配布：",
                    "戦略的資金調達： 1.25%、チーム／コンサルタント： 17%、シードラウンド： 2.78%、コミュニティ配分： 7.5%、通常販売： 4.75%、エコシステム： 35.4%、プライベートセールス： 4.32%、パートナー： 8%、スティキング報酬 ： 3%、CEXの流動性： 10%、LP奨励： 2%、DEX の流動性： 4%"
                ],
            },
        },
        item6: {
            title: "M3 火星中国語コミュニティ専門会議: M3 DAOの大物がゲストとして登場！",
            topic: "G.C Capitalの創設者であり、M3 DAOの投資リードであるGC氏と、M3 DAOの欧州コミュニティメンバーであるFerenceが立ち寄り、最近の暗号市場における投資トレンドとコミュニティコンセンサス構築の鍵について共有しました！",
            body: "G.C Capitalの創設者でありM3 DAOの投資責任者であるGCと、M3 DAO欧州コミュニティのメンバーであるFerencが出席し、暗号市場における最近の投資動向に関する洞察を共有し、コミュニティのコンセンサスを構築するための重要な戦略について議論した。 彼らのディスカッションは、刻々と変化する暗号通貨投資の展望を切り開く方法と、強力で団結したコミュニティを構築する方法について、ユニークな視点と貴重な洞察を提供した。",
            one: {
                title: "イベントのハイライト:",
                ul: [
                    "GC Capitalの創設者であり、M3 DAOの投資責任者であるGCが生配信に立ち寄り、暗号市場における最近の投資動向について専門的な見識を披露した。 ",
                    "M3 DAO欧州コミュニティの主要メンバーであるFerencは、コミュニティを急速に拡大し、コミュニティのコンセンサスを構築する方法を実演しました。"
                ],
            },
            two: {
                title: "Web3投資とは何か？",
                ul: [
                    "Web3投資は、次世代のインターネットの基盤を成す分散型技術の発展を支援することを目的としています。これには、ブロックチェーン技術、暗号通貨、分散型金融（DeFi）、非代替性トークン（NFT）など、デジタル資産やデータの所有権、プライバシー、制御権をユーザーに提供する技術が含まれます。Web3投資は、オンラインの相互作用を変革し、中心化された権力から個人やコミュニティへと権力を移すことを目指しています。"
                ],
            },
            three: {
                title: "コミュニティのコンセンサスとは？",
                ul: [
                    "コミュニティのコンセンサスとは、特定のコミュニティやグループ内での意見や決定の合意を指します。これは、メンバー全員の意見を尊重し、共有された目標や価値観に基づいて合意を形成するプロセスです。特に分散型ネットワークやブロックチェーン技術の分野では、コミュニティのコンセンサスはネットワークの運営や決定において重要な役割を果たし、全ての参加者が公平に意見を反映できるようにする仕組みです。"
                ],
            },
        },
        item7: {
            title: "M3 火星中国語コミュニティ専門会議: M3 DAOの大物がゲストとして登場！",
            topic: "M3 DAOオーストリアコミュニティのチーフであるHarry氏と、ヨーロッパおよびアメリカのアーティストであるCJ氏がライブ配信に参加し、コミュニティ拡大やコミュニティコンセンサス構築の経験を共有しました。30日以内に暗号資産の急成長を実現する方法についても議論が行われ、「できるかどうかではなく、やるかどうか」という彼の心構えを共有しました。",
            body: "M3 DAOオーストリアコミュニティのリーダーであるHarryと、欧米のアーティストであるCJがライブに参加し、コミュニティの拡大とコンセンサスの構築に関する彼らの経験を共有しました。彼らは、わずか30日間で暗号資産を爆発的に成長させる戦略について議論し、「できるかどうかではなく、やりたいかどうかを問う」という心構えを強調しました。彼らの洞察は、コミュニティの迅速かつ持続可能な成長を推進するための貴重な指針となりました。",
            one: {
                title: "イベントのハイライト:",
                ul: [
                    "M3 DAOオーストリアコミュニティのリーダーであるHarryがライブに登場し、コミュニティの拡大とコンセンサスの構築に関する彼の経験を共有しました。彼は、30日以内に暗号資産を急速に成長させる方法を説明し、「できるかどうかではなく、やりたいかどうかを問う」という彼の心構えを強調しました。",
                    "CJは欧米のアーティストで、現在M3において非常に積極的に活動し、3つの大陸の国々でコミュニティを運営しています。彼もライブに参加し、資産成長に関する経験を共有し、M3のさまざまな可能性について議論しました。"
                ],
            },
            two: {
                title: "コミュニティのコンセンサスとは？",
                ul: [
                    "コミュニティのコンセンサスとは、特定のコミュニティやグループ内での意見や決定の合意を指します。これは、メンバー全員の意見を尊重し、共有された目標や価値観に基づいて合意を形成するプロセスです。特に分散型ネットワークやブロックチェーン技術の分野では、コミュニティのコンセンサスはネットワークの運営や決定において重要な役割を果たし、全ての参加者が公平に意見を反映できるようにする仕組みです。"
                ],
            },
            three: {
                title: "M3 DAO について",
                ul: [
                    "M3 DAOは、メタバース、レイヤー2パブリックチェーン、暗号資産管理を組み合わせ、コミュニティガバナンス、多様性、革新性、そしてオープン性を備えたデジタルエコシステムを構築し、Web3と現実世界をつなげています。主な3つのプロダクトには、星間植民をテーマにしたメタバースエコシステム「MarsVerse」、安全で効率的なインフラを提供するレイヤー2ブロックチェーンソリューション「MarsChain」、投資、DeFi、ステーキング、資産管理、ベンチャーキャピタルを網羅するコア金融プロトコル「MarsProtocol」が含まれます。M3 DAOは、多様でインタラクティブなWeb3エコシステムを構築しており、未来を共に形作るために、好奇心旺盛でクリエイティブなすべての人々を招待しています。"
                ],
            },
        },
        item8: {
            title: "M3 火星グローバル・コミュニティ専門会議：M3 DAOの大物がゲストとして登場！",
            topic: "M3 DAOのコアメンバーであるL.A.氏とEmmanuel氏が、MetaMarsのメタバースエコシステム企画と、8月に予定されているシードラウンドのトークンサブスクリプション募集について議論しました。",
            body: "M3 DAOのコアメンバーであるL.A.とエマニュエルの2人が、MetaMarsのメタバースエコシステム企画と8月に予定されているシードラウンドトークンサブスクリプション募集について語ります。 彼らの洞察は、M3 DAOエコシステムの戦略的ビジョンと機会に関する貴重な情報を提供し、将来の成長と革新の舞台を整えます。",
            one: {
                title: "イベントのハイライト:",
                ul: [
                    "M3 DAOの2人の主要メンバーであるL.A.とEmmanuelがライブ配信に登場し、MetaMars のメタバース・エコシステム企画について議論しました。",
                    "8月に予定されているシードラウンドトークンサブスクリプション募集に関するユーザーからの質問にも回答しました。"
                ],
            },
            two: {
                title: "MetaMarsメタバースについて:",
                ul: [
                    "MetaMarsは、火星をテーマにしたWeb3メタバースプラットフォームです。仮想現実、暗号経済学、ソーシャルネットワークを統合することで、MetaMarsはデジタルインタラクションと価値創造の新しい方法を切り開いています。この革新的なデジタル世界は、火星探査とブロックチェーン技術を組み合わせ、ユーザーにユニークで没入感のある体験を提供します。",
                    "MetaMarsでは、参加者は火星の風景を探索し、自分の基地を築き、報酬を得ることができます。同時に、世界中から集まった星間旅行者たちが協力して宇宙の謎を解き明かしていきます。"
                ],
            },
            three: {
                title: "MCD トークンについて：",
                ul: [
                    "MCDトークンは、M3 DAOエコシステムのネイティブユーティリティトークンです。これは、ガバナンス、ステーキング、およびMarsVerse、MarsChain、MarsProtocol内のさまざまなサービスへのアクセスに使用されます。"
                ],
            },
        }
    },
    footer: {
        one: {
            title: "エコシステム  ",
            item1: "MarsVerse",
            item2: "MarsChain",
            item3: "MarsProtocol",
            item4: "Avatar",
            item5: "MarsLab",
            item6: "ブロック・ブラウザー",
        },
        two: {
            title: "サポート",
            item1: "M3 DAOについて",
            item2: "メディア・キット",
            item3: "連絡先",
        }
    }
}