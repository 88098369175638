module.exports = {
    tabList: {
        list1: 'Home',
        list2: 'Produkte',
        list3: 'Portfolio',
        list4: 'News',
        list5: 'Kontakt',
        list6: 'A-team.',
        list7: 'Zuschuss',
        list8: 'Blog',
    },
    home: {
        slogan: "Web3 immer zur Hand.",
        number1: '活跃用户',
        number2: '全球节点',
        number3: '线上线下',
        number4: '合作生态',
        box_title: '与M3 DAO一起发掘Web3潜力',
        box_content: "M3 DAO 是一个融合元宇宙、Layer 2 公链和数字资产管理的去中心化自治组织，以MarsVerse、MarsChain、MarsProtocol（MarsLab）三大板块为核心，M3 DAO 正在构建一个多元互动的 Web3 全新生态系统。在社区自治、多元化、创新、开放的价值理念下，M3 DAO将携手全球用户共同推动Web3与现实世界的深度连接，打造未来Web3版图。",
        introduce: 'U-topia 是世界上第一家 MediaFi 公司，拥有大量IP资源，通过区块链技术，将Web2与Web3结合，打造U-topia 是世界上第一家MediaFi 公司，',
        helloArray: [
            'Blockchain-Technologie',
            'DeFi',
            'DApps',
            'NFTs',
            'DAOs',
            'Smart Contracts',
            'Infrastruktur und Tools',
            'Datenschutz und Sicherheit',
            'Cross-Chain-Lösungen',
            'Metaverse',
            'Dezentrale Speicherung',
            'Dezentrale Identität',
            'Dezentrales Computing',
            'RWA',
            'SocialFi',
            'MediaFi',
            'Dezentrale Prognosemärkte',
            'Dezentrale Regieren'
        ],
        blog: "Der Blog",
        dq_title: 'Globale Gemeinschaft',
        bottom_title: 'Entdecken Sie die unendlichen Möglichkeiten von Web3',
        bottom_font1: 'Web3 verändert die Welt und wir glauben, dass wir durch dezentrale Technologie eine offenere, gerechtere und autonomere digitale Welt aufbauen werden.',
        bottom_font2: 'Gehen Sie mit Web3, gehen Sie mit Innovation.',
        button_button: "Kontakt"
    },
    Product: {
        mv1: "Benutzerbild",
        mv2: "Metaversum",
        mv3: "interstellare Kolonisierung",
        mv4: "RWA-Plattform",
        mc1: "Layer2",
        mc2: "DID",
        mc3: "Infrastruktur",
        mc4: "Unterstützendes Ökosystem",
        mp1: "MarsLab",
        mp2: "Web3-Investition und Inkubation",
        mp3: "Digitale Zahlungslösungen",
        mp4: "DAO Foundation",
    },
    Portfolio: {
        slogan1: "Wir freuen uns auf die Zusammenarbeit mit innovativen Projekten oder Unternehmen.",
        slogan2: "Klicken Sie auf die Schaltfläche, um Informationen einzureichen.",
        submit: "Projekt einbringen.",
        title: "Portfolio",
        list1: {
            title: 'Datenschutz und Sicherheit',
            font: 'Nutzen Sie Layer 2 und dezentrale Identitätstechnologie (DID), um die Autonomie der Benutzerdaten und den Schutz der Privatsphäre zu gewährleisten.',
        },
        list2: {
            title: "Community-Autonomie",
            font: "Globale Benutzer und Community-Mitglieder beteiligen sich an der Governance und erreichen demokratische Entscheidungsfindung."
        },
        list3: {
            title: "Effiziente Blockchain-Infrastruktu",
            font: "Leistungsstarke Layer-2-Lösungen sichern Großanwendungen und Hochfrequenztransaktionen."
        },
        list4: {
            title: "Tokenisierung von realen Vermögenswerten",
            font: "Unterstützung des On-Chain-Managements von realen Vermögenswerten, um eine transparente und effiziente Vermögensverwaltung zu erreichen. Kooperation mit mehr als 400 Unternehmen erreicht."
        },
        list5: {
            title: "Digital Asset Management",
            font: "Bereitstellung umfassender Digital Asset Management-Dienste wie Forschung, Investition und Inkubation für globale Benutzer und hochwertige Projekte, um den vielfältigen Anforderungen gerecht zu werden."
        },
        list6: {
            title: "Projektinkubation",
            font: "Stellen Sie über die MarsLab-Inkubationsplattform Finanzierung, Branding, Betrieb und technischen Support für neue Web3-Projekte bereit."
        }
    },
    news: {
        title: 'Tin tức & Nội dung'
    },
    support: {
        title: 'Ökosystem',
        font1: 'Wenn sie eine bessere idee haben, wenden sie sie an uns und wir haben den auftrag rechtzeitig.',
        input1: 'Geben sie den titel ein?',
        input2: 'Stellen sie die fragen.',
        input3: 'Bitte hinterlassen sie ihre e-mail-adresse. Sie werden Von unseren mitarbeitern zurückgeschrieben',
        input4: 'Einspruch stattgegeben.',
        button2: 'Abreise',
        button1: 'Eingereicht werden',
    },
    team: {
        font1: 'M3 DAO-Teams',
        font2: 'Das M3 DAO-Team setzt sich aus den weltweit führenden Web3-Branchenführern zusammen und hat sich zum Ziel gesetzt, den globalen Benutzern einzigartige Technologie- und Innovationserlebnisse zu bieten und die Entwicklung von Web3 umfassend voranzutreiben. Wir freuen uns darauf, mit allen Mitgliedern von M3 DAO zusammenzuarbeiten, um die Entwicklung dezentraler Technologie neu zu definieren und gemeinsam beispiellose Blockchain-Veränderungen zu erleben.',
        item1: {
            name: 'EMMANUEL QUEZADA',
            title1: `M3 DAO-Mitglied`,
            title2: `IP- und Branding-Leiter`,
            font: 'Als Gründer von U-topia Ecosystem arbeitet er seit 7 Jahren in der Blockchain-Technologie und ist zertifizierter Blockchain-Experte und Blockchain-Berater für KYC-, CFT- und AML-Angelegenheiten.'
        },
        item2: {
            name: 'L.A',
            title1: `Frühes Mitglied von M3 DAO `,
            title2: `Ökosystemleiter`,
            font: 'Früher BTC-Evangelist、Besitzer einer Bitcoin- Mine、Malaysischer Unternehmer mit über 400 stationären Geschäften、Präsident mehrerer börsennotierter Unternehmen、Globaler Community- Leiter'
        },
        item3: {
            name: 'MICHAEL GORD',
            title1: `M3 DAO-Mitglied`,
            title2: `Investitionsleiter`,
            font: 'Serienunternehmer (3 Exits + 3 Übernahmen), Global Citizen Disruptive Technology Investor/Berater (über 80 Portfoliounternehmen/4 Seeds -> Unicorn), Redner und Finanzier (70 Mio. Direktfinanzierung über 210 Millionen US-Dollar, strukturierte Finanzierung über 210 Millionen US-Dollar) , wobei der Schwerpunkt auf der groß angelegten Einführung digitaler Assets und der Blockchain-Technologie liegt.'
        },
        item4: {
            name: 'DARREN SMITH',
            title1: `M3 DAO-Mitglied`,
            title2: `IT-Leiter`,
            font: 'Veteran der Videospielbranche. Verbrachte 20 Jahre bei Nintendo und arbeitete an Konsolen wie Nintendo 64, Wii, DSi, 3DS sowie Apps und Spielen. Kam 2018 zu Epik Prime und arbeitet daran, NFTs ins Gaming zu bringen.'
        },
        item5: {
            name: 'GC',
            title1: `Frühes Mitglied von M3 DAO `,
            title2: `Golden Carrot Capital 创始人`,
            font: '作为一名加密货币企业家，他在区块链领域拥有 8 年经验，并在 Binance 和 MEXC Global 等领先加密货币交易所担任要职，积累了丰富的金融专业知识。'
        },
        item6: {
            name: 'OWEN MA',
            title1: `M3 DAO-Mitglied`,
            title2: `Ich plädiere`,
            font: 'Vizepräsident für Ökosystembetrieb und Finanzen bei U-topia. Umfangreiche Erfahrung in der Lizenzierung von geistigem Eigentum, Vermögensfinanzierung und Anreizprogrammen, Musikfestivals und Immobilien. Er hat erfolgreich das größte B2B-Ökosystem für Unternehmen in Web3 aufgebaut und dabei Gaming und Branding integriert.'
        },
        item7: {
            name: 'ALEXIS SIRKIA',
            title1: `M3 DAO-Mitglied`,
            title2: `Finanzielle Leitung`,
            font: 'Als erfahrener Unternehmer mit einer nachgewiesenen Erfolgsbilanz in der Kryptowährungsbranche hat er sich erfolgreich in der sich ständig verändernden Branchenumgebung zurechtgefunden und sich von einem frühen XRP-Investor zu einem führenden Kryptowährungshandels- und Market-Making-Unternehmen entwickelt, was in der Übernahme der führenden Blockchain der Yellow Group gipfelte Infrastrukturunternehmen OpenWare.'
        },
        item8: {
            name: 'Ference',
            title1: `Kernmitglied von M3 DAO`,
            title2: `Community-Leiter`,
            font: "Ein Marketingexperte mit zwanzigjähriger Erfahrung. Leidenschaftlich interessiert an Geschäftsentwicklungsstrategien, mit umfangreichem Hintergrundwissen in traditioneller Ökonomie, Web3 und Informationstechnologie. Hat eine Community aufgebaut, die sich aus globalen Erfahrungen zusammensetzt."
        },
        item9: {
            name: 'James',
            title1: `Kernmitglied von M3 DAO`,
            title2: "U-topia BD",
            font: "Absolvent der University of Adelaide mit Schwerpunkt Strategisches Management. Tief verwurzelt in der Krypto-Branche mit reicher Erfahrung. Ehemaliger Führungskraft bei Bybit mit bedeutenden Erfolgen in den Bereichen strategische Entwicklung, Community-Erweiterung und Ökosystem-Aufbau."
        }
    },
    grant: {
        one: 'MarsLab-Zuschussplan',
        two: 'Bewerben Sie sich sofort',
        title1: {
            bolder: 'MarsLab-Stipendien',
            content: "Das Programm strebt danach, an der Spitze der Blockchain- und Technologiesektoren zu stehen, indem es Projekte mit Weitsicht fördert und unterstützt und so Entwickler und Teams dazu ermutigt, sich aktiv am Aufbau grundlegender Infrastruktur zu beteiligen.Unser Ziel geht über die bloße Einführung praktischer und innovativer Lösungen für die Gemeinschaft hinaus.Unser Ziel ist es, einen wesentlichen Einfluss auf die Branche zu nehmen und die Richtung der Entwicklung der Blockchain- Technologie zu bestimmen."
        },
        title2: {
            font1: "Das Programm ist in 12 Phasen unterteilt, jede Phase mit einem Preisgeld von 500.000 US-Dollar.", font2: "Term 1"
        },
        Goal: {
            title: "Ziel",
            content1: "Ein Innovator und Marktführer in den Bereichen Blockchain, dezentrale Anwendungen(dApps), Web3 und verwandte Technologien zu werden.",
            content2: "Unterstützung von Projekten in jeder Phase, von der Konzeption bis zur Umsetzung, um sicherzustellen, dass sie eine signifikante Wirkung erzielen.",
            content3: "Eine ressourcenreiche, lebendige Gemeinschaft von Entwicklern, Schöpfern und Innovatoren aufzubauen und so die Zusammenarbeit und den Wissensaustausch zu verbessern."

        },
        Grant: {
            title: "Förderkategorie",
            content1: "Technologieinnovation",
            content2: "Werkzeug",
            content3: "Gemeinschaftshaus"
        },
        Qualification: {
            title: "Qualifikation",
            content1: "Offen für alle Projekte, die auf der Blockchain basieren, wobei der Schwerpunkt auf Multi- Chain - Interoperabilität und Innovation liegt.",
            content2: "Bewerber müssen Originalwerke einreichen.Plagiate sind strengstens untersagt.",
            content3: "Die Einreichungen sollten einen detaillierten Projektplan, eine Demo(falls verfügbar) und einen Teamhintergrund enthalten.",
            content4: "Projekte müssen klare Praktikabilität, Innovation und potenzielle Auswirkungen nachweisen."
        },
        Process: {
            title: "Verfahren",
            content1: "Einreichen",
            content2: "Rezension",
            content3: "Abstimmung",
            content4: "Großartige Unterstützung",
        },
        Funding: {
            title: "Finanzierungsstruktur",
            content1: "Die Mittel werden stufenweise entsprechend den Projektmeilensteinen und - ergebnissen verteilt.",
            content2: "Der Gesamtpreispool wird zu Beginn jedes Zyklus bekannt gegeben, wobei die einzelnen Zuschussbeträge je nach Projektbedarf und möglichen Auswirkungen variieren.",
            content3: "Zusätzlich zur finanziellen Unterstützung erhalten die Gewinner auch technische Unterstützung und Marketingförderung, um den Projekterfolg sicherzustellen.",
        },
        Community: {
            title: "Beteiligung und Unterstützung der Gemeinschaft.",
            content1: "M3 - Community",
            content2: "Seminar und Workshop",
            content3: "Anzeige",
            content4: "Darüber hinaus werden wir den Fortschritt der Gewinnerprojekte regelmäßig verfolgen, um eine reibungslose weitere Entwicklung sicherzustellen.",
        },
    },
    grantForm: {
        title1: "Đơn xin tài trợ MarsLab",
        title2: "Vui lòng điền vào mẫu này để đăng ký. Chúng tôi mong muốn tìm hiểu về những gì bạn sẽ xây dựng và cách chúng tôi có thể hỗ trợ hành trình của bạn.",
        input1: {
            place1: "Tên dự án",
            place2: "Dự án Twitter",
            place3: "trang web dự án",
            place4: "Tóm tắt dự án",
        },
        input2: {
            place1: "Hãy mô tả dự án của bạn trong vòng 50 ký tự trở xuống. Nó giải quyết được vấn đề gì?",
            place2: "Làm thế nào nó hoạt động?",
        },
        input3: {
            label: "Dự án của bạn thuộc loại nào?",
        },
        input4: {
            label: "Vui lòng nêu rõ giai đoạn dự án của bạn",
        },
        input5: {
            label: "Xin vui lòng cho chúng tôi biết về đội tham gia. Có bao nhiêu người?",
            label2: "Tên, vai trò và lý lịch của các thành viên cốt lõi trong nhóm."
        },
        input6: {
            label: "Vui lòng cho chúng tôi biết bạn cần loại hỗ trợ nào từ MarsLab",
        },
        input7: {
            label: "Người liên hệ dự án",
            place1: "Tên",
            place2: "E-Mail",
            place3: "điện tín",
            place4: "Địa chỉ ví",
        },
        input8: {
            label: "Thông tin người giới thiệu của bạn (nếu có)",
        },
        back: "MẶT SAU",
        next: "KẾ TIẾP",
    },
    grantFinish: {
        font1: "Antrag eingereicht",
        font2: "Ihre Einreichung wurde zur Prüfung bei uns eingegangen. Vielen Dank, dass Sie sich die Zeit genommen haben!",
        button1: "ZURÜCK ZUM GRANT",
        button2: "EINREICHUNG NEUER ANTRAG",
    },
    blog: {
        AMANews: "AMA-Beratung",
        item1: {
            title: "MarsVerse x U-topia: Ein tieferer Einblick in das MarsVerse-Ökosystem und die Avatar Alliance",
            topic: "Die Veranstaltung bot einen Überblick über das Marsverse-Ökosystem, den offiziellen Start der Avatar Alliance, Einführungen in Marsverse und U-topia Avatar, Einblicke in die zukünftige Entwicklung des U-topia-Projekts und eine ausführliche Diskussion über den U-Coin-Entwicklungsplan und Tokenomics.",
            body: "Diese Veranstaltung bot eine ausführliche Einführung in das Marsverse-Ökosystem und feierte den offiziellen Start des Kooperationsmodells der Avatar Alliance. Bei der Veranstaltung wurde auch die Co-Branding-Karte Marsverse x U-topia vorgestellt, die den Teilnehmern einen Einblick in die zukünftige Entwicklung des U-topia-Projekts gab. Das Treffen endete mit einer ausführlichen Diskussion des Entwicklungsplans und der Token-Ökonomie von U-coin, wobei die strategische Vision und der finanzielle Rahmen des Projekts geklärt wurden.",
            one: {
                title: 'Veranstaltungshighlights:',
                ul: [
                    "Um die Zusammenarbeit zwischen M3 DAO und U-topia zu feiern, wurde der CEO von U-topia eingeladen, an der Twitter Space Sharing-Sitzung von MarsVerse teilzunehmen, um Einblicke in das U-topia-Projekt und seine zukünftige Entwicklung zu geben.",
                    "Die Veranstaltung kündigte außerdem den Start des Kooperationsmodells Avatar Alliance an und stellte die MarsVerse x U-topia Avatar Alliance vor.",
                    "Um abschließend alle Fragen zum Wert von Utopia zu beantworten, diskutierte der CEO von U-topia ausführlich die Entwicklungspläne und die Token-Ökonomie von U-coin."
                ],
            },
            two: {
                title: "Über Avatar Alliance:",
                ul: [
                    "Im Mittelpunkt der strategischen Roadmap von MarsVerse steht die Avatar Alliance-Initiative, eine bahnbrechende Initiative zur Zusammenarbeit mit visionären potenziellen Partnern. Das Programm soll das Geschäftswachstum der MarsVerse-Partner durch die Co-Branding-Avatar Alliance vorantreiben, den Markeneinfluss stärken und die Interaktion mit dem dezentralen Netzwerk erleichtern.",
                    "Das Avatar Alliance-Programm läutet eine neue Ära der gemeinschaftsübergreifenden Zusammenarbeit ein und fördert gegenseitiges Wachstum und Innovation für MarsVerse und seine Partner durch strategisches Co-Branding, Multi-Channel-Marketing und globale Veranstaltungen."
                ],
            },
            three: {
                title: "Über die Co-Branding-Karte von MarsVerse x U-topia:",
                ul: [
                    "Die Co-Branding-Karte von MarsVerse x U-topia bietet Anlegern luxuriöse Vorteile und symbolische Belohnungen. Die Einführung der Co-Branding-Karte beinhaltet ein Airdrop-Event vom 1. bis 20. Juli, bei dem Benutzer, die bestimmte Avatare prägen, zusätzliche Belohnungen erhalten. Aufgrund des hohen Wertes, der Knappheit und der enormen Belohnungen, die mit dem Besitz dieser Karten einhergehen, hat dies in unserer Community zu einer enormen Nachfrage geführt. Die Veranstaltung hat den Wert des Ucoin-Tokens von U-topia, der einen Gesamtbestand von 1 Milliarde hat, erheblich gesteigert."
                ],
            },
        },
        item2: {
            title: "MarsVerse x U-topia: Die Zukunft des Metaversums: Was ist die nächste Revolution?",
            topic: "In der Diskussion wurden die Herausforderungen und Chancen bei der Schaffung nahtloser Erlebnisse auf verschiedenen Metaverse-Plattformen untersucht, die Entwicklung virtueller Güter- und Dienstleistungsmärkte über NFTs hinaus untersucht und untersucht, wie das Metaverse zukünftige soziale Interaktionen und Beziehungen prägen wird.",
            body: "Diese Diskussion befasst sich mit den Herausforderungen und Chancen der Schaffung nahtloser Erlebnisse auf verschiedenen Metaverse-Plattformen. Es untersucht die Entwicklung des Marktes für virtuelle Güter und Dienstleistungen über NFTs hinaus und untersucht, wie das Metaverse zukünftige soziale Interaktionen und Beziehungen prägen wird, wobei das Potenzial und die Auswirkungen dieser sich schnell entwickelnden digitalen Landschaft umfassend aufgezeigt werden.",
            one: {
                title: "Veranstaltungshighlights:",
                ul: [
                    "Führungskräfte von MarsVerse taten sich mit Vertretern des U-topia-Projekts zusammen, um im MarsVerse-Twitter-Space über die Herausforderungen und Chancen im Metaverse sowie die Zukunft des Metaverses zu sprechen.",
                    "Sie geben auch Antworten auf Benutzerfragen zur zukünftigen Entwicklung des Metaversums."
                ],
            },
            two: {
                title: "Über das Metaversum:",
                ul: [
                    "Das Metaverse ist ein kollektiver virtueller gemeinsamer Raum, der durch die Verschmelzung von virtueller, erweiterter physischer Realität und physisch persistentem virtuellen Raum entsteht. Es umfasst alle virtuellen Welten, Augmented Reality und das Internet zusammen. Im Metaverse können Benutzer in Echtzeit mit computergenerierten Umgebungen und anderen Benutzern interagieren und so immersive digitale Umgebungen erleben, die sich nahtlos in die reale Welt einfügen."
                ],
            },
            three: {
                title: "Über nicht fungible Token (NFTs):",
                ul: [
                    "Nicht fungible Token (NFTs) sind einzigartige digitale Vermögenswerte, die mithilfe der Blockchain-Technologie das Eigentum an einem bestimmten Gegenstand oder Inhalt wie Kunst, Musik, Video oder virtuellen Immobilien darstellen. Im Gegensatz zu Kryptowährungen wie Bitcoin oder Ethereum sind NFTs unteilbar und können nicht eins zu eins ausgetauscht werden, was jeden Token einzigartig und wertvoll macht. NFTs bieten eine Möglichkeit, den Besitz digitaler Kreationen zu authentifizieren und nachzuweisen, und eröffnen so neue Formen digitaler Transaktionen und Interaktionen."
                ],
            },
        },
        item3: {
            title: "Entdecken Sie M3 DAO: Vision und Innovation",
            topic: "Es bot einen detaillierten Überblick über die Produkte und Werte von M3 DAO, erläuterte, wie M3 DAO Community Governance erreicht, und diskutierte seinen Beitrag zur Zukunft der Web3-Branche.",
            body: "Dies ist das erste Mal, dass M3 DAO seine Produkte und Werte japanischen Benutzern präsentiert und mit ihnen kommuniziert. Die begeisterte Resonanz im Twitter-Bereich zeigt auch, dass japanische Benutzer den Grundwerten von M3 DAO voll und ganz zustimmen und sich auf die Metaverse-Revolution freuen, die M3 DAO mit sich bringt. Dies ist für M3 DAO der erste Schritt zur erfolgreichen Öffnung des japanischen Marktes und wir freuen uns auf die weitere Entwicklung von M3 DAO in Japan.",
            one: {
                title: "Veranstaltungshighlights:",
                ul: [
                    "M3 DAOs erste japanische AMA, M3 DAO-Vertreter waren im Twitter-Bereich japanischer KOLs unterwegs und teilten die Vision, Mission und Werte von M3 DAO und betonten die Bedeutung der Community Governance für M3 DAO.",
                    "Beantwortung von Fragen japanischer Zuschauer zu den M3 DAO-Kernprodukten MarsVerse, MarsChain und MarsProtocol."
                ],
            },
            two: {
                title: "Über die Vision, Ziele und Mission von M3 DAO?",
                ul: [
                    "Vision: Das weltweit einflussreichste Investment- und Inkubations-DAO zu werden und Innovation und nachhaltige Entwicklung zu fördern.",
                    "Ziel: Die zehn größten Web3-Einhornprojekte der Welt nach Marktwert hervorbringen.",
                    "Mission: Durch ein dezentrales Governance-Modell globale Ressourcen sammeln, innovative Projekte mit Potenzial unterstützen und fördern sowie technologischen Fortschritt und soziales Wohlergehen fördern."
                ],
            },
            three: {
                title: "Was bedeutet Community Governance für M3 DAO?",
                ul: [
                    "Die Community-Governance von M3 DAO ermöglicht es den Mitgliedern, sich am Entscheidungsprozess zu beteiligen, Änderungen vorzuschlagen und über wichtige Themen abzustimmen. Der oben genannte Inhalt wird vollständig über MarsVerse implementiert. Gleichzeitig implementiert MarsVerse einen globalen Community-Governance-Mechanismus, der es Benutzern ermöglicht, Einfluss auf die Entwicklung und Richtlinien des Ökosystems zu nehmen."
                ],
            },
        },
        item4: {
            title: "M3 Online-Broadcast-Konferenz: U-Topia-Sondersitzung der chinesischen Community",
            topic: "In dieser Sitzung wurden U-Topia, der Co-Branding-Partner von M3 DAO, sowie die kommende MarsVerse x U-Topia Co-Branding-Serie von Avatar-Decks und damit verbundene Airdrop-Event-Veröffentlichungen vorgestellt!",
            body: "Bei diesem Treffen wurden U-Topia als Co-Branding-Partner von M3 DAO und die kommende Co-Branding-Karte MarsVerse x U-Topia vorgestellt. Das Treffen beleuchtete auch die Veröffentlichungsdetails der damit verbundenen Airdrop-Aktivitäten und legte damit den Grundstein für eine spannende Zusammenarbeit.",
            one: {
                title: "Veranstaltungshighlights:",
                ul: [
                    "Um die Zusammenarbeit zwischen M3 DAO und U-topia zu feiern, nahm der CEO von U-topia an der Live-Übertragung teil, um Einblicke in das U-topia-Projekt und seine zukünftige Entwicklung zu geben.",
                    "Die Veranstaltung markierte auch den offiziellen Start der MarsVerse x U-topia Avatar Alliance und der damit verbundenen Luftabwürfe."
                ],
            },
            two: {
                title: "Über M3 DAO:",
                ul: [
                    "M3 DAO ist eine dezentrale autonome Organisation, die sich auf die Identifizierung und Unterstützung vielversprechender Blockchain-Startups konzentriert. M3 DAO nutzt die kollektive Weisheit seiner globalen Gemeinschaft, um Investitionsentscheidungen zu treffen, und bietet so einen demokratischeren und integrativeren Ansatz für Risikokapital."
                ],
            },
            three: {
                title: "Über U-topia:",
                ul: [
                    "U-topia ist das weltweit erste MediaFi-Unternehmen, eine Fusion globaler innovativer IP-Lizenzen für GameFi, KI-Musik und Videounterhaltung, unterstützt durch einen Katalog von 70.000 realen Asset-Belohnungen. U-topia nutzt erstklassiges geistiges Eigentum (IP) in Web2, wie z. B. Spielinhalte professioneller Mannschaftssportarten, Videoinhalte von Bandai Namco usw. sowie Inhalte von 100 weltweit führenden Einzelhändlern wie Nike, und integriert gleichzeitig Web3 der Top-NFTs Sammlerstücke."
                ],
            },
        },
        item5: {
            title: "MarsVerse x Utopia: M3 MarsVerse x U-topia Avatar-Verkaufscountdown",
            topic: "Erhalten Sie Einblicke in das Co-Branding von Avatar und die Werbeaktionen von MarsVerse x U-topia. Erfahren Sie mehr über das U-topia-Ökosystem und die U-Coin-Tokenomik.",
            body: "Community-Benutzer arbeiteten mit Vertretern von U-topia zusammen, um mehr über die Co-Branding-Karte von MarsVerse x U-topia und ihre aufregenden Werbeaktivitäten zu erfahren. Erkunden Sie das U-topia-Ökosystem und vertiefen Sie sich in die Ökonomie der U-Coin-Token, um ihre strategischen Auswirkungen vollständig zu verstehen.",
            one: {
                title: "Veranstaltungshighlights:",
                ul: [
                    "Vertreter des U-topia-Projekts besuchten den Live-Übertragungsraum und teilten den mit MarsVerse x U-topia gemeinsam gebrandeten Avatar und damit verbundene Werbeaktivitäten.",
                    "Gleichzeitig wurden auch Fragen der Nutzer zum U-topia-Ökosystem und zur U-Coin-Token-Ökonomie beantwortet."
                ],
            },
            two: {
                title: "Über die Co-Branding-Karte von MarsVerse x U-topia:",
                ul: [
                    "Die Co-Branding-Karte von MarsVerse x U-topia bietet Anlegern luxuriöse Vorteile und symbolische Belohnungen. Der Start beinhaltet ein Airdrop-Event vom 1. bis 20. Juli, mit zusätzlichen Belohnungen für Benutzer, die bestimmte Avatare prägen. Aufgrund des hohen Wertes, der Seltenheit und der großzügigen Belohnungen, die diese Karten bieten, sind sie in unserer Community äußerst gefragt. Die Veranstaltung steigerte den Wert des Ucoin-Tokens von U-topia, der einen Gesamtbestand von 1 Milliarde hat, deutlich."
                ],
            },
            three: {
                title: "Über U-Coin:",
                ul: [
                    "Token-Symbol: U-Münze",
                    "Gesamtangebot: 1 Milliarde",
                    "Datum der Börsennotierung: Anfang August",
                    "Verteilung der U-Coin-Token:",
                    "Strategische Finanzierung: 1,25%、Team/Berater: 17%、Samenrunde: 2,78%、Gemeinschaftszuteilung: 7,5%、Öffentlicher Verkauf: 4,75%、Ökosystem: 35,4%、Privatverkauf: 4,32%、Partner: 8%、Einsatzprämie: 3%、Zentralisierte Börsenliquidität: 10%、Belohnung des Liquiditätsanbieters: 2%、Dezentrale Börsenliquidität: 4%"
                ],
            },
        },
        item6: {
            title: "M3 Mars Konsenskonferenz der chinesischen Gemeinschaft: M3 DAO-Big Shots sind zu Gast!",
            topic: "G.C Capital-Gründer und M3 DAO Investment Lead – GC und M3 DAO European Community-Mitglied Ference kam vorbei, um die jüngsten Investitionstrends auf dem Kryptomarkt und den Schlüssel zur Konsensbildung in der Community zu teilen!",
            body: "GC, Gründer von G.C Capital und Leiter von M3 DAO Investment, und Ferenc, Mitglied der europäischen M3 DAO-Community, waren vor Ort, um Einblicke in die jüngsten Investitionstrends auf dem Kryptomarkt zu geben und wichtige Strategien zur Konsensbildung in der Community zu diskutieren. Ihre Diskussion bot eine einzigartige Perspektive und wertvolle Einblicke in die Frage, wie man sich in der sich ständig verändernden Welt des Investierens in Kryptowährungen zurechtfindet und eine starke, geeinte Community aufbaut.",
            one: {
                title: "Veranstaltungshighlights:",
                ul: [
                    "GC, Gründer von GC Capital und Leiter von M3 DAO Investment, erschien im Live-Übertragungsraum, um seine professionellen Einblicke in die jüngsten Investitionstrends auf dem Kryptomarkt zu teilen.",
                    "Ferenc, ein wichtiges Mitglied der europäischen M3 DAO-Community, demonstrierte, wie man die Community schnell erweitert und einen Community-Konsens herstellt."
                ],
            },
            two: {
                title: "Was ist Web3 Investing?",
                ul: [
                    "Web3 Investments konzentriert sich auf die Finanzierung und Entwicklung dezentraler Technologien, die die Grundlage für die nächste Generation des Internets bilden. Dazu gehören Blockchain-Projekte, Kryptowährungen, dezentrale Finanzplattformen (DeFi), nicht fungible Token (NFTs) und andere Technologien, die Benutzereigentum, Privatsphäre und Kontrolle über digitale Vermögenswerte und Daten fördern. Web3 Investments zielt darauf ab, die Art und Weise, wie wir online interagieren, zu verändern und die Macht von zentralisierten Einheiten in die Hände einzelner Benutzer und Gemeinschaften zu verlagern."
                ],
            },
            three: {
                title: "Was ist Community-Konsens?",
                ul: [
                    "Unter Community-Konsens versteht man den Prozess der Erzielung einer Einigung innerhalb einer Gruppe oder Gemeinschaft, der häufig in dezentralen und kollaborativen Umgebungen eingesetzt wird. Dieser Prozess beinhaltet offene Kommunikation, Beteiligung und Konsultation, um sicherzustellen, dass Entscheidungen die kollektiven Wünsche und Interessen der Community-Mitglieder widerspiegeln. Bei Blockchain- und Web3-Projekten ist der Konsens der Community entscheidend für die Validierung von Transaktionen, die Implementierung von Änderungen und die Aufrechterhaltung von Vertrauen und Transparenz innerhalb des Netzwerks."
                ],
            },
        },
        item7: {
            title: "M3 Mars Konsenskonferenz der chinesischen Gemeinschaft: M3 DAO-Big Shots sind zu Gast!",
            topic: "Der Community-Chef von M3 DAO Austria sprang zusammen mit dem europäischen und amerikanischen Künstler CJ mit dem Fallschirm in den Live-Übertragungsraum, um die Erfahrungen der Community-Erweiterung und der Konsensbildung in der Community in 30 Tagen zu teilen, nur wollen oder nicht, nein, können oder kippen!",
            body: `Der österreichische Community-Leiter Harry von M3 DAO und der europäisch-amerikanische Künstler CJ nahmen an der Live-Übertragung teil und tauschten ihre Erfahrungen bei der Community-Erweiterung und Konsensbildung aus. Sie diskutierten Strategien für ein explosionsartiges Wachstum von Krypto-Assets in nur 30 Tagen und betonten dabei die „Fragen Sie, ob Sie können, nicht wenn“-Mentalität. Ihre Erkenntnisse liefern wertvolle Hinweise, um ein schnelles und nachhaltiges Wachstum in der Gemeinschaft voranzutreiben.`,
            one: {
                title: "Veranstaltungshighlights:",
                ul: [
                    "Harry, der Leiter der österreichischen Community von M3 DAO, erschien im Live-Übertragungsraum und teilte seine Erfahrungen bei der Community-Erweiterung und Konsensbildung. Er erklärte, wie man innerhalb von 30 Tagen ein schnelles Wachstum von Krypto-Assets erreichen kann, und betonte die Mentalität „Fragen Sie einfach, ob Sie wollen, nicht, ob Sie können“.",
                    "CJ ist ein europäischer und amerikanischer Künstler, der derzeit sehr aktiv in M3 predigt und Gemeinden auf drei Kontinenten und in verschiedenen Ländern leitet. Während der Live-Übertragung teilte er auch seine Erfahrungen mit der Vermögensvermehrung und diskutierte die verschiedenen Möglichkeiten von M3."
                ],
            },
            two: {
                title: "Was ist Community-Konsens?",
                ul: [
                    "Unter Community-Konsens versteht man den Prozess der Erzielung einer Einigung innerhalb einer Gruppe oder Gemeinschaft, der häufig in dezentralen und kollaborativen Umgebungen eingesetzt wird. Dieser Prozess beinhaltet offene Kommunikation, Beteiligung und Konsultation, um sicherzustellen, dass Entscheidungen die kollektiven Wünsche und Interessen der Community-Mitglieder widerspiegeln. Bei Blockchain- und Web3-Projekten ist der Konsens der Community entscheidend für die Validierung von Transaktionen, die Implementierung von Änderungen und die Aufrechterhaltung von Vertrauen und Transparenz innerhalb des Netzwerks."
                ],
            },
            three: {
                title: "Über M3 DAO:",
                ul: [
                    "M3 DAO kombiniert Metaverse, Layer 2 Public Chain und Digital Asset Management, um ein von der Community verwaltetes, vielfältiges, innovatives und offenes digitales Ökosystem zu schaffen, das Web3 mit der realen Welt verbindet. Zu seinen drei Hauptprodukten gehören: MarsVerse, ein Metaverse-Ökosystem, das sich auf die interstellare Kolonisierung konzentriert; MarsChain, eine Layer-2-Blockchain-Lösung, die sichere und effiziente Infrastruktur bereitstellt, und MarsProtocol, eine Plattform, die Investitionen, DeFi, Absteckung und Vermögensverwaltung sowie zentrale Finanzprotokolle abdeckt Risikokapital. M3 DAO baut ein vielfältiges und interaktives Web3-Ökosystem auf und lädt alle neugierigen und kreativen Menschen ein, an der Gestaltung der Zukunft mitzuwirken."
                ],
            },
        },
        item8: {
            title: "M3 Mars Global Community Consensus Conference: M3 DAO-Führungskräfte zu Besuch!",
            topic: "L.A. und Emmanuel, zwei Kernmitglieder von M3 DAO, werden den MetaMars Metaverse Ecology Plan und die bevorstehende Rekrutierung von Token-Abonnements für die Seed-Runde im August besprechen!",
            body: "Zwei Kernmitglieder von M3 DAO, L.A. und Emmanuel, diskutierten im August den MetaMars Metaverse-Ökoplan und die bevorstehende Rekrutierung von Token-Abonnements für die Seed-Runde. Ihre Erkenntnisse liefern wertvollen Input für die strategische Vision und die Chancen des M3-DAO-Ökosystems und legen den Grundstein für zukünftiges Wachstum und Innovation.",
            one: {
                title: "Veranstaltungshighlights:",
                ul: [
                    "L.A. und Emmanuel, zwei Kernmitglieder von M3 DAO, fliegen im Live-Übertragungsraum, um den ökologischen Plan von MetaMars Metaverse zu besprechen.",
                    "Beantwortung der Fragen der Benutzer zur bevorstehenden Rekrutierung von Token-Abonnements für die Seed-Runde im August."
                ],
            },
            two: {
                title: "Über das MetaMars-Metaversum?",
                ul: [
                    "MetaMars ist eine Web3-Metaverse-Plattform mit Mars-Thema. Durch die Integration von virtueller Realität, Kryptoökonomie und sozialen Netzwerken schafft MetaMars eine neue Art der digitalen Interaktion und Wertschöpfung. Diese innovative digitale Welt kombiniert die Erforschung des Mars mit der Blockchain-Technologie, um Benutzern ein einzigartiges, immersives Erlebnis zu bieten.",
                    "Bei MetaMars können Teilnehmer eine sorgfältig gestaltete Marslandschaft erkunden, ihre eigene Basis aufbauen und Belohnungen verdienen. Gleichzeitig arbeiten interstellare Reisende aus aller Welt zusammen, um die Geheimnisse des Universums zu erforschen."
                ],
            },
            three: {
                title: "Über MCD-Token:",
                ul: [
                    "Der MCD-Token ist der native Utility-Token des M3-DAO-Ökosystems. Es wird für die Verwaltung, das Abstecken und den Zugriff auf verschiedene Dienste in MarsVerse, MarsChain und MarsProtocol verwendet."
                ],
            },
        }
    },
    footer: {
        one: {
            title: "Ökosystem",
            item1: "MarsVerse",
            item2: "MarsChain",
            item3: "MarsProtocol",
            item4: "Avatar",
            item5: "MarsLab",
            item6: "Blockchain Explorer",
        },
        two: {
            title: "Support",
            item1: "Über uns",
            item2: "Medienpaket",
            item3: "Kontakt",
        }
    }
}