module.exports = {
    tabList: {
        list1: 'Trang chủ',
        list2: 'Sản phẩm',
        list3: 'Portfolio',
        list4: 'Tin tức',
        list5: 'Liên hệ',
        list6: 'nhóm',
        list7: 'Tài trợ',
        list8: 'Blog',
    },
    home: {
        slogan: "Web3 trong tầm tay bạn.",
        number1: 'Người dùng đang hoạt động',
        number2: 'nút toàn cầu',
        number3: 'Hệ sinh thái cộng tác',
        number4: 'trực tuyến và ngoại tuyến',
        box_title: '与M3 Khám phá tiềm năng của Web3 với M3 DAO',
        box_content: "M3 DAO là một tổ chức tự trị phi tập trung tích hợp Metaverse, chuỗi công khai Lớp 2 và quản lý tài sản kỹ thuật số. Với ba lĩnh vực chính là MarsVerse, MarsChain và MarsProtocol (MarsLab) làm cốt lõi, M3 DAO đang xây dựng một hệ sinh thái Web3 đa tương tác mới. Theo các giá trị về quyền tự chủ, đa dạng hóa, đổi mới và cởi mở của cộng đồng, M3 DAO sẽ hợp tác với người dùng toàn cầu để cùng thúc đẩy kết nối sâu sắc giữa Web3 và thế giới thực, đồng thời tạo ra bối cảnh Web3 trong tương lai.",
        introduce: 'U-topia là công ty MediaFi đầu tiên trên thế giới có số lượng lớn tài nguyên IP và kết hợp Web2 và Web3 thông qua công nghệ blockchain.',
        helloArray: [
            'Công nghệ chuỗi khối',
            'DeFi',
            'DApp',
            'NFT',
            'DAO',
            'Hợp đồng thông minh',
            'Cơ sở hạ tầng và công cụ',
            'Quyền riêng tư và bảo mật',
            'Giải pháp chuỗi chéo',
            'Metaverse',
            'Lưu trữ phi tập trung',
            'Nhận dạng phi tập trung',
            'Máy tính phi tập trung',
            'RWA',
            'mạng xã hộiFi',
            'Privacy and Security',
            'MediaFi',
            'Thị trường dự đoán phi tập trung'
        ],
        dq_title: 'Cộng đồng toàn cầu',
        blog: "Blog",
        bottom_title: 'Khám phá những khả năng vô hạn của Web3',
        bottom_font1: 'Web3 đang định hình lại thế giới và chúng tôi tin rằng thông qua công nghệ phi tập trung, chúng tôi sẽ xây dựng một thế giới kỹ thuật số cởi mở, công bằng và tự chủ hơn.',
        bottom_font2: 'Đồng hành cùng Web3, đồng hành cùng sự đổi mới.',
        button_button: "Liên hệ"
    },
    Product: {
        mv1: "hình đại diện",
        mv2: "metaverse",
        mv3: "thuộc địa giữa các vì sao",
        mv4: "nền tảng RWA",
        mc1: "Lớp 2",
        mc2: "LÀM",
        mc3: "Cơ sở hạ tầng",
        mc4: "Hệ sinh thái hỗ trợ",
        mp1: "MarsLab",
        mp2: "Đầu tư và ươm tạo Web3",
        mp3: "Giải pháp thanh toán kỹ thuật số",
        mp4: "Quỹ DAO",
    },
    Portfolio: {
        slogan1: "Chúng tôi mong muốn được làm việc với các dự án hoặc công ty sáng tạo.",
        slogan2: "Bấm vào nút để gửi thông tin.",
        submit: "Gửi các dự án",
        title: "Trường hợp",
        list1: {
            title: '数Quyền riêng tư và bảo mật dữ liệu据隐私与安全',
            font: 'Sử dụng Lớp 2 và công nghệ nhận dạng phi tập trung (DID) để đảm bảo quyền tự chủ dữ liệu của người dùng và bảo vệ quyền riêng tư.',
        },
        list2: {
            title: "Quyền tự chủ của cộng đồng",
            font: "Người dùng toàn cầu và các thành viên cộng đồng tham gia quản trị và đưa ra quyết định dân chủ."
        },
        list3: {
            title: "Cơ sở hạ tầng blockchain hiệu quả",
            font: "Các giải pháp Lớp 2 hiệu suất cao bảo vệ các ứng dụng quy mô lớn và các giao dịch tần suất cao."
        },
        list4: {
            title: "Mã thông báo tài sản thực",
            font: "Hỗ trợ quản lý tài sản thực trên chuỗi để đạt được sự quản lý tài sản minh bạch và hiệu quả. Đạt được sự hợp tác với hơn 400 đơn vị."
        },
        list5: {
            title: "Quản lý tài sản kỹ thuật số",
            font: "Cung cấp các dịch vụ quản lý tài sản kỹ thuật số toàn diện như nghiên cứu, đầu tư và ươm tạo cho người dùng toàn cầu cũng như các dự án chất lượng cao để đáp ứng nhu cầu đa dạng."
        },
        list6: {
            title: "Ươm tạo dự án",
            font: "Cung cấp kinh phí, xây dựng thương hiệu, hoạt động và hỗ trợ kỹ thuật cho các dự án Web3 mới nổi thông qua Nền tảng ươm tạo MarsLab."
        }
    },
    news: {
        title: 'Neuigkeiten & Inhalte'
    },
    support: {
        title: 'Ủng hộ',
        font1: 'Nếu ông có lời khuyên tốt hơn, hãy cho chúng tôi biết và chúng tôi sẽ xử lý kịp thời.',
        input1: 'Hãy nhập tiêu đề',
        input2: 'Xin vui lòng nhập vấn đề',
        input3: 'Hãy để lại địa chỉ email và nhân viên của chúng tôi sẽ trả lời',
        input4: 'Gửi thành công',
        button2: 'Trở về',
        button1: 'Được gửi đi.',
    },
    team: {
        font1: 'Đội ngũ M3 DAO',
        font2: 'Nhóm M3 DAO bao gồm các nhà lãnh đạo ngành Web3 hàng đầu thế giới và cam kết mang lại trải nghiệm đổi mới và công nghệ độc đáo cho người dùng toàn cầu, đồng thời thúc đẩy toàn diện sự phát triển của Web3. Chúng tôi mong muốn được làm việc với tất cả các thành viên của M3 DAO để xác định lại sự phát triển của công nghệ phi tập trung và cùng nhau trải nghiệm những thay đổi chưa từng có về blockchain.',
        item1: {
            name: 'EMMANUEL QUEZADA',
            title1: `Thành viên M3 DAO`,
            title2: `Trưởng nhóm IP & Thương hiệu`,
            font: 'Người sáng lập Hệ sinh thái U-topia, ông đã làm việc trong lĩnh vực công nghệ blockchain được 7 năm và là chuyên gia blockchain và nhà tư vấn blockchain được chứng nhận về các vấn đề KYC, CFT và AML.'
        },
        item2: {
            name: 'L.A',
            title1: `Thành viên ban đầu của M3 DAO `,
            title2: `Dẫn đầu hệ sinh thái`,
            font: 'Nhà truyền giáo BTC sớm、Chủ mỏ bitcoin、Doanh nhân Malaysia với hơn 400 doanh nghiệp truyền thống、Chủ tịch một số công ty niêm yết、Lãnh đạo cộng đồng toàn cầu'
        },
        item3: {
            name: 'MICHAEL GORD',
            title1: `Thành viên M3 DAO`,
            title2: `Trưởng nhóm đầu tư`,
            font: 'Doanh nhân nối tiếp (3 lần thoát ra + 3 lần mua lại), Nhà đầu tư/Cố vấn công nghệ đột phá toàn cầu (Hơn 80 công ty danh mục đầu tư/4 Hạt giống -> Kỳ lân), Diễn giả đại chúng & Nhà tài trợ (Tài trợ trực tiếp 70 triệu trên 210 triệu USD, tài trợ cơ cấu trên 210 triệu USD) , tập trung vào việc áp dụng quy mô lớn các tài sản kỹ thuật số và công nghệ blockchain.'
        },
        item4: {
            name: 'DARREN SMITH',
            title1: `Thành viên M3 DAO`,
            title2: `Trưởng nhóm CNTT`,
            font: 'Người kỳ cựu trong ngành trò chơi điện tử. 20 năm làm việc tại Nintendo, làm việc trên các máy chơi game như Nintendo 64, Wii, DSi, 3DS, cũng như các ứng dụng và trò chơi. Gia nhập Epik Prime vào năm 2018, nỗ lực đưa NFT vào hoạt động chơi game. '
        },
        item5: {
            name: 'GC',
            title1: `Thành viên ban đầu của M3 DAO `,
            title2: `Golden Carrot Capital 创始人`,
            font: '作为一名加密货币企业家，他在区块链领域拥有 8 年经验，并在 Binance 和 MEXC Global 等领先加密货币交易所担任要职，积累了丰富的金融专业知识。'
        },
        item6: {
            name: 'OWEN MA',
            title1: `Thành viên M3 DAO`,
            title2: `Tôi cầu xin`,
            font: 'Phó Chủ tịch Vận hành Hệ sinh thái và Tài chính tại U-topia. Có nhiều kinh nghiệm về cấp phép sở hữu trí tuệ, tài trợ tài sản và các chương trình khuyến khích, lễ hội âm nhạc và bất động sản. Anh đã xây dựng thành công hệ sinh thái B2B doanh nghiệp lớn nhất trên Web3, tích hợp trò chơi và xây dựng thương hiệu.'
        },
        item7: {
            name: 'ALEXIS SIRKIA',
            title1: `Thành viên M3 DAO`,
            title2: `Trưởng nhóm tài chính`,
            font: 'Là một doanh nhân dày dạn kinh nghiệm với thành tích đã được chứng minh trong ngành tiền điện tử, ông đã điều hướng thành công môi trường ngành luôn thay đổi, phát triển từ một nhà đầu tư ban đầu vào XRP thành một công ty tạo lập thị trường và giao dịch tiền điện tử hàng đầu, đỉnh cao là việc mua lại chuỗi khối hàng đầu của Yellow Group công ty cơ sở hạ tầng OpenWare.'
        },
        item8: {
            name: 'Ference',
            title1: `Thành viên cốt lõi của M3 DAO`,
            title2: `Lãnh đạo cộng đồng`,
            font: "Là một chuyên gia marketing với hai mươi năm kinh nghiệm. Đam mê về chiến lược phát triển kinh doanh, có nền tảng kiến thức phong phú về kinh tế học truyền thống, Web3 và công nghệ thông tin. Đã xây dựng một cộng đồng bao gồm những kinh nghiệm toàn cầu."
        },
        item9: {
            name: 'James',
            title1: "Thành viên cốt lõi của M3 DAO",
            title2: "U-topia BD",
            font: "Tốt nghiệp Đại học Adelaide, chuyên ngành Quản lý Chiến lược. Có kinh nghiệm phong phú và am hiểu sâu sắc trong ngành công nghiệp tiền điện tử. Nguyên là giám đốc điều hành tại Bybit, đạt được nhiều thành tựu đáng kể trong phát triển chiến lược, mở rộng cộng đồng và xây dựng hệ sinh thái."
        }
    },
    grant: {
        one: 'Kế hoạch tài trợ của MarsLab',
        two: 'Nộp đơn ngay',
        title1: {
            bolder: 'Tài trợ của MarsLab',
            content: "Chương trình mong muốn dẫn đầu trong lĩnh vực công nghệ và blockchain bằng cách thúc đẩy và hỗ trợ các dự án có tầm nhìn xa, từ đó khuyến khích các nhà phát triển và nhóm tích cực tham gia xây dựng cơ sở hạ tầng nền tảng.Mục tiêu của chúng tôi không chỉ dừng lại ở việc giới thiệu các giải pháp thiết thực và sáng tạo cho cộng đồng; chúng tôi mong muốn tạo ra tác động đáng kể trong ngành và định hướng sự phát triển của công nghệ blockchain."
        },
        title2: {
            font1: "Chương trình được chia thành 12 giai đoạn, mỗi giai đoạn có giải thưởng 500.000 USD.", font2: "Học kỳ 1"
        },
        Goal: {
            title: "Mục tiêu",
            content1: "Để trở thành nhà đổi mới và dẫn đầu trong lĩnh vực blockchain, ứng dụng phi tập trung(dApps), Web3 và các công nghệ liên quan.",
            content2: "Để hỗ trợ các dự án ở mọi giai đoạn, từ lên ý tưởng đến triển khai, đảm bảo chúng tạo ra tác động đáng kể.",
            content3: "培Để nuôi dưỡng một cộng đồng giàu tài nguyên, sôi động gồm các nhà phát triển, người sáng tạo và nhà đổi mới, tăng cường hợp tác và trao đổi kiến ​​thức."
        },
        Grant: {
            title: "Hạng mục tài trợ",
            content1: "Đổi mới công nghệ",
            content2: "Dụng cụ",
            content3: "Xây dựng cộng đồng"
        },
        Qualification: {
            title: "Trình độ chuyên môn",
            content1: "Mở cửa cho tất cả các dự án được xây dựng trên blockchain, nhấn mạnh vào khả năng tương tác và đổi mới đa chuỗi.",
            content2: "Ứng viên phải nộp tác phẩm gốc. Đạo văn bị nghiêm cấm.",
            content3: "Bài nộp phải bao gồm kế hoạch dự án chi tiết, bản demo (nếu có) và thông tin cơ bản về nhóm.",
            content4: "Các dự án phải thể hiện rõ ràng tính thực tiễn, tính đổi mới và tác động tiềm tàng."
        },
        Process: {
            title: "Quá trình",
            content1: "Nộp",
            content2: "Ôn tập",
            content3: "Bỏ phiếu",
            content4: "Hỗ trợ lớn",
        },
        Funding: {
            title: "Cơ cấu tài trợ",
            content1: "Vốn sẽ được phân bổ theo từng giai đoạn theo các mốc quan trọng và sản phẩm bàn giao của dự án.",
            content2: "Tổng giải thưởng sẽ được công bố vào đầu mỗi chu kỳ, với số tiền trợ cấp riêng lẻ khác nhau tùy theo nhu cầu của dự án và tác động tiềm tàng.",
            content3: "Ngoài hỗ trợ tài chính, người chiến thắng cũng sẽ nhận được hỗ trợ kỹ thuật và xúc tiến tiếp thị để đảm bảo thành công của dự án.",
        },
        Community: {
            title: "Sự tham gia và hỗ trợ của cộng đồng",
            content1: "Cộng đồng M3",
            content2: "Hội thảo và hội thảo",
            content3: "Trưng bày",
            content4: "Hơn nữa, chúng tôi sẽ thường xuyên theo dõi tiến độ của các dự án trúng thầu để đảm bảo quá trình phát triển tiếp theo diễn ra suôn sẻ.",
        },
    },
    grantForm: {
        title1: "MarsLab-Zuschussantrag",
        title2: "Bitte füllen Sie dieses Formular aus, um sich zu bewerben. Wir freuen uns darauf, zu erfahren, was Sie bauen werden und wie wir Sie auf Ihrem Weg unterstützen können.",
        input1: {
            place1: "Projektname",
            place2: "Projekt-Twitter",
            place3: "Projekt-Website",
            place4: "Projektbeschreibung",
        },
        input2: {
            place1: "Bitte beschreiben Sie Ihr Projekt in maximal 50 Zeichen.Welches Problem löst es?",
            place2: "Wie funktioniert es?",
        },
        input3: {
            label: "In welche Kategorie fällt Ihr Projekt?",
        },
        input4: {
            label: "Bitte geben Sie Ihr Projektstadium an",
        },
        input5: {
            label: "Bitte erzählen Sie uns von dem beteiligten Team.Wie viele Personen sind es?",
            label2: "Namen, Rollen und Hintergründe der Kernteammitglieder."
        },
        input6: {
            label: "Bitte teilen Sie uns mit, welche Art von Unterstützung Sie von MarsLab benötigen",
        },
        input7: {
            label: "Projektkontaktperson",
            place1: "Name",
            place2: "E-Mail",
            place3: "Telegram",
            place4: "Wallet-Adresse",
        },
        input8: {
            label: "Ihre Empfehlungsinformationen (falls vorhanden)",
        },
        back: "ZURÜCK",
        next: "WEITER",
    },
    grantFinish: {
        font1: "Nộp đơn",
        font2: "Nội dung gửi của bạn đã được nhận để chúng tôi xem xét. Cảm ơn bạn rất nhiều vì đã dành thời gian!",
        button1: "QUAY LẠI CẤP",
        button2: "ĐĂNG KÝ Đơn đăng ký MỚI",
    },
    blog: {
        AMANews: "Tư vấn AMA",
        item1: {
            title: "MarsVerse x U-topia: Đi sâu vào hệ sinh thái MarsVerse và Liên minh Avatar",
            topic: "Sự kiện giới thiệu tổng quan về Hệ sinh thái Marsverse, sự ra mắt chính thức của Liên minh Avatar, giới thiệu về Marsverse và U-topia Avatar, hiểu biết sâu sắc về sự phát triển trong tương lai của dự án U-topia và thảo luận chi tiết về kế hoạch phát triển U-coin và tokenomics.",
            body: "Sự kiện này đã giới thiệu sâu rộng về hệ sinh thái Marsverse và kỷ niệm sự khởi đầu chính thức của mô hình hợp tác Avatar Alliance. Trong sự kiện, Marsverse x U-topia Avatar Alliance cũng đã được giới thiệu, mang đến cho người tham gia cái nhìn thoáng qua về sự phát triển trong tương lai của dự án U-topia. Cuộc họp kết thúc với việc thảo luận chi tiết về kế hoạch phát triển U-coin và kinh tế học mã token, làm sáng tỏ tầm nhìn chiến lược và khung tài chính của dự án.",
            one: {
                title: "Sự kiện nổi bật:",
                ul: [
                    "Để kỷ niệm sự hợp tác giữa M3 DAO và U-topia, Giám đốc điều hành của U-topia đã được mời tham gia phiên chia sẻ Twitter Space của MarsVerse để chia sẻ những hiểu biết sâu sắc về dự án U-topia và sự phát triển trong tương lai của nó.",
                    '- Sự kiện cũng công bố ra mắt mô hình hợp tác Liên minh Avatar và giới thiệu Liên minh Avatar MarsVerse x U-topia.',
                    "Cuối cùng, để trả lời câu hỏi của mọi người về giá trị của Utopia, Giám đốc điều hành của U-topia đã thảo luận chi tiết về kế hoạch phát triển của U-coin và kinh tế token."
                ],
            },
            two: {
                title: "Về Avatar Alliance:",
                ul: [
                    "Avatar Alliance là cốt lõi trong lộ trình chiến lược của MarsVerse, đây là một sáng kiến đột phá hợp tác với các đối tác tiềm năng có tầm nhìn xa. Kế hoạch này nhằm thúc đẩy sự tăng trưởng kinh doanh của các đối tác MarsVerse thông qua liên minh Avatar đồng thương hiệu, tăng cường sức mạnh thương hiệu và thúc đẩy sự tương tác với mạng lưới phi tập trung. ",
                    "Avatar Alliance dự báo một kỷ nguyên mới của sự hợp tác cộng đồng, thúc đẩy sự phát triển và đổi mới chung của MarsVerse và các đối tác của mình thông qua các chiến lược liên minh thương hiệu, tiếp thị đa kênh và các sự kiện toàn cầu."
                ],
            },
            three: {
                title: 'Về MarsVerse x U-topia Avatar Alliance:',
                ul: [
                    "MarsVerse x U-topia Avatar Alliance mang lại cho các nhà đầu tư những lợi ích sang trọng và phần thưởng mã token. Sự kiện ra mắt bao gồm hoạt động airdrop từ ngày 1 tháng 7 đến ngày 20 tháng 7, trong đó người dùng mint các Avatar cụ thể sẽ nhận được phần thưởng bổ sung. Do giá trị cao, sự khan hiếm và phần thưởng lớn của những thẻ này, nhu cầu trong cộng đồng của chúng tôi rất lớn. Sự kiện này đã làm tăng đáng kể giá trị của mã token U-coin của U-topia, với tổng nguồn cung là 1 tỷ token."
                ],
            },
        },
        item2: {
            title: "MarsVerse x U-topia: Tương lai của Metaverse: Cuộc cách mạng tiếp theo là gì?",
            topic: "Cuộc thảo luận khám phá những thách thức và cơ hội trong việc tạo ra trải nghiệm liền mạch trên các nền tảng metaverse khác nhau, xem xét sự phát triển của thị trường hàng hóa và dịch vụ ảo ngoài NFT và đi sâu vào cách metaverse sẽ định hình các mối quan hệ và tương tác xã hội trong tương lai.",
            body: "Cuộc thảo luận này đi sâu vào những thách thức và cơ hội trong việc tạo ra trải nghiệm liền mạch trên các nền tảng Metaverse khác nhau. Nó xem xét sự phát triển của thị trường hàng hóa và dịch vụ ảo ngoài NFT và khám phá cách Metaverse sẽ định hình các mối quan hệ và tương tác xã hội trong tương lai, thể hiện đầy đủ tiềm năng và tác động của bối cảnh kỹ thuật số đang phát triển nhanh chóng này.",
            one: {
                title: "Sự kiện nổi bật:",
                ul: [
                    "Các giám đốc điều hành của MarsVerse đã bắt tay với các đại diện dự án U-topia bay trên Không gian Twitter MarsVerse để chia sẻ những thách thức và cơ hội trong Metaverse cũng như tương lai của Metaverse.",
                    "Họ cũng cung cấp câu trả lời cho các câu hỏi của người dùng về sự phát triển trong tương lai của Metaverse."
                ],
            },
            two: {
                title: "Giới thiệu về Metaverse:",
                ul: [
                    "Metaverse là một không gian chia sẻ ảo tập thể được hình thành bởi sự kết hợp giữa thực tế vật lý ảo nâng cao và không gian ảo bền vững về mặt vật lý. Nó bao gồm tất cả các thế giới ảo, thực tế tăng cường và Internet kết hợp. Trong Metaverse, người dùng có thể tương tác với môi trường do máy tính tạo ra và những người dùng khác trong thời gian thực, trải nghiệm môi trường kỹ thuật số phong phú hòa quyện hoàn hảo với thế giới thực."
                ],
            },
            three: {
                title: "Giới thiệu về mã thông báo không thể thay thế (NFT):",
                ul: [
                    "Mã thông báo không thể thay thế (NFT) là tài sản kỹ thuật số độc đáo sử dụng công nghệ chuỗi khối để thể hiện quyền sở hữu một mục hoặc nội dung cụ thể, chẳng hạn như nghệ thuật, âm nhạc, video hoặc bất động sản ảo. Không giống như các loại tiền điện tử như Bitcoin hay Ethereum, NFT không thể chia cắt và không thể thay đổi lẫn nhau, khiến mỗi mã thông báo trở nên độc đáo và có giá trị. NFT cung cấp một cách để xác thực và chứng minh quyền sở hữu các sáng tạo kỹ thuật số, mở ra các hình thức giao dịch và tương tác kỹ thuật số mới."
                ],
            },
        },
        item3: {
            title: "Khám phá M3 DAO: Tầm nhìn và Đổi mới",
            topic: "Nó cung cấp cái nhìn tổng quan chi tiết về các sản phẩm và giá trị của M3 DAO, giải thích cách M3 DAO đạt được khả năng quản trị cộng đồng và thảo luận về những đóng góp của nó cho tương lai của ngành Web3.",
            body: "Đây là lần đầu tiên M3 DAO mang sản phẩm và giá trị của mình đến với người dùng Nhật Bản và giao lưu với họ. Phản ứng nhiệt tình trên không gian Twitter cũng cho thấy người dùng Nhật Bản hoàn toàn đồng ý với các giá trị cốt lõi của M3 DAO và mong chờ cuộc cách mạng metaverse do M3 DAO mang lại. Điều này đánh dấu bước đầu tiên để M3 DAO mở cửa thành công thị trường Nhật Bản và chúng tôi mong muốn M3 DAO phát triển hơn nữa tại Nhật Bản.",
            one: {
                title: "Sự kiện nổi bật:",
                ul: [
                    "Đại diện AMA Nhật Bản đầu tiên của M3 DAO, đại diện M3 DAO xuất hiện trên Không gian Twitter của các KOL Nhật Bản và chia sẻ tầm nhìn, sứ mệnh và giá trị của M3 DAO, nhấn mạnh tầm quan trọng của quản trị cộng đồng đối với M3 DAO.",
                    "Trả lời câu hỏi của khán giả Nhật Bản về các sản phẩm cốt lõi M3 DAO MarsVerse, MarsChain và MarsProtocol."
                ],
            },
            two: {
                title: "Về tầm nhìn, mục tiêu và sứ mệnh của M3 DAO?",
                ul: [
                    "Tầm nhìn: Trở thành DAO đầu tư và ươm tạo có ảnh hưởng nhất thế giới, thúc đẩy đổi mới và phát triển bền vững.",
                    "Mục tiêu: ươm tạo mười dự án kỳ lân Web3 hàng đầu thế giới theo giá trị thị trường.",
                    "Sứ mệnh: Thông qua mô hình quản trị phi tập trung, thu thập các nguồn lực toàn cầu, hỗ trợ và ươm tạo các dự án đổi mới có tiềm năng, đồng thời thúc đẩy tiến bộ công nghệ và phúc lợi xã hội."
                ],
            },
            three: {
                title: "Quản trị cộng đồng có ý nghĩa gì với M3 DAO?",
                ul: [
                    "Quản trị cộng đồng của M3 DAO cho phép các thành viên tham gia vào quá trình ra quyết định, đề xuất sửa đổi và bỏ phiếu về các vấn đề chính. Các nội dung trên đều được thực hiện thông qua MarsVerse. Đồng thời, MarsVerse thực hiện cơ chế quản trị cộng đồng toàn cầu để cho phép người dùng tác động đến sự phát triển và chính sách của hệ sinh thái."
                ],
            },
        },
        item4: {
            title: "Hội nghị simulcast trực tuyến M3: Phiên họp đặc biệt của cộng đồng người Hoa U-Topia",
            topic: "Phiên này đã giới thiệu về U-Topia, đối tác đồng thương hiệu M3 DAO, cũng như loạt bộ bài Avatar đồng thương hiệu MarsVerse x U-Topia sắp ra mắt và các sự kiện phát hành airdrop liên quan!",
            body: "Cuộc họp này đã giới thiệu U-Topia với tư cách là đối tác đồng thương hiệu của M3 DAO và thẻ đồng thương hiệu MarsVerse x U-Topia sắp ra mắt. Cuộc họp cũng nêu bật chi tiết phát hành của các hoạt động airdrop liên quan, đặt nền tảng cho sự hợp tác thú vị.",
            one: {
                title: "Sự kiện nổi bật:",
                ul: [
                    "Để kỷ niệm sự hợp tác giữa M3 DAO và U-topia, Giám đốc điều hành của U-topia đã tham gia buổi phát sóng trực tiếp để chia sẻ những hiểu biết sâu sắc về dự án U-topia và sự phát triển trong tương lai của nó.",
                    "Sự kiện này cũng đánh dấu sự ra mắt chính thức của Liên minh Avatar MarsVerse x U-topia và chiến dịch airdrop liên quan của nó."
                ],
            },
            two: {
                title: "Giới thiệu về M3 DAO:",
                ul: [
                    "M3 DAO là một tổ chức tự trị phi tập trung tập trung vào việc xác định và hỗ trợ các công ty khởi nghiệp blockchain có tiềm năng cao. M3 DAO tận dụng trí tuệ tập thể của cộng đồng toàn cầu để đưa ra quyết định đầu tư, cung cấp cách tiếp cận dân chủ và toàn diện hơn cho đầu tư mạo hiểm."
                ],
            },
            three: {
                title: "Giới thiệu về U-topia:",
                ul: [
                    "U-topia là công ty MediaFi đầu tiên trên thế giới, sự kết hợp giữa cấp phép IP đổi mới toàn cầu trong GameFi, âm nhạc AI và giải trí video, được hỗ trợ bởi danh mục 70.000 phần thưởng tài sản trong thế giới thực. U-topia tận dụng tài sản trí tuệ (IP) hàng đầu trong Web2, chẳng hạn như nội dung trò chơi thể thao đồng đội chuyên nghiệp, nội dung video từ Bandai Namco, v.v., cũng như nội dung từ 100 nhà bán lẻ hàng đầu toàn cầu như Nike, đồng thời tích hợp Web3 của NFT hàng đầu sưu tầm."
                ],
            },
        },
        item5: {
            title: "Phiên chia sẻ sinh thái MarsVerse x Utopia: Kế hoạch phát triển sinh thái MarsVerse M3 đã hoàn thiện và loạt phim chung Avatar chính thức ra mắt",
            topic: "Nhận thông tin chi tiết về Avatar đồng thương hiệu MarsVerse x U-topia Avatar và các chương trình khuyến mãi Biết thêm về hệ sinh thái U-topia và hệ thống mã thông báo U-coin.",
            body: "Người dùng cộng đồng đã làm việc với đại diện của U-topia để tìm hiểu thêm về thẻ đồng thương hiệu MarsVerse x U-topia và các hoạt động khuyến mại thú vị của nó. Khám phá hệ sinh thái U-topia và đi sâu vào nền kinh tế mã thông báo U-coin để hiểu đầy đủ ý nghĩa chiến lược của nó.",
            one: {
                title: "Sự kiện nổi bật:",
                ul: [
                    "Đại diện dự án U-topia đã đến thăm phòng phát sóng trực tiếp và chia sẻ về Avatar đồng thương hiệu MarsVerse x U-topia cũng như các hoạt động quảng bá liên quan.",
                    "Đồng thời, những thắc mắc của người dùng về hệ sinh thái U-topia và nền kinh tế token U-coin cũng được giải đáp."
                ],
            },
            two: {
                title: "Giới thiệu về thẻ đồng thương hiệu MarsVerse x U-topia:",
                ul: [
                    "Thẻ đồng thương hiệu MarsVerse x U-topia mang đến cho nhà đầu tư những lợi ích xa xỉ và phần thưởng mã thông báo. Sự kiện ra mắt bao gồm một sự kiện airdrop từ ngày 1 tháng 7 đến ngày 20 tháng 7, với phần thưởng bổ sung dành cho người dùng tạo ra các Hình đại diện cụ thể. Do giá trị cao, sự khan hiếm và phần thưởng hào phóng mà những loại thẻ này mang lại nên chúng có nhu cầu rất cao trong cộng đồng của chúng tôi. Sự kiện này đã làm tăng đáng kể giá trị của token Ucoin của U-topia, với tổng nguồn cung là 1 tỷ. "
                ],
            },
            three: {
                title: "Giới thiệu về U-coin:",
                ul: [
                    "Biểu tượng token: U-coin",
                    "Tổng nguồn cung: 1 tỷ",
                    "Ngày niêm yết trên sàn giao dịch: Đầu tháng 8",
                    "Phân phối mã thông báo U-coin:",
                    "Tài trợ chiến lược：1.25%、Nhóm/Tư vấn: 17%、Vòng hạt: 2,78%、Phân bổ cộng đồng: 7,5%、Bán công khai: 4,75%、Hệ sinh thái: 35,4%、Bán riêng: 4,32%、Đối tác: 8%、Phần thưởng đặt cược: 3%、Thanh khoản sàn giao dịch tập trung: 10%、Phần thưởng của nhà cung cấp thanh khoản: 2%、Thanh khoản sàn giao dịch phi tập trung: 4%"
                ],
            },
        },
        item6: {
            title: "Hội nghị đồng thuận cộng đồng người Trung Quốc M3 Mars: Những người nổi tiếng M3 DAO là khách mời!",
            topic: "Người sáng lập G.C Capital và Trưởng nhóm đầu tư M3 DAO-GC và thành viên cộng đồng M3 DAO Châu Âu Ference đã ghé qua để chia sẻ các xu hướng đầu tư gần đây vào thị trường tiền điện tử và chìa khóa xây dựng sự đồng thuận của cộng đồng!",
            body: "GC, người sáng lập G.C Capital và người đứng đầu bộ phận đầu tư M3 DAO, và Ferenc, thành viên của cộng đồng M3 DAO Châu Âu, đã có mặt để chia sẻ những hiểu biết sâu sắc về xu hướng đầu tư vào thị trường tiền điện tử gần đây và thảo luận về các chiến lược chính để xây dựng sự đồng thuận của cộng đồng. Cuộc thảo luận của họ đã cung cấp một góc nhìn độc đáo và những hiểu biết sâu sắc có giá trị về cách điều hướng thế giới đầu tư tiền điện tử luôn thay đổi và xây dựng một cộng đồng đoàn kết, mạnh mẽ.",
            one: {
                title: "Sự kiện nổi bật:",
                ul: [
                    "GC, người sáng lập GC Capital và là người đứng đầu bộ phận đầu tư M3 DAO, đã xuất hiện trong phòng phát sóng trực tiếp để chia sẻ những hiểu biết chuyên môn của mình về xu hướng đầu tư gần đây trên thị trường tiền điện tử.",
                    "Ferenc, một thành viên quan trọng của cộng đồng M3 DAO Châu Âu, đã trình bày cách nhanh chóng mở rộng cộng đồng và xây dựng sự đồng thuận của cộng đồng."
                ],
            },
            two: {
                title: "Đầu tư Web3 là gì?",
                ul: [
                    "Web3 Investments tập trung vào việc tài trợ và phát triển các công nghệ phi tập trung tạo thành nền tảng cho thế hệ Internet tiếp theo. Điều này bao gồm các dự án blockchain, tiền điện tử, nền tảng tài chính phi tập trung (DeFi), mã thông báo không thể thay thế (NFT) và các công nghệ khác nhằm thúc đẩy quyền sở hữu, quyền riêng tư và quyền kiểm soát của người dùng đối với tài sản và dữ liệu kỹ thuật số. Web3 Investments nhằm mục đích thay đổi cách chúng ta tương tác trực tuyến, chuyển quyền lực khỏi các thực thể tập trung sang tay người dùng cá nhân và cộng đồng."
                ],
            },
            three: {
                title: "Sự đồng thuận của cộng đồng là gì?",
                ul: [
                    "Sự đồng thuận của cộng đồng đề cập đến quá trình đạt được thỏa thuận trong một nhóm hoặc cộng đồng, thường được sử dụng trong môi trường hợp tác và phi tập trung. Quá trình này bao gồm sự giao tiếp cởi mở, sự tham gia và tham vấn để đảm bảo rằng các quyết định phản ánh mong muốn và lợi ích chung của các thành viên cộng đồng. Trong các dự án blockchain và Web3, sự đồng thuận của cộng đồng là rất quan trọng để xác thực các giao dịch, thực hiện các thay đổi cũng như duy trì sự tin cậy và minh bạch trong mạng."
                ],
            },
        },
        item7: {
            title: "Hội nghị đồng thuận cộng đồng người Trung Quốc M3 Mars: Những người nổi tiếng M3 DAO là khách mời!",
            topic: "Trưởng cộng đồng M3 DAO Áo Harry cùng với nghệ sĩ châu Âu và Mỹ CJ đã nhảy dù vào phòng phát sóng trực tiếp để chia sẻ kinh nghiệm mở rộng cộng đồng, xây dựng sự đồng thuận của cộng đồng Cách nhận ra sự tăng trưởng phân hạch tài sản tiền điện tử trong 30 ngày, chỉ muốn hay không, không thể hoặc. không thể!",
            body: "Lãnh đạo cộng đồng người Áo M3 DAO Harry và nghệ sĩ Âu Mỹ CJ đã tham gia buổi phát sóng trực tiếp và chia sẻ kinh nghiệm của họ trong việc mở rộng cộng đồng và xây dựng sự đồng thuận. Họ đã thảo luận về các chiến lược để đạt được sự tăng trưởng bùng nổ về tài sản tiền điện tử chỉ trong 30 ngày, nhấn mạnh tâm lý “hãy hỏi xem bạn có thể chứ không phải nếu”. Những hiểu biết sâu sắc của họ cung cấp hướng dẫn có giá trị để thúc đẩy sự phát triển nhanh chóng và bền vững trong cộng đồng.",
            one: {
                title: "Sự kiện nổi bật:",
                ul: [
                    "Harry, lãnh đạo cộng đồng M3 DAO của Áo, đã xuất hiện trong phòng phát sóng trực tiếp và chia sẻ kinh nghiệm của mình trong việc mở rộng cộng đồng và xây dựng sự đồng thuận. Ông giải thích cách đạt được sự tăng trưởng nhanh chóng của tài sản tiền điện tử trong vòng 30 ngày, nhấn mạnh tâm lý “chỉ cần hỏi nếu bạn muốn chứ không phải nếu bạn có thể”.",
                    "CJ là một nghệ sĩ Âu Mỹ hiện đang rất tích cực rao giảng ở M3 và điều hành các cộng đồng trên khắp ba châu lục và các quốc gia khác nhau. Anh ấy cũng chia sẻ kinh nghiệm của mình về tăng trưởng tài sản trong buổi phát sóng trực tiếp và thảo luận về các khả năng khác nhau của M3."
                ],
            },
            two: {
                title: "Sự đồng thuận của cộng đồng là gì?",
                ul: [
                    "Sự đồng thuận của cộng đồng đề cập đến quá trình đạt được thỏa thuận trong một nhóm hoặc cộng đồng, thường được sử dụng trong môi trường hợp tác và phi tập trung. Quá trình này bao gồm sự giao tiếp cởi mở, sự tham gia và tham vấn để đảm bảo rằng các quyết định phản ánh mong muốn và lợi ích chung của các thành viên cộng đồng. Trong các dự án blockchain và Web3, sự đồng thuận của cộng đồng là rất quan trọng để xác thực các giao dịch, thực hiện các thay đổi cũng như duy trì sự tin cậy và minh bạch trong mạng."
                ],
            },
            three: {
                title: "Giới thiệu về M3 DAO:",
                ul: [
                    "M3 DAO kết hợp quản lý tài sản kỹ thuật số và chuỗi công khai Metaverse, Lớp 2 để tạo ra một hệ sinh thái kỹ thuật số mở, đa dạng, sáng tạo và do cộng đồng quản lý, kết nối Web3 với thế giới thực. Ba sản phẩm chính của nó bao gồm: MarsVerse, một hệ sinh thái metaverse tập trung vào việc thuộc địa hóa giữa các vì sao; MarsChain, một giải pháp chuỗi khối Lớp 2 cung cấp cơ sở hạ tầng an toàn và hiệu quả; và MarsProtocol, một nền tảng bao gồm đầu tư, DeFi, đặt cược, quản lý tài sản và các giao thức tài chính cốt lõi cho vốn mạo hiểm. M3 DAO đang xây dựng một hệ sinh thái Web3 đa dạng và tương tác, mời tất cả những người tò mò và sáng tạo tham gia định hình tương lai."
                ],
            },
        },
        item8: {
            title: "Hội nghị đồng thuận cộng đồng toàn cầu M3 Mars: Lãnh đạo M3 DAO ghé thăm!",
            topic: "L.A. và Emmanuel, hai thành viên cốt lõi của M3 DAO, sẽ thảo luận về Kế hoạch sinh thái Metaverse MetaMars và đợt tuyển dụng đăng ký mã thông báo vòng hạt giống sắp tới vào tháng 8!",
            body: "Hai thành viên cốt lõi của M3 DAO, L.A. và Emmanuel, đã thảo luận về kế hoạch sinh thái MetaMars Metaverse và đợt tuyển dụng đăng ký mã thông báo vòng hạt giống sắp tới vào tháng 8. Những hiểu biết sâu sắc của họ cung cấp thông tin đầu vào có giá trị về tầm nhìn chiến lược và cơ hội cho hệ sinh thái M3 DAO, đặt nền tảng cho sự phát triển và đổi mới trong tương lai.",
            one: {
                title: "Sự kiện nổi bật:",
                ul: [
                    "L.A. và Emmanuel, hai thành viên cốt lõi của M3 DAO, bay trong phòng phát sóng trực tiếp để thảo luận về kế hoạch sinh thái MetaMars Metaverse.",
                    "Trả lời các câu hỏi của người dùng về đợt tuyển dụng đăng ký mã thông báo vòng hạt giống sắp tới vào tháng 8."
                ],
            },
            two: {
                title: "Giới thiệu về metaverse MetaMars?",
                ul: [
                    "MetaMars là một nền tảng metaverse Web3 theo chủ đề sao Hỏa. Bằng cách tích hợp thực tế ảo, kinh tế học mật mã và mạng xã hội, MetaMars tạo ra một cách tương tác kỹ thuật số mới và tạo ra giá trị.Thế giới kỹ thuật số sáng tạo này kết hợp việc khám phá sao Hỏa với công nghệ chuỗi khối để cung cấp cho người dùng trải nghiệm nhập vai độc đáo.",
                    "Trong MetaMars, người tham gia có thể khám phá cảnh quan sao Hỏa được chế tạo cẩn thận, xây dựng căn cứ của riêng mình và kiếm phần thưởng. Đồng thời, những người du hành giữa các vì sao từ khắp nơi trên thế giới hợp tác để khám phá những bí ẩn của vũ trụ."
                ],
            },
            three: {
                title: "Giới thiệu về mã thông báo MCD:",
                ul: [
                    "Mã thông báo MCD là mã thông báo tiện ích gốc của hệ sinh thái M3 DAO. Nó được sử dụng để quản trị, đặt cược và truy cập vào các dịch vụ khác nhau trong MarsVerse, MarsChain và MarsProtocol."
                ],
            },
        }
    },
    footer: {
        one: {
            title: "Hệ sinh thái",
            item1: "MarsVerse",
            item2: "Sao HỏaChuỗi",
            item3: "MarsGiao thức",
            item4: "hình đại diện",
            item5: "MarsLab",
            item6: "Trình khám phá chuỗi khối",
        },
        two: {
            title: "Ủng hộ",
            item1: "Về chúng tôi",
            item2: "Bộ phương tiện truyền thông",
            item3: "Liên hệ chúng tôi",
        }
    }
}