module.exports = {
    tabList: {
        list1: 'Home',
        list2: 'Products',
        list3: 'Portfolio',
        list4: 'News',
        list5: 'Contact',
        list6: 'Team',
        list7: 'Grant',
        list8: 'Blog',
    },
    home: {
        slogan: "Web3 at your fingertips",
        number1: 'Active Users',
        number2: 'global Nodes',
        number3: 'Online and Offline',
        number4: 'Collaboration Ecosystems',
        box_title: 'Uncovering the potential of Web3 with M3 DAO',
        box_content: "M3 DAO is a decentralized autonomous organization that integrates the Metaverse, Layer 2 public chain, and digital asset management.With the three major sectors of MarsVerse, MarsChain, and MarsProtocol(MarsLab) as its core, M3 DAO is building a new multi - interaction Web3 ecosystem.Under the values of community autonomy, diversification, innovation, and openness, M3 DAO will work with global users to jointly promote the deepconnection between Web3 and the real world, and create a future Web3 landscape.",
        introduce: "U-topia - MediaFi PlatformU- topia is the world's first MediaFi company, which has a large number of IP resources and combines Web2 and Web3 through blockchain technology.",
        helloArray: [
            'Blockchain Technology',
            'DeFi',
            'DApps',
            'Metaverse',
            'NFTs',
            'DAOs',
            'Smart Contracts',
            'Infrastructure and Tools',
            'Privacy and Security',
            'Cross-Chain Solutions',
            'Decentralized Storage',
            'Decentralized Identity',
            'Decentralized Computing',
            'RWA',
            'SocialFi',
            'MediaFi',
            'Decentralized Prediction Markets',
            'Decentralized Governance'
        ],
        blog: "Report",
        dq_title: 'Global Community',
        bottom_title: 'Explore the Infinite Possibilities of Web3',
        bottom_font1: 'Web3 is reshaping the world, and we believe that through decentralized technology, we will build a more open, fair and autonomous digital world.',
        bottom_font2: 'Walk with Web3, walk with innovation.',
        button_button: "Contact"
    },
    Product: {
        mv1: "Avatar",
        mv2: "Interstellar",
        mv3: "Colonization",
        mv4: "RWA Platform",
        mc1: "Layer2",
        mc2: "DID",
        mc3: "Infrastructure",
        mc4: "Supporting Ecosystem",
        mp1: "MarsLab",
        mp2: "Web3 Investment and Incubation",
        mp3: "Digital Payment Solutions",
        mp4: "DAO Foundation",
    },
    Portfolio: {
        slogan1: "We look forward to working with innovative projects or companies.",
        slogan2: "Click the button to submit information.",
        submit: "Project Submission",
        title: "Portfolio",
        list1: {
            title: 'Data Privacy and Security',
            font: 'Utilize Layer 2 and decentralized identity (DID) technology to ensure user data autonomy and privacy protection.',
        },
        list2: {

            title: 'Community autonomy',
            font: 'Global users and community members participate in governance and achieve democratic decision-making.',
        },
        list3: {

            title: 'Community autonomy',
            font: 'Global users and community members participate in governance and achieve democratic decision-making.',
        },
        list4: {

            title: 'Tokenization of real assets',
            font: 'Support on-chain management of real assets to achieve transparent and efficient asset management. Reached cooperation with more than 400 entities.',
        },
        list5: {

            title: 'Digital Asset Management',
            font: 'Provide comprehensive digital asset management services such as research, investment, and incubation for global users and high-quality projects to meet diverse needs',
        },
        list6: {

            title: 'Project incubation',
            font: 'Provide funding, branding, operations and technical support to emerging Web3 projects through the MarsLab Incubation Platform',
        }
    },
    news: {
        title: 'News & Contents'
    },
    support: {
        title: 'SUPPORT',
        font1: 'If you have better comments or suggestions for us, please give us feedback and I will receive them in time',
        input1: 'Input Your Title here',
        input2: 'Input Your Questions',
        input3: 'Please leave your email address, and our staff will get back to you!',
        input4: 'Submitted successfully',
        button2: 'Return',
        button1: 'CONFIRM',
    },
    team: {
        font1: 'M3 DAO Team',
        font2: 'Our top- tier team is composed of leading Web3 leaders from around the globe, dedicated tobringing unique technology and innovation experiences to users worldwide, and fully promoting the development of Web3.We look forward to working with all members of M3 DAO toredefine decentralized technology and experience the unparalleled blockchain revolution.',
        item1: {
            name: 'EMMANUEL QUEZADA',
            title1: `M3 DAO Builder `,
            title2: `IP & Branding Lead`,
            font: 'As the founder of U- topia Ecosystem, he has been working in blockchain technology for 7 years and is a certified blockchain expert and blockchain consultant for KYC, CFT and AML matters.'
        },
        item2: {
            name: 'L.A',
            title1: `M3 DAO Builder `,
            title2: `Ecosystem Lead`,
            font: 'Early BTC Evangelist Bitcoin Miner Malaysian Entrepreneur with Over 400 Physical Businesses President of Multiple Listed Companies Global Community Leader'
        },
        item3: {
            name: 'MICHAEL GORD',
            title1: `M3 DAO Builder `,
            title2: `Investment Lead`,
            font: 'Serial entrepreneur (3 exits + 3 acquisitions), global citizen, disruptive technologyinvestor/advisor (over 80 portfolio companies/4 seed-stage -> unicorn), public speaker,and financier (over $70 million in direct financing, over $210 million in structuredfinancing), focusing on the mass adoption of digital assets and blockchain technology.'
        },
        item4: {
            name: 'DARREN SMITH',
            title1: `M3 DAO Builder `,
            title2: `IT Lead`,
            font: 'A veteran in the video game industry. Worked at Nintendo for 20 years, dedicated to the development of consoles such as Nintendo 64, Wii, DSi, 3DS, as well as applications and games. Joined Epik Prime in 2018, working on bringing NFTs to gaming.'
        },
        item5: {
            name: 'GC',
            title1: `M3 DAO Builder `,
            title2: `Founder of Golden Carrot Capital`,
            font: 'A crypto entrepreneur with 8 years of experience in blockchain and rich financial expertise from key roles at leading crypto exchanges like Binance and MEXC Global, is renowned for his unique insights into crypto and the digital industry.'
        },
        item6: {
            name: 'OWEN MA',
            title1: `M3 DAO Builder `,
            title2: `IP Lead`,
            font: 'Vice President of Operations and Finance for the U-topia Ecosystem. He has extensive experience in intellectual property licensing, asset financing, reward programs, music festivals, and real estate.He successfully built the largest enterprise B2B ecosystem in Web3, integrating games and brands.'
        },
        item7: {
            name: 'ALEXIS SIRKIA',
            title1: `M3 DAO Builder `,
            title2: `Financial Lead`,
            font: 'An experienced entrepreneur with a strong track record in the cryptocurrency industry, he successfully navigated the constantly changing industry environment, evolving from an early investor in XRP to founding a leading cryptocurrency trading and market-making company, eventually acquiring OpenWare, a leading blockchain infrastructure company under Yellow Group.'
        },
        item8: {
            name: 'Ference',
            title1: `Core member of M3 DAO`,
            title2: `Community Lead `,
            font: 'Sales Leader with twenty years experience. Passionate individual regarding Business development strategizing with broad background knowledge in economics and IT. In possession of an influential network consisting of high standards people worldwide.'
        },
        item9: {
            name: 'James',
            title1: `Core member of M3 DAO`,
            title2: `U-topia BD`,
            font: "Graduated from the University of Adelaide, majoring in Strategic Management. Deeply rooted in the crypto industry with rich experience. Former executive at Bybit, with significant achievements in strategic development, community expansion, and ecosystem building."
        }
    },
    grant: {
        one: 'MarsLab Grant plan',
        // MarsLab 资助计划
        two: 'Apply Immediately',
        // 立即申请
        title1: {
            bolder: 'MarsLab Grants',
            content: "The program aspires to be at the forefront of blockchain and technology sectors by fostering and backing projects with foresight, thereby encouraging developers and teams to actively engage in building foundational infrastructure. Our goal extends beyond merely introducing practical and innovative solutions to the community; we aim to make a substantial impact in the industry and guide the direction of blockchain technology's evolution."
        },
        // MarsLab 资助
        // 该计划旨在通过培育和支持具有远见的项目，从而鼓励开发人员和团队积极参与基础设施建设，从而走在区块链和技术领域的最前沿。我们的目标不仅仅是向社区提供实用和创新的解决方案；我们的目标是对行业产生实质性影响，引导区块链技术的演进方向。
        title2: {
            font1: "The program is divided into 12 phases, each phase with a prize of $500,000.",
            font2: "Term 1"
        },
        Goal: {
            title: "Goal",
            content1: "To become an innovator and leader in the realms of blockchain, decentralized applications (dApps), Web3, and related technologies.",
            content2: "To support projects at every phase, from conceptualization to deployment, ensuring they make a significant impact.",
            content3: "To cultivate a resource-rich, vibrant community of developers, creators, and innovators, enhancing collaboration and knowledge exchange."
        },
        Grant: {
            title: "Grant Category",
            content1: "Technology innovation",
            content2: "Tool",
            content3: "Community building"
        },
        // Cycle: {
        //     title: "Application Cycle",
        //     content1: "Submission period",
        //     content2: "Review period",
        //     content3: "Winner list announce"
        // },
        Qualification: {
            title: "Qualification",
            content1: "Open to all projects built on the blockchain, emphasizing multi-chain interoperability and innovation.",
            content2: "Applicants must submit original works. Plagiarism is strictly prohibited.",
            content3: "Submissions should include a detailed project plan, a demo (if available), and team background.",
            content4: "Projects must demonstrate clear practicality, innovation, and potential impact."
        },
        Process: {
            title: "Process",
            content1: "Submit",
            content2: "Review",
            content3: "Vote",
            content4: "Grand support",
        },
        Funding: {
            title: "Funding Structure",
            content1: "Funds will be distributed in stages according to project milestones and deliverables.",
            content2: "RThe total prize pool will be announced at the start of each cycle, with individual grant amounts varying based on project needs and potential impact.",
            content3: "In addition to financial support, winners will also receive technical support and marketing promotion to ensure project success.",
        },
        Community: {
            title: "Community Participation and Support",
            content1: "M3 Community",
            content2: "Seminar and workshop",
            content3: "Display",
            content4: "Moreover, we will follow up on the progress of winning projects regularly to ensure smooth subsequent development.",
        },
    },
    grantForm: {
        title1: "MarsLab Grant Application",
        title2: "Please fill out this form to apply. We look forward to learning about what you will build and how we can support your journey.",
        input1: {
            place1: "Project Name",
            place2: "Project Twitter",
            place3: "Project Website",
            place4: "Project Brief",
        },
        input2: {
            place1: "Please describe your project in 50 characters or less. What problem does it solve?",
            place2: "How does it work?",
        },
        input3: {
            label: "What is the category of your project?",
        },
        input4: {
            label: "Please state your project stage",
            // 请说明您的项目阶段
        },
        input5: {
            label: "Please tell us about the team involved. How many people are there?",
            label2: "Core team members' names, roles, and backgrounds"
            // 请告诉我们相关团队的情况。有多少人？ 核心团队成员的姓名、职位和背景。
        },
        input6: {
            label: "Please tell us what kind of support you need from MarsLab",
            // 请告诉我们您需要 MarsLab 提供什么样的支持
        },
        input7: {
            label: "Project Contact Person",
            place1: "Name",
            place2: "Email",
            place3: "Telegram",
            place4: "Wallet Address",
        },
        input8: {
            label: "Your Referrer Information (if any)",
        },
        back: "BACK",
        next: "NEXT",
    },
    grantFinish: {
        font1: "Application SUBMISSION",
        font2: "Your submission has been received for our review. Thank you so much for taking the time!",
        button1: "BACK TO GRANT",
        button2: "SUBMISSION NEW Application",
    },
    blog: {
        AMANews: "AMA News",
        item1: {
            title: "MarsVerse x U-topia: Get insights into the MarsVerse Ecosystem and Avatar Alliance",
            topic:
                'The event featured an overview of the Marsverse Ecosystem, the official launch of the Avatar Alliance, introductions to Marsverse and U-topia Avatar, insights into the future development of the U-topia project, and a detailed discussion on the U-coin development plan and tokenomics.',
            body: "The event provided an in-depth overview of the Marsverse Ecosystem and celebrated the official launch of the Avatar Alliance. It featured introductions to both Marsverse and U-topia Avatars, offering attendees a glimpse into the future development of the U-topia project. The session concluded with a detailed discussion on the U-coin development plan and tokenomics, shedding light on the project's strategic vision and financial framework.",
            one: {
                title: 'Event Highlights:',
                ul: [
                    "To celebrate the partnership between M3 DAO and U-topia, U-topia's CEO joined the live broadcast to share insights into the U-topia project and its future development.",
                    'The event also marked the official launch of the MarsVerse x U-topia Avatar Alliance, including a related airdrop event.',
                    "Finally, to address questions about the value of Utopia, U-topia's CEO provided a detailed discussion on the U-coin development plan and tokenomics."
                ],
            },
            two: {
                title: "About Avatar Alliance:",
                ul: [
                    "At the heart of  MarsVerse's strategic roadmap is the Avatar Alliance initiative, a groundbreaking collaboration with visionary and potential partners. This initiative aims to boost the business growth of MarsVerse's partners through co-branded Avatar Alliances, enhancing brand presence and facilitating interaction with decentralized networks.",
                    "The Avatar Alliance initiative heralds a new era of cross - community synergy, driving mutual growth and innovation for both MarsVerse and its partners through strategic co- branding, multi- channel marketing, and global events."
                ],
            },
            three: {
                title: 'About MarsVerse x U-topia Avatar Alliance:',
                ul: [
                    "MarsVerse & U-topia Avatar Alliance Cards, which offer luxurious benefits and token rewards to investors. The launch included an airdrop event from July 1 to July 20, providing additional rewards for minting specific Avatars. Due to the high value, exclusivity, and huge rewards associated with holding these cards, the demand within our community is immense. This campaign significantly enhances the value of U-topia's Ucoin token, with a total supply of 1 billion. "
                ],
            },
        },
        item2: {
            title: "MarsVerse x U-topia: The Future of the Metaverse: What's the Next Revolution?",
            topic:
                'The discussion explored the challenges and opportunities in creating seamless experiences across different metaverse platforms, examined the evolution of virtual goods and services markets beyond NFTs, and delved into how the metaverse will shape future social interactions and relationships.',
            body: "The discussion delved into the challenges and opportunities of creating seamless experiences across various metaverse platforms. It examined the evolution of virtual goods and services markets beyond NFTs and explored how the metaverse will shape future social interactions and relationships, offering a comprehensive look at the potential and impact of this rapidly evolving digital landscape.",
            one: {
                title: "Event Highlights:",
                ul: [
                    "The MarsVerse's bigwigs and U-topia project representatives parachuted into the MarsVerse Twitter space to share the challenges and opportunities in the metaverse and the future of the metaverse.",
                    "Answered user's question about the evolution of the future metaverse."
                ],
            },
            two: {
                title: "About the Metaverse:",
                ul: [
                    "The metaverse is a collective virtual shared space, created by the convergence of virtually enhanced physical reality and physically persistent virtual spaces. It includes the sum of all virtual worlds, augmented reality, and the internet. In the metaverse, users can interact with a computer-generated environment and other users in real time, experiencing immersive digital environments that blend seamlessly with the real world."
                ],
            },
            three: {
                title: "About NFTs:",
                ul: [
                    "Non-Fungible Tokens (NFTs) are unique digital assets that represent ownership of a specific item or piece of content, such as art, music, videos, or virtual real estate, using blockchain technology. Unlike cryptocurrencies like Bitcoin or Ethereum, NFTs are indivisible and cannot be exchanged on a one-to-one basis, making each token distinct and valuable. They provide a way to authenticate and prove ownership of digital creations, enabling new forms of digital commerce and interaction."
                ],
            },
        },
        item3: {
            title: 'Discovering M3 DAO: Vision and Innovation',
            topic:
                "It provided a detailed overview of M3 DAO's products and values, explained how M3 DAO achieves community governance, and discussed its contributions to the future of the Web3 industry.",
            body: "This was the first time that M3 DAO brought its products and values to Japanese users and communicated with them in Japanese. The enthusiastic response in the Twitter space also signaled that Japanese users strongly identify with M3 DAO's core values and are looking forward to the meta-universe revolution that M3 DAO will bring. It marks the first step of M3 DAO's successful opening of the Japanese market, and we look forward to M3 DAO's further development in Japan.",
            one: {
                title: "Event Highlights:",
                ul: [
                    "M3 DAO's first Japanese-language AMA, in which M3 DAO representatives shared M3 DAO's vision, mission, and values on Japanese KOL's Twitter space, highlighting the importance of community governance for M3 DAO.",
                    "Answered questions from Japanese audience about core products of M3 DAO: MarsVerse, MarsChain, and MarsProtocol."
                ],
            },
            two: {
                title: "What is M3 DAO's vision, mission, and goal?",
                ul: [
                    "Vision:To become the world's most influential investment and incubation DAO, driving innovation and sustainable development.",
                    "Goal: To incubate a Web3 unicorn project that ranks among the top ten globally by market value.",
                    "Mission: Through a decentralized governance model, gather global resources to support and incubate promising innovative projects, promoting technological advancement and social welfare."
                ],
            },
            three: {
                title: "What is the role of community governance in M3 DAO?",
                ul: [
                    "Community governance in M3 DAO allows members to participate in decision-making processes, propose changes, and vote on key issues. This is facilitated through MarsVerse, where global community governance mechanisms are implemented, enabling users to influence the development and policies of the ecosystem."
                ],
            },
        },
        item4: {
            title: 'M3 Online Joint Conference: U-Topia Chinese Community Session',
            topic:
                'This session introduced U-Topia, the M3 DAO co-branded partner, as well as the upcoming MarsVerse x U-Topia co-branded series of Avatar decks and related airdrop event releases!',
            body: "This session introduced U-Topia as the co-branded partner of M3 DAO, highlighting the upcoming MarsVerse x U-Topia series of co-branded Avatar decks. It also featured details on the related airdrop event releases, setting the stage for an exciting collaboration.",
            one: {
                title: "Event Highlights:",
                ul: [
                    "To celebrate the partnership between M3 DAO and U-topia, U-topia's CEO joined the live broadcast to share insights into the U-topia project and its future development.",
                    "The event also marked the official launch of the MarsVerse x U-topia Avatar Alliance, including a related airdrop event."
                ],
            },
            two: {
                title: "About M3 DAO:",
                ul: [
                    "M3 DAO is a decentralized autonomous organization focused on identifying and supporting high-potential blockchain startups. M3 DAO leverages the collective intelligence of its global community to make investment decisions, fostering a more democratic and inclusive approach to venture funding."
                ],
            },
            three: {
                title: "About U-topia:",
                ul: [
                    "U-topia is the 1st MediaFi Company in the World, it is the convergence of innovative global IP Licensing in GameFi, AI Music, and Video Entertainment backed by a catalog of 70k Real World Assets rewards, leveraging top Intellectual Property (IP) from Web2 such as content from sports matches by professional teams, video content like Bandai Namco, and Top 100 retailers like Nike along with prime NFT collections from Web3."
                ],
            },
        },
        item5: {
            title: 'MarsVerse x Utopia: M3 MarsVerse x U-topia Avatar selling countdown',
            topic:
                'Get insights on MarsVerse x U-topia Avatar co-branded Avatar and promotions. Know more about the U-topia ecosystem and U-coin tokenomics.',
            body: "Discover insights into the MarsVerse x U-topia co-branded Avatars and their exciting promotions. Learn more about the U-topia ecosystem and delve into the details of U-coin tokenomics for a deeper understanding of its strategic impact.",
            one: {
                title: "Event Highlights:",
                ul: [
                    "The U-topia project representative parachuted into the live broadcast room to share the MarsVerse x U-topia co-branded Avatar and promotions.",
                    "Answered users questions about the U-topia ecosystem and U-coin tokenomics."
                ],
            },
            two: {
                title: "About MarsVerse x U-topia Avatar Alliance:",
                ul: [
                    "MarsVerse & U-topia Avatar Alliance Cards, which offer luxurious benefits and token rewards to investors. The launch included an airdrop event from July 1 to July 20, providing additional rewards for minting specific Avatars. Due to the high value, exclusivity, and huge rewards associated with holding these cards, the demand within our community is immense. This campaign significantly enhances the value of U-topia's Ucoin token, with a total supply of 1 billion. "
                ],
            },
            three: {
                title: "About U-coin:",
                ul: [
                    "Token Symbol: U-coin",
                    "Total Supply: 1 Billion",
                    "Exchange Listing Date: Beginning of August",
                    "U-coin Allocation:",
                    "Strategic Financing：1.25%、Team/Advisor：17%、Seed Round：2.78%、Community Allocation：7.5%、Public Sale：4.75%、Ecosystem：35.4%、Private Sale：4.32%、Partner：8%、Staking Rewards：3%、CEX Liquidity：10%、LP Rewards：2%、DEX Liquidity：4%"
                ],
            },
        },
        item6: {
            title: 'M3 Mars Chinese Community Consensus Conference: M3 DAO bigwigs came as guests!',
            topic:
                'G.C Capital Founder and M3 DAO Investment Lead-GC and M3 DAO European community member Ference stopped by to share the recent investment trends in the crypto market and the key to community consensus building!',
            body: "G.C Capital Founder and M3 DAO Investment Lead, GC, along with M3 DAO European community member Ference, stopped by to share insights on recent investment trends in the crypto market and the essential strategies for building community consensus. Their discussion provided valuable perspectives on navigating the evolving landscape of cryptocurrency investments and fostering a strong, united community.",
            one: {
                title: "Event Highlights:",
                ul: [
                    "GC Capital founder and M3 DAO Investment lead- GC came on air to share his professional insights on the recent investment trends in the crypto market.",
                    "M3 DAO European community big shot member Ferenc , now shows how to quickly expand the community and build community consensus."
                ],
            },
            two: {
                title: "What is Web3 investment?",
                ul: [
                    "Web3 investment focuses on funding and developing decentralized technologies that form the backbone of the next generation of the internet. This includes blockchain projects, cryptocurrencies, decentralized finance (DeFi) platforms, non-fungible tokens (NFTs), and other technologies that promote user ownership, privacy, and control over digital assets and data. Web3 investment aims to transform how we interact online, shifting power from centralized entities to individual users and communities."
                ],
            },
            three: {
                title: "What is community consensus?",
                ul: [
                    "Community consensus is the process of reaching an agreement within a group or community, often used in decentralized and collaborative environments. It involves open communication, participation, and negotiation to ensure that decisions reflect the collective will and interests of the community members. In blockchain and Web3 projects, community consensus is crucial for validating transactions, implementing changes, and maintaining trust and transparency within the network."
                ],
            },
        },
        item7: {
            title: 'M3 Mars Chinese Community Consensus Conference: M3 DAO bigwigs came as guests!',
            topic:
                "M3 DAO Austria community chief Harry together with European and American artist CJ parachuted into the live broadcast room to share the experience of community expansion, community consensus building. How to realize crypto asset fission growth in 30 days, only want or not, no can or can't!",
            body: `M3 DAO Austria Community Chief Harry, along with European and American artist CJ, joined the live broadcast to share their experiences in community expansion and consensus building. They discussed strategies for achieving explosive growth in crypto assets within just 30 days, emphasizing the mindset of "want or not, rather than can or can't." Their insights provided valuable guidance for driving rapid and sustainable growth in the community.`,
            one: {
                title: "Event Highlights:",
                ul: [
                    "M3 DAO Austrian community chief Harry parachuted into the live stream to share his experience in community expansion and community consensus building. How to realize crypto asset fission growth in 30 days, only want or not, no can or can't!",
                    "CJ, a European-American entertainer, is currently very active and fully evangelizing at M3, while running a community that spans three continents and different countries. He dropped by the live stream to share his experience in asset growth and discuss the possibilities of M3."
                ],
            },
            two: {
                title: "What is community consensus?",
                ul: [
                    "Community consensus was the process of reaching an agreement within a group or community, often used in decentralized and collaborative environments. It involved open communication, participation, and negotiation to ensure that decisions reflected the collective will and interests of the community members. In blockchain and Web3 projects, community consensus was crucial for validating transactions, implementing changes, and maintaining trust and transparency within the network."
                ],
            },
            three: {
                title: "About M3 DAO:",
                ul: [
                    "M3 DAO combines the metaverse, Layer 2 public chains, and digital asset management to create a community-governed, diversified, innovative, and open digital ecosystem, connecting Web3 and the real world. Its three main products are MarsVerse, a metaverse ecosystem focused on interstellar colonization; MarsChain, a Layer 2 blockchain solution providing secure and efficient infrastructure; and MarsProtocol, a core financial protocol covering investment, DeFi, staking, asset management, and venture capital. M3 DAO is building a diverse and interactive Web3 ecosystem, inviting all curious and creative individuals to join in shaping the future."
                ],
            },
        },
        item8: {
            title: 'M3 Mars Global Community Consensus Conference: M3 DAO leaders are coming to visit!',
            topic:
                'L.A. and Emmanuel, two core members of M3 DAO, will discuss the MetaMars Metaverse Ecology Plan and the upcoming seed round token subscription recruitment in August!',
            body: "L.A. and Emmanuel, two core members of M3 DAO, discussed the MetaMars Metaverse Ecology Plan and the upcoming seed round token subscription recruitment happening in August. Their insights provided valuable information on the strategic vision and opportunities within the M3 DAO ecosystem, setting the stage for future growth and innovation.",
            one: {
                title: "Event Highlights:",
                ul: [
                    "L.A. and Emmanuel, two core members of M3 DAO, parachuted into the live stream and discussed the MetaMars Metaverse Ecology Plan.",
                    "Answered users' questions about the upcoming seed round token subscription recruitment in August."
                ],
            },
            two: {
                title: "What is MetaMars Metaverse?",
                ul: [
                    "MetaMars is a Web3 metaverse platform with Mars as its theme. By integrating virtual reality, crypto economics, and social networks, MetaMars is pioneering a new way of digital interaction and value creation. This innovative digital world combines Mars exploration with blockchain technology, providing users with a unique immersive experience. ",
                    "In MetaMars, participants can explore crafted Martian landscapes, establish their own bases, and earn rewards, while interstellar travelers from around the world collaborate to explore the mysteries of the universe."
                ],
            },
            three: {
                title: "About MCD Token:",
                ul: [
                    "The MCD token is the native utility token of the M3 DAO ecosystem. It is used for governance, staking, and accessing various services within MarsVerse, MarsChain, and MarsProtocol."
                ],
            },
        }
    },
    footer: {
        one: {
            title: "Ecosystem",
            item1: "MarsVerse",
            item2: "MarsChain",
            item3: "MarsProtocol",
            item4: "Avatar",
            item5: "MarsLab",
            item6: "Blockchain Explorer",

        },
        two: {
            title: "Support",
            item1: "About",
            item2: "Media Kit",
            item3: "Contact Us",

        }
    }
}