module.exports = {
    tabList: {
        list1: '홈',
        list2: '제품',
        list3: '사례',
        list4: '뉴스',
        list5: '연락처',
        list6: '팀',
        list7: '펀딩 프로그램',
        list8: '블로그',
    },
    home: {
        slogan: "Web3를 여러분의 손끝에서 만나보세요.",
        number1: '활성 사용자',
        number2: '글로벌 노드',
        number3: '온·오프라인',
        number4: '협력생태',
        box_title: 'M3 DAO와 함께 Web3의 잠재력을 발굴하다',
        box_content: "M3 DAO는 메타버스, 레이어 2 퍼블릭 체인, 디지털 자산 관리를 통합하는 탈중앙화 자율 조직입니다. MarsVerse, MarsChain, MarsProtocol(MarsLab)의 세 가지 주요 부문을 중심으로 M3 DAO는 새로운 다중 상호작용 Web3 생태계를 구축하고 있습니다. 커뮤니티 자율성, 다양성, 혁신, 개방성의 가치를 바탕으로 M3 DAO는 전 세계 사용자들과 협력하여 Web3와 현실 세계의 깊은 연결을 촉진하고, 미래의 Web3 환경을 창조할 것입니다.",
        introduce: 'U-topia는 세계 최초의 MediaFi 회사로, 수많은 IP 자원을 보유하고 있으며 블록체인 기술을 사용하여 Web2와 Web3를 결합하여 최고 수준의 MediaFi 플랫폼을 만듭니다.',
        helloArray: [
            '블록체인 기술',
            'DeFi',
            'DApps',
            'NFTs',
            'DAOs',
            '스마트 계약',
            '인프라 및 도구',
            '프라이버시 및 보안',
            '크로스체인 솔루션',
            '메타버스',
            '탈중앙화 저장',
            '탈중앙화 신원',
            '탈중앙화 컴퓨팅',
            '실물 자산(Real World Assets)',
            'SocialFi',
            'MediaFi',
            '탈중앙화 예측 시장',
            '탈중앙화 거버넌스'
        ],
        blog: "블로그",
        dq_title: '글로벌 커뮤니티',
        bottom_title: 'Web3의 무한한 가능성을 탐험해보세요',
        bottom_font1: 'Web3는 세상을 재편하고 있습니다.우리는 분산형 기술을 통해 더욱 개방적이고 공정하며 자율적인 디지털 세상을 구축할 것이라고 믿습니다.',
        bottom_font2: 'Web3와 함께 걷기, 혁신으로 걷기',
        button_button: "문의하기"
    },
    Product: {
        mv1: "Avatar",
        mv2: "메타버스",
        mv3: "우주 식민지",
        mv4: "RWA 플랫폼",
        mc1: "Layer2",
        mc2: "DID",
        mc3: "하부 구조",
        mc4: "생태계 지원",
        mp1: "MarsLab",
        mp2: "Web3 투자 및 인큐베이션",
        mp3: "디지털 결제 솔루션",
        mp4: "다오재단",
    },
    Portfolio: {
        slogan1: "우리는 혁신적인 프로젝트나 회사와 협력하기를 기대합니다.",
        slogan2: "버튼을 클릭하시면 정보를 제출하실 수 있습니다.",
        submit: "프로젝트 제출",
        title: "사례",
        list1: {
            title: '데이터 프라이버시 및 보안',
            font: 'Layer 2 및 분산 신원 (DID) 기술을 활용하여 사용자 데이터 자주성과 개인정보 보호를 보장합니다.',
        },
        list2: {
            title: "지역사회의 자율성",
            font: "글로벌 사용자와 커뮤니티 구성원이 거버넌스에 참여하고 민주적인 의사결정을 달성합니다."
        },
        list3: {
            title: "효율적인 블록체인 인프라",
            font: "고성능 Layer 2 솔루션은 대규모 응용 프로그램과 고주파 거래를 보호합니다."
        },
        list4: {
            title: "현실 자산 디지털화",
            font: "현실 자산의 체인 상 관리를 지원하여 투명하고 효율적인 자산 관리를 실현합니다. 400여 개의 기업과 협력 관계를 맺고 있습니다."
        },
        list5: {
            title: "디지털 자산 관리",
            font: "전 세계 사용자와 우수 프로젝트를 위해 투자 연구, 투자, 인큐베이션 등의 포괄적인 디지털 자산 관리 서비스를 제공하여 다양한 요구를 충족합니다."
        },
        list6: {
            title: "프로젝트 인큐베이션",
            font: "MarsLab 인큐베이션 플랫폼을 통해 신생 Web3 프로젝트에 자금, 브랜드, 운영 및 기술 지원을 제공합니다."
        }
    },
    news: {
        title: '뉴스&컨텐츠'
    },
    support: {
        title: '지원하다',
        font1: '만일 당신이 우리에게 더 좋은 건의가 있다면, 우리에게 제기하십시오, 우리가 제때에 처리할 것입니다.',
        input1: '제목을 입력하십시오.',
        input2: '질문을 입력하십시오',
        input3: '귀하의 전자 우편 주소를 남기시면, 저희 직원이 회신할 것입니다',
        input4: '커밋에 성공했습니다.',
        button2: '돌아가',
        button1: '제출',
    },
    team: {
        font1: 'M3 DAO 팀',
        font2: 'M3 DAO 팀은 세계 최고의 Web3 업계 리더로 구성되어 있으며 전 세계 사용자에게 고유한 기술과 혁신 경험을 제공하고 Web3 개발을 포괄적으로 촉진하는 데 최선을 다하고 있습니다. 우리는 M3 DAO의 모든 구성원과 협력하여 분산 기술 개발을 재정의하고 비교할 수 없는 블록체인 변화를 함께 경험할 수 있기를 기대합니다.',
        item1: {
            name: 'EMMANUEL QUEZADA',
            title1: `M3 DAO 회원 `,
            title2: `IP&브랜딩 리드`,
            font: 'U-topia 생태계의 창립자인 그는 7년 동안 블록체인 기술 분야에서 일해 왔으며 KYC, CFT 및 AML 문제에 대한 인증된 블록체인 전문가이자 블록체인 컨설턴트입니다.'
        },
        item2: {
            name: 'L.A',
            title1: 'M3 DAO의 초기 멤버',
            title2: '생태계 리드',
            font: '초기 BTC 전도사、비트코인 광산 소유자、400개 이상의 오프라인 사업을 운영하는 말레이시아 기업가、여러 상장회사의 사장、글로벌 커뮤니티 리더.'
        },
        item3: {
            name: 'MICHAEL GORD',
            title1: `M3 DAO 회원 `,
            title2: `투자 리드`,
            font: '연쇄 창업가(3개 출구 + 3개 인수), 글로벌 시민 파괴적 기술 투자자/고문(80개 이상의 포트폴리오 회사/4개 종자 -> 유니콘), 연설자 및 금융가(7천만 달러 직접 자금 조달 2억 1천만 달러 이상, 구조화 금융 2억 1천만 달러 이상) , 디지털 자산 및 블록체인 기술의 대규모 채택에 중점을 두고 있습니다.'
        },
        item4: {
            name: 'DARREN SMITH',
            title1: `M3 DAO 회원 `,
            title2: `IT 리드`,
            font: '비디오 게임 업계 베테랑. Nintendo에서 20년 동안 Nintendo 64, Wii, DSi, 3DS와 같은 콘솔과 앱, 게임 작업을 했습니다.2018년 Epik Prime에 합류하여 NFT를 게임에 도입하는 작업을 진행하고 있습니다.'
        },
        item5: {
            name: 'GC',
            title1: `M3 DAO의 초기 멤버 `,
            title2: `골든 캐럿 캐피털(Golden Carrot Capital) 창립자`,
            font: '블록체인 분야에서 8년의 경험을 보유한 암호화폐 기업가로서 그는 Binance 및 MEXC Global과 같은 주요 암호화폐 거래소에서 고위직을 통해 광범위한 금융 전문 지식을 축적했습니다.'
        },
        item6: {
            name: 'OWEN MA',
            title1: `M3 DAO 회원 `,
            title2: `IP 리드`,
            font: 'U-topia의 생태계 운영 및 재무 담당 부사장입니다. 지적 재산권 라이센스, 자산 금융, 인센티브 프로그램, 음악 축제 및 부동산 분야에서 광범위한 경험을 보유하고 있습니다. 그는 게임과 브랜딩을 통합하여 Web3에서 최대 규모의 기업 B2B 생태계를 성공적으로 구축했습니다.'
        },
        item7: {
            name: 'ALEXIS SIRKIA',
            title1: `M3 DAO 회원 `,
            title2: `재무 리드`,
            font: '암호화폐 업계에서 검증된 실적을 보유한 노련한 기업가인 그는 XRP의 초기 투자자에서 선도적인 암호화폐 거래 및 시장 조성 회사로 발전하여 끊임없이 변화하는 산업 환경을 성공적으로 탐색했으며, Yellow Group의 선도적인 블록체인을 인수했습니다. 인프라 회사 OpenWare.'
        },
        item8: {

            name: '페렌츠',
            title1: `M3 DAO 핵심 멤버`,
            title2: `커뮤니티 리더`,
            font: "20년의 경험을 가진 마케팅 전문가. 비즈니스 개발 전략에 대한 열정을 가지고 있으며, 전통 경제학, Web3, 정보 기술에 대한 풍부한 배경 지식을 보유. 글로벌 경험으로 구성된 커뮤니티를 구축했다."
        },
        item9: {
            name: '제임스',
            title1: `M3 DAO 핵심 멤버`,
            title2: "U-topia BD",
            font: "애들레이드 대학교(University of Adelaide) 졸업, 전략 경영 전공. 암호화폐 업계에 깊이 뿌리내린 풍부한 경험 보유. Bybit의 전 임원으로 전략 개발, 커뮤니티 확장 및 생태계 구축 분야에서 상당한 성과를 이룩함."
        }
    },
    grant: {
        one: 'MarsLab 보조금 프로그램',
        two: '즉시 적용',
        title1: {
            bolder: 'MarsLab 자금 조달',
            content: "이 프로그램은 미래 지향적인 프로젝트를 육성하고 지원함으로써 블록체인과 기술의 선두에 서서 개발자와 팀이 인프라 구축에 적극적으로 참여하도록 장려하는 것을 목표로 합니다.우리의 목표는 단지 ​​커뮤니티에 실용적이고 혁신적인 솔루션을 제공하는 것이 아니라 업계에 상당한 영향을 미치고 블록체인 기술의 발전을 이끄는 것입니다."
        },
        title2: {
            font1: "이 프로그램은 12단계로 나누어지며, 각 단계마다 상금이 $500,000입니다.", font2: "1단계"
        },
        Goal: {
            title: "표적",
            content1: "블록체인, 분산 애플리케이션(dApp), Web3 및 관련 기술의 혁신가이자 리더가 되십시오.",
            content2: "개념화부터 배포까지 모든 단계에서 프로젝트를 지원하여 상당한 영향을 미치도록 합니다.",
            content3: "개발자, 창작자, 혁신가로 구성된 풍부하고 활발한 커뮤니티를 육성하여 협업과 지식 교환을 강화하세요."
        },
        Grant: {
            title: "보조금 카테고리",
            content1: "기술 혁신",
            content2: "도구",
            content3: "커뮤니티 빌딩"
        },
        Qualification: {
            title: "자격",
            content1: "블록체인을 기반으로 구축된 모든 프로젝트에 개방되어 있으며 다중 체인 상호 운용성과 혁신을 강조합니다.",
            content2: "참가자는 반드시 독창적인 작품을 제출해야 합니다.표절은 엄격히 금지됩니다.",
            content3: "제출물에는 자세한 프로젝트 계획, 프리젠테이션(가능한 경우) 및 팀 배경이 포함되어야 합니다.",
            content4: "프로젝트는 명확한 유용성, 혁신 및 잠재적 영향을 입증해야 합니다."
        },
        Process: {
            title: "프로세스",
            content1: "제출하다",
            content2: "검토",
            content3: "투표",
            content4: "지원하다",
        },
        Funding: {
            title: "자금 구조",
            content1: "자금은 프로젝트 마일스톤 및 결과물에 따라 단계적으로 할당됩니다.",
            content2: "총 지원금 풀은 각 주기가 시작될 때 발표되며, 개별 보조금 금액은 프로젝트 필요와 잠재적 영향에 따라 달라집니다.",
            content3: "수상자에게는 재정적 지원 외에도 프로젝트 성공을 위한 기술 지원 및 마케팅 프로모션이 제공됩니다.",
        },
        Community: {
            title: " 지역사회 참여 및 지원",
            content1: "M3 커뮤니티",
            content2: "세미나 및 워크숍",
            content3: "전시하다",
            content4: "또한, 후속 개발이 원활하게 이루어질 수 있도록 당선 프로젝트의 진행 상황을 정기적으로 후속 조치하겠습니다.",
        },
    },
    grantForm: {
        title1: "MarsLab 보조금 신청",
        title2: "신청하시려면 이 양식을 작성해 주세요. 우리는 귀하가 무엇을 구축할 것인지, 우리가 귀하의 여정을 어떻게 지원할 수 있는지 알아보기를 기대합니다.",
        input1: {
            place1: "프로젝트 이름",
            place2: "프로젝트 트위터",
            place3: "프로젝트 웹사이트",
            place4: "프로젝트 개요",
        },
        input2: {
            place1: "당신의 프로젝트를 50자 이내로 설명해주세요.어떤 문제가 해결되나요?",
            place2: "어떻게 작동하나요?",

        },
        input3: {
            label: "귀하의 프로젝트 카테고리는 무엇입니까?",
        },
        input4: {
            label: "프로젝트 단계를 알려주세요",
        },
        input5: {
            label: "관련된 팀에 대해 알려주세요.인원은 몇 명인가요?",
            label2: "핵심 팀 구성원의 이름, 역할 및 배경."
        },
        input6: {
            label: "MarsLab에서 어떤 지원이 필요한지 알려주세요.",
        },
        input7: {
            label: "프로젝트 담당자",
            place1: "이름",
            place2: "이메일",
            place3: "전보",
            place4: "지갑 주소",
        },
        input8: {
            label: "추천인 정보(있는 경우)",
        },
        back: "뒤쪽에",
        next: "다음",
    },
    grantFinish: {
        font1: "원서 제출",
        font2: "검토를 위해 귀하의 제출물이 접수되었습니다. 시간을 내주셔서 정말 감사합니다!",
        button1: "그랜트로 돌아가기",
        button2: "제출 새로운 신청서",
    },
    blog: {
        AMANews: "AMA 컨설팅",
        item1: {
            title: "MarsVerse x U-topia: MarsVerse 생태계와 Avatar Alliance에 대한 심층 분석",
            topic: "이번 행사에서는 마스버스 생태계 개요, 아바타 얼라이언스 공식 출범, 마스버스 및 유토피아 아바타 소개, 유토피아 프로젝트의 향후 발전에 대한 통찰, 유코인 개발 계획에 대한 자세한 논의가 진행됐다. 그리고 토큰경제학.",
            body: "이번 행사에서는 Marsverse 생태계에 대해 깊이 있는 소개가 이루어졌으며, Avatar Alliance 협력 모델의 공식 출범을 축하했습니다. 행사 중 Marsverse x U-topia 협력 카드를 소개하며 참석자들에게 U-topia 프로젝트의 미래 발전에 대한 전망을 제공했습니다. 마지막으로, U-coin의 개발 계획과 토큰 경제학을 자세히 논의하며 프로젝트의 전략적 비전과 재정적 프레임워크를 설명했습니다.",
            one: {
                title: '이벤트 하이라이트:',
                ul: [
                    "M3 DAO와 U-topia의 협력을 기념하기 위해, U-topia의 CEO가 MarsVerse의 Twitter Space에 초대되어 U-topia 프로젝트 및 향후 발전에 대한 통찰을 공유했습니다.",
                    "행사 중 Avatar Alliance 협력 모델의 출범이 발표되었으며, MarsVerse x U-topia Avatar Alliance가 소개되었습니다.",
                    "마지막으로, U-topia의 가치에 대한 질문을 해결하기 위해, U-topia의 CEO는 U-coin의 개발 계획 및 토큰 경제에 대해 자세히 설명했습니다."
                ],
            },
            two: {
                title: "Avatar Alliance에 대하여:",
                ul: [
                    "MarsVerse의 전략 로드맵의 핵심에는 미래 지향적인 잠재적 파트너와 협력하는 획기적인 이니셔티브인 Avatar Alliance 계획이 있습니다. 이 계획은 공동 브랜드의 Avatar 연합을 통해 MarsVerse 파트너의 비즈니스 성장을 촉진하고, 브랜드 영향력을 강화하며, 탈중앙화 네트워크와의 상호작용을 촉진하는 것을 목표로 합니다. ",
                    "Avatar Alliance 계획은 커뮤니티 간의 협력을 새로운 시대로 이끌며, 전략적 공동 브랜드, 다채널 마케팅 및 글로벌 이벤트를 통해 MarsVerse와 그 파트너들의 공동 성장과 혁신을 추진합니다."
                ],
            },
            three: {
                title: 'MarsVerse x U-topia Avatar Alliance에 대하여:',
                ul: [
                    "MarsVerse x U-topia 공동 브랜드 카드는 투자자들에게 풍부한 혜택과 토큰 보상을 제공합니다. 이 공동 브랜드 카드의 출시에는 7월 1일부터 7월 20일까지 진행되는 에어드랍 이벤트가 포함되어 있으며, 특정 Avatar를 소유한 사용자에게는 추가 보상이 주어집니다. 이 카드의 높은 가치, 희소성 및 막대한 보상으로 인해 커뮤니티 내에서 엄청난 수요가 발생했습니다. 이번 이벤트는 U-topia의 Ucoin 토큰의 가치를 크게 상승시켰으며, 총 공급량은 10억 개입니다."
                ],
            },
        },
        item2: {
            title: "MarsVerse x U-topia: 메타버스의 미래: 다음 혁명은 무엇입니까?",
            topic: "토론에서는 다양한 메타버스 플랫폼 전반에 걸쳐 원활한 경험을 창출하는 데 있어 도전 과제와 기회를 탐구하고, NFT를 넘어선 가상 상품 및 서비스 시장의 진화를 조사했으며, 메타버스가 미래의 사회적 상호 작용 및 관계를 어떻게 형성할 것인지에 대해 탐구했습니다.",
            body: "이 토론에서는 다양한 Metaverse 플랫폼에서 원활한 경험을 창출하는 과제와 기회를 탐구합니다. NFT를 넘어 가상 상품 및 서비스 시장의 진화를 조사하고 메타버스가 미래의 사회적 상호 작용 및 관계를 어떻게 형성할지 탐구하여 빠르게 진화하는 디지털 환경의 잠재력과 영향을 완전히 보여줍니다.",
            one: {
                title: "이벤트 하이라이트:",
                ul: [
                    "MarsVerse 경영진은 U-topia 프로젝트 대표자들과 손을 잡고 MarsVerse Twitter Space를 통해 Metaverse의 미래뿐만 아니라 Metaverse의 도전과 기회를 공유했습니다.",
                    "또한 메타버스의 향후 진화에 관한 사용자 질문에 대한 답변도 제공합니다."
                ],
            },
            two: {
                title: "메타버스 정보:",
                ul: [
                    "메타버스(Metaverse)는 가상의 강화된 물리적 현실과 물리적으로 영속적인 가상 공간이 융합되어 형성된 집단적 가상 공유 공간이다. 여기에는 가상 세계, 증강 현실, 인터넷이 모두 포함됩니다. 메타버스에서 사용자는 컴퓨터 생성 환경 및 다른 사용자와 실시간으로 상호 작용할 수 있으며 현실 세계와 원활하게 조화를 이루는 몰입형 디지털 환경을 경험할 수 있습니다."
                ],
            },
            three: {
                title: "대체 불가능한 토큰(NFT) 정보:",
                ul: [
                    "대체 불가능한 토큰(NFT)은 블록체인 기술을 사용하여 예술, 음악, 비디오 또는 가상 부동산과 같은 특정 항목이나 콘텐츠의 소유권을 나타내는 고유한 디지털 자산입니다. 비트코인이나 이더리움과 같은 암호화폐와 달리 NFT는 분할할 수 없고 일대일로 교환할 수 없으므로 각 토큰이 독특하고 가치가 있습니다. NFT는 디지털 창작물의 소유권을 인증하고 증명하는 방법을 제공하여 새로운 형태의 디지털 거래 및 상호 작용을 가능하게 합니다."
                ],
            },
        },
        item3: {
            title: "M3 DAO 살펴보기: 비전과 혁신",
            topic: "M3 DAO의 제품과 가치에 대한 자세한 개요를 제공하고, M3 DAO가 커뮤니티 거버넌스를 달성하는 방법을 설명하고, Web3 산업의 미래에 대한 기여에 대해 논의했습니다.",
            body: "M3 DAO가 자사의 제품과 가치를 일본 사용자들에게 알리고 소통한 것은 이번이 처음이다. 트위터 공간에서의 열광적인 반응은 일본 사용자들이 M3 DAO의 핵심 가치에 강력하게 동의하고 M3 DAO가 가져올 메타버스 혁명을 기대하고 있음을 보여줍니다. 이는 M3 DAO가 일본 시장을 성공적으로 개척하기 위한 첫 번째 단계이며, 일본에서 M3 DAO의 지속적인 발전을 기대합니다.",
            one: {
                title: "이벤트 하이라이트:",
                ul: [
                    "M3 DAO의 첫 번째 일본 AMA인 M3 DAO 대표자들은 일본 KOL의 트위터 공간에 상륙하며 M3 DAO의 비전, 사명, 가치를 공유하며 M3 DAO에 대한 커뮤니티 거버넌스의 중요성을 강조했습니다.",
                    "M3 DAO 핵심 제품 MarsVerse, MarsChain 및 MarsProtocol에 대한 일본 시청자의 질문에 답변했습니다."
                ],
            },
            two: {
                title: "M3 DAO의 비전, 목표 및 임무에 대해?",
                ul: [
                    "비전: 세계에서 가장 영향력 있는 투자 및 인큐베이션 DAO가 되어 혁신과 지속 가능한 개발을 촉진하는 것입니다.",
                    "목표: 시장 가치 기준으로 세계 상위 10개 Web3 유니콘 프로젝트를 육성합니다.",
                    "사명: 분산형 거버넌스 모델을 통해 글로벌 자원을 수집하고, 잠재력이 있는 혁신적인 프로젝트를 지원 및 육성하며, 기술 진보와 사회 복지를 촉진합니다."
                ],
            },
            three: {
                title: "M3 DAO에 커뮤니티 거버넌스는 무엇을 의미하나요?",
                ul: [
                    "M3 DAO의 커뮤니티 거버넌스는 회원들이 의사 결정 과정에 참여하고, 수정 사항을 제안하고, 주요 문제에 대해 투표할 수 있도록 해줍니다. 위 내용은 모두 MarsVerse를 통해 구현되었습니다. 동시에 MarsVerse는 사용자가 생태계의 개발 및 정책에 영향을 미칠 수 있도록 글로벌 커뮤니티 거버넌스 메커니즘을 구현합니다."
                ],
            },
        },
        item4: {
            title: "M3 온라인 방송 컨퍼런스: 유토피아 중국인 커뮤니티 특별세션",
            topic: "이번 세션에서는 M3 DAO 공동 브랜드 파트너인 U-Topia와 곧 출시될 MarsVerse x U-Topia 공동 브랜드 아바타 덱 시리즈 및 관련 에어드랍 이벤트 릴리스를 소개했습니다!",
            body: "이번 회의에서는 U-Topia가 M3 DAO의 협력 파트너로서 소개되었으며, 곧 출시될 MarsVerse x U-topia Avatar Alliance에 대해 다뤘습니다. 또한, 이번 협력의 중요한 부분인 관련 에어드롭 이벤트에 대한 세부 사항도 강조되었습니다.",
            one: {
                title: "이벤트 하이라이트:",
                ul: [
                    "M3 DAO와 U-topia의 협업을 축하하기 위해 U-topia CEO가 라이브 방송에 참여하여 U-topia 프로젝트와 향후 개발에 대한 통찰력을 공유했습니다.",
                    "이번 행사는 MarsVerse x U-topia Avatar Alliance 및 관련 에어드롭 이벤트의 공식 시작을 알립니다."
                ],
            },
            two: {
                title: "M3 DAO 소개:",
                ul: [
                    "M3 DAO는 유망한 블록체인 스타트업을 발굴하고 지원하는 데 중점을 둔 탈중앙화 자율 조직입니다. M3 DAO는 글로벌 커뮤니티의 집단 지성을 활용하여 투자 결정을 내리며, 보다 민주적이고 포용적인 방식으로 벤처 자본을 제공합니다."
                ],
            },
            three: {
                title: "U-topia 소개:",
                ul: [
                    "U-topia는 세계 최초의 MediaFi 회사로, GameFi, AI 음악, 비디오 엔터테인먼트 분야에서 글로벌 혁신 IP 라이센싱을 통합한 플랫폼입니다. U-topia는 7만 개 이상의 현실 세계 자산 보상을 포함한 카탈로그를 기반으로 하며, 웹2의 유명 IP(예: 프로 스포츠팀 경기 콘텐츠, 반다이 남코의 비디오 콘텐츠, 나이키 등 글로벌 상위 100대 소매업체의 콘텐츠)와 웹3의 유명 NFT 컬렉션을 통합합니다."
                ],
            },
        },
        item5: {
            title: "MarsVerse x Utopia 생태계 공유회: M3 MarsVerse 생태계 발전 계획 전면 추진, Avatar 공동 브랜드 시리즈 공식 출시",
            topic: "MarsVerse x U-topia Avatar 공동 브랜드 아바타 및 프로모션에 대한 통찰력을 얻으세요. U-topia 생태계와 U-coin 토큰경제학에 대해 자세히 알아보세요.",
            body: "커뮤니티 사용자들은 U-topia 대표와 함께 MarsVerse x U-topia 공동 브랜드 카드 및 흥미로운 프로모션 활동에 대해 심도 있게 탐구했습니다. U-topia 생태계를 탐험하고 U-coin 토큰 경제학을 깊이 연구하며 그 전략적 영향을 전반적으로 이해했습니다.",
            one: {
                title: "이벤트 하이라이트:",
                ul: [
                    "U-topia 프로젝트 대표가 라이브 방송에 깜짝 등장하여 MarsVerse x U-topia Avatar Alliance 및 관련 프로모션 활동을 공유했습니다.",
                    "또한, 사용자들이 궁금해하는 U-topia 생태계와 U-coin 토큰 경제학에 대한 질문에 답변했습니다."
                ],
            },
            two: {
                title: "MarsVerse x U-topia 공동 브랜드 카드에 대해:",
                ul: [
                    "MarsVerse x U-topia 공동 브랜드 카드는 투자자들에게 호화로운 혜택과 토큰 보상을 제공합니다. 이번 출시는 7월 1일부터 7월 20일까지의 에어드롭 활동을 포함하며, 특정 아바타를 발행하는 사용자에게 추가 보상이 주어집니다. 이러한 카드들이 지닌 높은 가치, 희소성, 그리고 풍부한 보상으로 인해 우리 커뮤니티에서의 수요가 매우 높습니다. 이번 활동은 U-topia의 U-coin 토큰 가치를 크게 상승시켰으며, 해당 토큰의 총 공급량은 10억 개입니다."
                ],
            },
            three: {
                title: "U-coin에 대해:",
                ul: [
                    "토큰 기호: U-coin",
                    "총 공급량: 10억 개",
                    "거래소 상장 날짜: 8월 초",
                    "U-coin 토큰 분배:",
                    "전략적 자금 조달: 1.25%、팀/컨설턴트: 17%、시드 라운드: 2.78%、커뮤니티 할당: 7.5%、공개판매: 4.75%、생태계: 35.4%、프라이빗 세일: 4.32%、파트너: 8%、스테이킹 보상: 3%、중앙화 거래소 유동성: 10%、유동성 공급자 보상: 2%、분산형 거래소 유동성: 4%"
                ],
            },
        },
        item6: {
            title: "M3 화성 중국 커뮤니티 합의 회의: M3 DAO 거물들이 손님입니다!",
            topic: "G.C Capital 창립자이자 M3 DAO 투자 책임자 - GC 및 M3 DAO 유럽 커뮤니티 회원인 Ference가 암호화폐 시장의 최근 투자 동향과 커뮤니티 합의 구축의 핵심을 공유하기 위해 들렀습니다!",
            body: "GC Capital의 창립자이자 M3 DAO 투자 책임자인 GC와 M3 DAO 유럽 커뮤니티 회원인 Ferenc가 참석하여 최근 암호화폐 시장 투자 동향에 대한 통찰력을 공유하고 커뮤니티 합의 구축을 위한 주요 전략에 대해 논의했습니다. 그들의 토론은 끊임없이 변화하는 암호화폐 투자 세계를 탐색하고 강력하고 단결된 커뮤니티를 구축하는 방법에 대한 독특한 관점과 귀중한 통찰력을 제공했습니다.",
            one: {
                title: "이벤트 하이라이트:",
                ul: [
                    "GC Capital의 창립자이자 M3 DAO 투자 책임자인 GC가 라이브 방송실에 등장하여 암호화폐 시장의 최근 투자 동향에 대한 전문적인 통찰력을 공유했습니다.",
                    "M3 DAO 유럽 커뮤니티의 중요한 구성원인 Ferenc는 커뮤니티를 빠르게 확장하고 커뮤니티 합의를 구축하는 방법을 시연했습니다."
                ],
            },
            two: {
                title: "Web3 투자란 무엇입니까?",
                ul: [
                    "Web3 Investments는 차세대 인터넷의 기반을 형성하는 분산형 기술에 자금을 지원하고 개발하는 데 중점을 두고 있습니다. 여기에는 블록체인 프로젝트, 암호화폐, 탈중앙화 금융(DeFi) 플랫폼, 대체 불가능한 토큰(NFT) 및 디지털 자산과 데이터에 대한 사용자 소유권, 개인 정보 보호 및 통제를 촉진하는 기타 기술이 포함됩니다. Web3 Investments는 온라인에서 상호 작용하는 방식을 변화시켜 권력을 중앙화된 주체에서 개별 사용자 및 커뮤니티의 손으로 옮기는 것을 목표로 합니다."
                ],
            },
            three: {
                title: "지역사회 합의란 무엇인가요?",
                ul: [
                    "커뮤니티 합의는 그룹이나 커뮤니티 내에서 합의에 도달하는 프로세스를 의미하며, 분산 및 협업 환경에서 자주 사용됩니다. 이 과정에는 공개적인 의사소통, 참여 및 협의가 포함되어 결정이 지역사회 구성원의 집단적 소망과 이익을 반영하도록 합니다. 블록체인 및 Web3 프로젝트에서 커뮤니티 합의는 트랜잭션을 검증하고, 변경 사항을 구현하고, 네트워크 내에서 신뢰와 투명성을 유지하는 데 매우 중요합니다."
                ],
            },
        },
        item7: {
            title: "M3 화성 중국 커뮤니티 합의 회의: M3 DAO 거물들이 손님입니다!",
            topic: "M3 DAO 오스트리아 커뮤니티 대표 Harry는 유럽 및 미국 아티스트 CJ와 함께 라이브 방송실로 낙하산을 타고 커뮤니티 확장, 커뮤니티 합의 구축 경험을 공유했습니다. 원하든 원하지 않든, 할 수 없거나 할 수 없는 30일 만에 암호화폐 자산 분열 성장을 실현하는 방법입니다. 캔트!",
            body: `M3 DAO 오스트리아 커뮤니티 리더 해리(Harry)와 유럽계 미국인 아티스트 CJ가 라이브 방송에 참여해 커뮤니티 확장과 합의 구축에 대한 경험을 공유했습니다. 그들은 단 30일 만에 암호화폐 자산의 폭발적인 성장을 달성하기 위한 전략을 논의하면서 "할 수 있는지 물어보세요"라는 사고방식을 강조했습니다. 그들의 통찰력은 지역 사회에서 빠르고 지속 가능한 성장을 추진하는 데 귀중한 지침을 제공합니다.`,
            one: {
                title: "이벤트 하이라이트:",
                ul: [
                    `- M3 DAO 오스트리아 커뮤니티 리더 해리(Harry)가 생방송방에 등장해 커뮤니티 확장 및 합의 구축 경험을 공유했다. 그는 30일 이내에 암호화폐 자산의 급속한 성장을 달성하는 방법을 설명하면서 "할 수 있는지 묻지 말고 원하는지 물어보세요"라는 사고 방식을 강조했습니다.`,
                    "CJ는 현재 M3에서 설교 활동과 3개 대륙, 다양한 국가를 넘나들며 커뮤니티를 운영하는 유럽과 미국의 예술가입니다. 또한 그는 라이브 방송에서 자신의 자산 성장 경험을 공유하며 M3의 다양한 가능성에 대해 논의했다."
                ],
            },
            two: {
                title: "지역사회 합의란 무엇인가요?",
                ul: [
                    "커뮤니티 합의는 그룹이나 커뮤니티 내에서 합의에 도달하는 프로세스를 의미하며, 분산 및 협업 환경에서 자주 사용됩니다. 이 과정에는 공개적인 의사소통, 참여 및 협의가 포함되어 결정이 지역사회 구성원의 집단적 소망과 이익을 반영하도록 합니다. 블록체인 및 Web3 프로젝트에서 커뮤니티 합의는 트랜잭션을 검증하고, 변경 사항을 구현하고, 네트워크 내에서 신뢰와 투명성을 유지하는 데 매우 중요합니다."
                ],
            },
            three: {
                title: "M3 DAO 소개:",
                ul: [
                    "M3 DAO는 Metaverse, Layer 2 퍼블릭 체인 및 디지털 자산 관리를 결합하여 Web3와 현실 세계를 연결하는 커뮤니티 관리형, 다양하고 혁신적이며 개방형 디지털 생태계를 만듭니다. 세 가지 주요 제품은 다음과 같습니다: 성간 식민지화에 초점을 맞춘 메타버스 생태계인 MarsChain, 안전하고 효율적인 인프라를 제공하는 레이어 2 블록체인 솔루션, 그리고 투자, DeFi, 스테이킹, 자산 관리 및 핵심 금융 프로토콜을 다루는 플랫폼인 MarsProtocol. 벤처캐피탈. M3 DAO는 다양하고 상호작용적인 Web3 생태계를 구축하여 호기심 많고 창의적인 모든 사람들이 미래를 형성하는 데 참여할 수 있도록 초대합니다."
                ],
            },
        },
        item8: {
            title: "M3 화성 글로벌 커뮤니티 합의 회의: M3 DAO 리더 방문!",
            topic: "M3 DAO의 두 핵심 멤버인 L.A.와 Emmanuel은 MetaMars Metaverse 생태 계획과 8월에 예정된 시드 라운드 토큰 구독 모집에 대해 논의할 예정입니다!",
            body: "M3 DAO의 두 핵심 멤버인 L.A.와 Emmanuel은 MetaMars Metaverse 생태학적 계획과 8월에 예정된 시드 라운드 토큰 구독 모집에 대해 논의했습니다. 그들의 통찰력은 M3 DAO 생태계의 전략적 비전과 기회에 대한 귀중한 정보를 제공하여 미래 성장과 혁신의 기반을 마련합니다.",
            one: {
                title: "이벤트 하이라이트:",
                ul: [
                    "M3 DAO의 두 핵심 멤버인 L.A.와 Emmanuel이 MetaMars Metaverse 생태학적 계획을 논의하기 위해 생방송실에 공중에 떠 있습니다.",
                    "8월 예정된 시드 라운드 토큰 구독 모집에 대한 사용자 질문에 답변했습니다."
                ],
            },
            two: {
                title: "MetaMars 메타버스에 대해서요?",
                ul: [
                    "MetaMars는 화성을 테마로 한 Web3 메타버스 플랫폼입니다. 가상 현실, 암호경제학 및 소셜 네트워크를 통합함으로써 MetaMars는 디지털 상호 작용 및 가치 창출의 새로운 방식을 창출합니다. 이 혁신적인 디지털 세계는 화성 탐사와 블록체인 기술을 결합하여 사용자에게 독특한 몰입형 경험을 제공합니다.",
                    "MetaMars에서 참가자는 세심하게 제작된 화성의 풍경을 탐험하고, 자신만의 기지를 건설하고, 보상을 받을 수 있습니다. 동시에 전 세계의 성간 여행자들이 협력하여 우주의 신비를 탐구합니다."
                ],
            },
            three: {
                title: "MCD 토큰 정보:",
                ul: [
                    "MCD 토큰은 M3 DAO 생태계의 기본 유틸리티 토큰입니다. MarsVerse, MarsChain 및 MarsProtocol의 다양한 서비스에 대한 거버넌스, 스테이킹 및 액세스에 사용됩니다."
                ],
            },
        }
    },
    footer: {
        one: {
            title: "생태학",
            item1: "MarsVerse",
            item2: "MarsChain",
            item3: "MarsProtocol",
            item4: "Avatar",
            item5: "MarsLab",
            item6: "블록 탐색기",
        },
        two: {
            title: "지원하다",
            item1: "회사 소개",
            item2: "미디어 리소스 팩",
            item3: "문의하기",
        }
    }
}