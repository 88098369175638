<template>
	<div class="home">
		<meta name="keywords" content="m3dao" />
		<div class="banner">
			<div class="font">
				M3 DAO <br />
				{{ $t('home.slogan') }}
			</div>
			<div class="mar">
				<Vue3Marquee style="z-index: 1" :pause-on-hover="true" duration="40">
					<div class="border-box" v-for="(word, index) in helloArray" :key="index">
						{{ word }}
					</div>
				</Vue3Marquee>
				<Vue3Marquee style="z-index: 1" :pause-on-hover="true" direction="reverse" duration="40">
					<div class="border-box" v-for="(word, index) in helloArray" :key="index">
						{{ word }}
					</div>
				</Vue3Marquee>
			</div>
		</div>
		<div class="number" ref="numberbox">
			<Transition name="slide-fade">
				<div
					class="numberBox"
					style="display: flex; justify-content: space-between; width: 100%"
					v-if="numberFlag"
				>
					<div class="item">
						<div class="icon icon1"></div>
						<div class="item_n f_o">
							<countTo
								:startVal="0"
								:endVal="100000"
								:duration="2000"
								prefix=""
								suffix="+"
							></countTo>
						</div>
						<div class="item_f f_w">{{ $t('home.number1') }}</div>
					</div>
					<div class="item">
						<!-- <img src="../assets/home/item2.png" alt="" /> -->
						<div class="icon icon2"></div>
						<div class="item_n f_o">
							<countTo :startVal="0" :endVal="120" :duration="2000" prefix="" suffix="+"></countTo>
						</div>
						<div class="item_f f_w">{{ $t('home.number2') }}</div>
					</div>
					<div class="item">
						<!-- <img src="../assets/home/item3.png" alt="" /> -->
						<div class="icon icon3"></div>
						<div class="item_n f_o">
							<countTo :startVal="0" :endVal="1000" :duration="2000" prefix="" suffix="+"></countTo>
						</div>
						<div class="item_f f_w">
							<p>{{ $t('home.number3') }}</p>
							<p>{{ $t('home.number4') }}</p>
						</div>
					</div>
				</div>
			</Transition>
		</div>
		<vue-particles
			color="#fff"
			:particleOpacity="0.7"
			:particlesNumber="60"
			shapeType="circle"
			:particleSize="4"
			linesColor="#fff"
			:linesWidth="1"
			:lineLinked="true"
			:lineOpacity="0.4"
			:linesDistance="150"
			:moveSpeed="2"
			:hoverEffect="true"
			hoverMode="grab"
			:clickEffect="true"
			clickMode="push"
			class="lizi"
		>
		</vue-particles>
		<div class="box">
			<div class="left">
				<div class="top">{{ $t('home.box_title') }}</div>
				<div class="bottom">
					{{ $t('home.box_content') }}
				</div>
			</div>
			<img src="../assets/home/boxright.png" class="right" alt="" />
		</div>
		<div class="introduce">
			<!-- <img src="../assets/home/logo.png" alt="" class="logo" /> -->
			<div class="logo">
				<a href="https://www.mars-verse.io/" target="_blank">
					<img src="../assets/home/logo1.png" alt="" class="logo1" />
				</a>
				<a href="" target="_blank">
					<img src="../assets/home/logo2.png" alt="" class="logo2" />
				</a>
				<a href="/" target="_blank">
					<img src="../assets/home/logo3.png" alt="" class="logo3" />
				</a>
			</div>
			<div class="corporation">
				<div class="title">
					{{ $t('Portfolio.title') }}
				</div>
				<div class="body">
					<img src="../assets/home/corporation.png" alt="" />
					<div class="font">
						{{ $t('home.introduce') }}
					</div>
				</div>
			</div>
			<div class="blog">
				<div class="title">
					{{ $t('home.blog') }}
				</div>
				<div class="t_body">
					<div @click="go(item.src)" class="items" v-for="(item, index) in blogList" :key="index">
						<img :src="item.url" alt="" class="top" />
						<!-- <img src="../assets/home/top.png" alt="" class="top" /> -->
						<div class="bottom">
							<div class="tx"></div>
							<div class="one">{{ item.name }}</div>
							<div class="two">{{ item.font }}</div>
							<div class="three">
								<div class="left">
									<!-- <img src="../assets/home/slogo.png" alt="" /> -->
									<img :src="item.icon" alt="" class="top" />
									<span>{{ item.name }}</span>
								</div>
								<img class="right" src="../assets/home/right.png" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="dq_box">
			<div class="title">{{ $t('home.dq_title') }}</div>
			<!-- <img style="padding-top: 30px" src="../assets/home/dq.png" alt="" /> -->
			<div class="dq_reactive">
				<a class="icon_box">
					<img class="dq_img" src="../assets/home/icon.gif" alt="" />
				</a>
				<a class="icon_box">
					<img class="dq_img" src="../assets/home/icon.gif" alt="" />
				</a>
				<a class="icon_box">
					<img class="dq_img" src="../assets/home/icon.gif" alt="" />
				</a>
				<a class="icon_box">
					<img class="dq_img" src="../assets/home/icon.gif" alt="" />
				</a>
				<a class="icon_box">
					<img class="dq_img" src="../assets/home/icon.gif" alt="" />
				</a>
				<a class="icon_box">
					<img class="dq_img" src="../assets/home/icon.gif" alt="" />
				</a>
				<a class="icon_box">
					<img class="dq_img" src="../assets/home/icon.gif" alt="" />
				</a>
				<a class="icon_box">
					<img class="dq_img" src="../assets/home/icon.gif" alt="" />
				</a>
			</div>
		</div>
		<div class="content">
			<div class="left">
				<div class="one">
					{{ $t('home.bottom_title') }}
				</div>
				<div class="two">
					{{ $t('home.bottom_font1') }}
				</div>
				<div class="three">
					{{ $t('home.bottom_font2') }}
				</div>
			</div>
			<!-- <a href="https://mail.google.com/mail/u/0/#inbox?compose=new" target="_blank">
				
			</a> -->
			<div class="right" @click="router.push('/support')">
				{{ $t('home.button_button') }}
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { CountTo } from 'vue3-count-to';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import top1 from '../assets/home/top1.png';
import top2 from '../assets/home/top2.png';
import top3 from '../assets/home/top3.png';
import top4 from '../assets/home/top4.png';
import top5 from '../assets/home/top5.png';
import top6 from '../assets/home/top6.png';
import icon1 from '../assets/home/icon1.png';
import icon2 from '../assets/home/icon2.png';
import icon3 from '../assets/home/icon3.png';
import icon4 from '../assets/home/icon4.png';
import icon5 from '../assets/home/icon5.png';
import icon6 from '../assets/home/icon6.png';
const { tm } = useI18n();
const router = useRouter();

const helloArray = tm('home.helloArray');

const blogList = ref([
	{
		url: top1,
		icon: icon1,
		name: 'CERE',
		font: 'Cere Network Analysis Report',
		src: '/cere.pdf',
	},
	{
		url: top2,
		icon: icon2,
		name: 'FEI',
		font: 'Fei Protocol Investment Proposal',
		src: '/fei.pdf',
	},
	{
		url: top3,
		icon: icon3,
		name: 'CNATO',
		font: 'Public Chain Project Canto Research Report',
		src: '/cna.pdf',
	},
	{
		url: top4,
		icon: icon4,
		name: 'BTC L2',
		font: 'Analysis of L2 Opportunities',
		src: '/btc.pdf',
	},
	{
		url: top5,
		icon: icon5,
		name: 'RWA',
		font: 'Ideas and Practices of RWA',
		src: '/rwa.pdf',
	},
	{
		url: top6,
		icon: icon6,
		name: 'DePINL',
		font: 'Re-understanding DePINL',
		src: '/re.pdf',
	},
]);

const numberbox = ref(null);
const numberFlag = ref(false);

const go = (url) => {
	window.open(url, '_blank');
};

onMounted(() => {
	// 检测元素是否在视图窗口的函数
	const observer = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				// 当元素进入视窗口时触执行这里面的代码
				// console.log('元素进入视图窗口', numberbox.value);
				setTimeout(() => {
					numberFlag.value = true;
				}, 200);
			}
		});
	});
	observer.observe(numberbox.value);
});
</script>

<style scoped lang="scss">
/*
	进入和离开动画可以使用不同
	持续时间和速度曲线。
	*/
.slide-fade-enter-active {
	transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
	transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translatey(20px);
	opacity: 0;
}
@media screen and (max-width: 768px) {
	img {
		max-width: 100%;
	}
	.home {
		width: 100%;
		color: #ffffff;
		background: #000;
		.banner {
			width: 100%;
			height: 496px;
			background: url('../assets/h5Home/banner.png') no-repeat;
			background-size: 100%;
			display: flex;
			flex-direction: column;
			.font {
				text-align: center;
				padding: 167px 21px 0px;
				font-size: 24px;
				font-weight: 700;
				line-height: normal;
				text-shadow: 10 0 20px #ffffff, 0 10 20px #ffffff, -10 0 20px #ffffff, 0 -10 20px #ffffff; //设置发光效果
			}
			.mar {
				margin-top: 100px;
				width: 100%;
				height: 207px;
				.border-box {
					margin-bottom: 20px;
					padding: 18px 30px;
					margin-right: 20px;
					font-size: 18px;
					font-weight: 500;
					cursor: pointer;
					border: 1px solid rgba(255, 255, 255, 0.6);
					border-top: 0px;
					border-bottom: 0px;
					background: rgba(217, 217, 217, 0.3);
					border-radius: 20px;
					height: 60px;
				}
				.border-box:hover {
					color: #000000;
					border: 1px solid rgba(255, 255, 255, 0.6);
					background: linear-gradient(to right, #2a7bcc, #fdaa33);
				}
			}
		}
		.number {
			width: 100%;
			margin-top: 30px;
			padding: 0 0 53px;
			color: #000000;
			.numberBox {
				width: 100%;
				display: flex;
				align-items: center;
				flex-direction: column;
				.item {
					background: url(../assets/home/number.png);
					background-size: 293px 274px;
					background-repeat: no-repeat;
					width: 293px;
					height: 274px;
					padding: 47px 0 0 18px;
					margin-bottom: 20px;
					.icon {
						width: 58.85px;
						height: 58.85px;
						margin-bottom: 10px;
					}
					.icon1 {
						background: url(../assets/home/item.png);
						background-size: 58.85px 58.85px;
						background-repeat: no-repeat;
					}
					.icon2 {
						background: url(../assets/home/item2.png);
						background-size: 58.85px 58.85px;
						background-repeat: no-repeat;
					}
					.icon3 {
						background: url(../assets/home/item3.png);
						background-size: 58.85px 58.85px;
						background-repeat: no-repeat;
					}
					.item_n {
						font-size: 46.58px;
						font-weight: 600;
						margin-bottom: 10px;
					}
					.item_f {
						font-size: 18.78px;
						font-weight: 400;
					}
					.f_o {
						color: #ffffff;
					}
					.f_w {
						color: #ffffff;
					}
				}
				.item:hover {
					border-radius: 10px;
					background: linear-gradient(to bottom, #1f78d3, #fdaa33);
					.f_o {
						color: #000000;
					}
					.f_w {
						color: #000000;
					}
					.icon1 {
						background: url(../assets/home/bitem1.png);
						background-size: 58.85px 58.85px;
						background-repeat: no-repeat;
					}
					.icon2 {
						background: url(../assets/home/bitem2.png);
						background-size: 58.85px 58.85px;
						background-repeat: no-repeat;
					}
					.icon3 {
						background: url(../assets/home/bitem3.png);
						background-size: 58.85px 58.85px;
						background-repeat: no-repeat;
					}
				}
			}
		}
		.box {
			width: 100%;
			padding: 0 20px 65px;
			display: flex;
			flex-direction: column-reverse;
			text-align: center;
			.left {
				width: 100%;
				.top {
					width: 336px;
					font-size: 26px;
					margin-bottom: 24px;
					font-weight: 600;
				}
				.bottom {
					width: 312px;
					font-size: 16px;
					font-weight: 400;
				}
			}
			.right {
				width: 100%;
				margin-bottom: 39px;
			}
		}
		.introduce {
			padding: 0 22px 0;
			width: 100%;
			// height: 904px;
			background: url('../assets/h5Home/introduce.png') no-repeat;
			background-size: 375px 904px;
			.logo {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				width: 100%;
				padding: 0 0 100px 64px;
				.logo1 {
					margin-bottom: 40px;
					width: 199.52px;
					height: 30.84px;
				}
				.logo2 {
					margin-bottom: 40px;
					width: 178.37px;
					height: 26.31px;
				}
				.logo3 {
					width: 207.33px;
				}
			}
			.corporation {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				font-size: 28px;
				font-weight: 700;
				margin-bottom: 83.64px;
				.body {
					margin-top: 23px;
					width: 330px;
					height: 172.36px;
					position: relative;
					img {
						width: 330px;
						height: 172.36px;
					}
					.font {
						width: 244px;
						position: absolute;
						top: 78.97px;
						left: 43px;
						font-size: 12px;
						font-weight: 400;
					}
				}
			}
			.blog {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				.title {
					font-weight: 700;
					font-size: 28px;
					margin-bottom: 38px;
				}
				.t_body {
					width: 100%;
					display: flex;
					flex-direction: column;
					.items {
						width: 330px;
						height: 286px;
						margin-bottom: 20px;
						cursor: pointer;

						.top {
							width: 330px;
							height: 170px;
							margin: 0;
						}
						.bottom {
							background: #de8302;
							background: #242424;
							width: 330px;
							height: 116px;
							padding: 15px 32px 20px 14px;
							box-sizing: border-box;
							position: relative;
							margin-top: -8px;
							.tx {
								width: 100px;
								height: 0;
								position: absolute;
								right: 0;
								top: -19px;
								z-index: 2;
								border-bottom: 20px solid #242424;
								border-left: 10px solid transparent;
							}
							.one {
								font-size: 21.96px;
								font-weight: 500;
								width: 330px;
								overflow: hidden; //超出的文本隐藏
								text-overflow: ellipsis; //溢出用省略号显示
								white-space: nowrap; //溢出不换行
								margin-bottom: 2px;
							}
							.two {
								font-size: 13.18px;
								font-weight: 400;
								width: 330px;
								overflow: hidden; //超出的文本隐藏
								text-overflow: ellipsis; //溢出用省略号显示
								white-space: nowrap; //溢出不换行
							}
							.three {
								font-size: 16.1px;
								font-weight: 400;
								margin-top: 14px;
								width: 100%;
								height: 28px;
								display: flex;
								align-items: center;
								justify-content: space-between;
								.left {
									display: flex;
									align-items: center;
									img {
										width: 27.81px;
										height: 27.81px;
										margin-right: 8.78px;
										border-radius: 50%;
									}
								}
								.right {
									width: 24.89px;
									height: 24.89px;
								}
							}
						}
					}
					.items:hover {
						.tx {
							border-bottom-color: #1f78d3;
						}
						.bottom {
							background-color: #1f78d3;
						}
					}
				}
			}
		}
		.dq_box {
			margin-top: 57px;
			padding: 0 20px;
			width: 100%;
			.title {
				width: 100%;
				text-align: center;
				font-size: 28px;
				font-weight: 700;
				margin-bottom: 30px;
			}
			.dq_reactive {
				width: 100%;
				height: 114.43px;
				background: url('../assets/home/dq.png');
				background-size: 100% 114.43px;
				position: relative;
				.icon_box {
					width: 12px;
					// height: 70px;
					box-sizing: border-box;
					position: absolute;
					.dq_img {
						width: 100%;
					}
				}
				.icon_box:nth-child(1) {
					top: 6.86px;
					right: 22.45px;
				}
				.icon_box:nth-child(2) {
					top: 38.18px;
					right: 64.89px;
				}
				.icon_box:nth-child(3) {
					top: -3.62px;
					right: 102.93px;
				}
				.icon_box:nth-child(4) {
					top: 63.92px;
					right: 147px;
				}
				.icon_box:nth-child(5) {
					left: 189px;
					top: 7px;
				}
				.icon_box:nth-child(6) {
					top: 63.23px;
					right: 227.48px;
				}
				.icon_box:nth-child(7) {
					top: 11px;
					right: 270px;
				}
				.icon_box:nth-child(8) {
					top: 4.84px;
					right: 310px;
				}
			}
		}
		.content {
			margin-top: 39.57px;
			width: 100%;
			height: 506px;
			padding: 38px 38px 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background: linear-gradient(to left, rgba(253, 170, 51, 0.6), rgba(31, 120, 211, 0.6));
			.left {
				width: 100%;
				text-align: center;
				margin-bottom: 42px;
				.one {
					font-weight: 600;
					font-size: 28px;
					margin-bottom: 22px;
				}
				.two {
					width: 100%;
					font-weight: 400;
					font-size: 20px;
					margin-bottom: 42px;
				}
			}
			.right {
				min-width: 173px;
				height: 45px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				background: rgba(221, 142, 28, 1);
				font-size: 20px;
				// line-height: 20px;
				border-radius: 10px;
				color: #000000;
			}
			.right:hover {
				background: rgba(253, 170, 51, 1);
			}
		}
	}
}
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.home {
		width: 100%;
		color: #ffffff;
		background: #000;
		padding-bottom: 214px;
		.banner {
			width: 100%;
			height: 970px;
			background: url('../assets/home/banner.png') no-repeat;
			background-size: 100% 100%;

			.font {
				padding: 258px 0 243px 311px;
				font-size: 70px;
				font-weight: 700;
				line-height: normal;
				text-shadow: 10 0 20px #ffffff, 0 10 20px #ffffff, -10 0 20px #ffffff, 0 -10 20px #ffffff; //设置发光效果
			}
			.mar {
				width: 100%;
				height: 224px;
				.border-box {
					margin-bottom: 40px;
					padding: 20px 30px;
					margin-right: 20px;
					font-size: 28px;
					font-weight: 500;
					cursor: pointer;
					border: 2px solid rgba(255, 255, 255, 0.6);
					border-top: 0px;
					border-bottom: 0px;
					background: rgba(217, 217, 217, 0.3);
					border-radius: 20px;
				}
				.border-box:hover {
					color: #000000;
					border: 1px solid rgba(255, 255, 255, 0.6);
					background: linear-gradient(to right, #2a7bcc, #fdaa33);
				}
			}
		}
		.number {
			width: 100%;
			padding: 0 240px 107px;
			display: flex;
			justify-content: space-between;
			color: #000000;
			.item {
				background: url(../assets/home/number.png);
				background-size: 468px 438px;
				background-repeat: no-repeat;
				width: 468px;
				height: 438px;
				padding: 76px 30px 76px 30px;
				border-radius: 10px;
				.icon {
					width: 94px;
					height: 94px;
					margin-bottom: 10px;
				}
				.icon1 {
					background: url(../assets/home/item.png);
					background-size: 94px 94px;
					background-repeat: no-repeat;
				}
				.icon2 {
					background: url(../assets/home/item2.png);
					background-size: 94px 94px;
					background-repeat: no-repeat;
				}
				.icon3 {
					background: url(../assets/home/item3.png);
					background-size: 94px 94px;
					background-repeat: no-repeat;
				}
				.item_n {
					font-size: 74px;
					font-weight: 600;
					margin-bottom: 20px;
				}
				.item_f {
					font-size: 30px;
					font-weight: 400;
				}
				.f_o {
					color: #ffffff;
				}
				.f_w {
					color: #ffffff;
				}
			}
			.item:hover {
				background: linear-gradient(to bottom, #1f78d3, #fdaa33);
				.f_o {
					color: #000000;
				}
				.f_w {
					color: #000000;
				}
				.icon1 {
					background: url(../assets/home/bitem1.png);
					background-size: 94px 94px;
					background-repeat: no-repeat;
				}
				.icon2 {
					background: url(../assets/home/bitem2.png);
					background-size: 94px 94px;
					background-repeat: no-repeat;
				}
				.icon3 {
					background: url(../assets/home/bitem3.png);
					background-size: 94px 94px;
					background-repeat: no-repeat;
				}
			}
		}
		.box {
			width: 100%;
			padding: 0 240px;
			height: 606px;
			display: flex;
			justify-content: space-between;
			.left {
				width: 720px;
				.top {
					width: 690px;
					font-size: 50px;
					margin-bottom: 50px;
					font-weight: 600;
				}
				.bottom {
					width: 616px;
					font-size: 22px;
					font-weight: 400;
				}
			}
			.right {
				width: 715px;
				height: 606px;
			}
		}
		.introduce {
			padding: 0 240px;
			width: 100%;
			height: 1889px;
			background: url('../assets/home/introduce.png');
			background-size: cover;
			.logo {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				width: 100%;
				padding: 110px 0 180px;
				.logo1 {
					width: 295.74px;
					height: 45.71px;
				}
				.logo2 {
					width: 264.4px;
					height: 39px;
				}
				.logo3 {
					width: 307.33px;
					height: 32.84px;
				}
			}
			.corporation {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				font-size: 60px;
				font-weight: 700;
				margin-bottom: 157px;
				.body {
					margin-top: 53px;
					width: 625px;
					height: 328px;
					position: relative;
					img {
						width: 625px;
						height: 328px;
					}
					.font {
						width: 458px;
						position: absolute;
						top: 152px;
						left: 82px;
						font-size: 22px;
						font-weight: 400;
					}
				}
			}
			.blog {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				.title {
					font-weight: 700;
					font-size: 60px;
					margin-bottom: 50px;
				}
				.t_body {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					// flex-flow: row;
					justify-content: space-between;
					.items {
						width: 450px;
						height: 390px;
						margin-bottom: 35px;
						cursor: pointer;

						.top {
							width: 450px;
							height: 230px;
							margin: 0;
						}
						.bottom {
							background: #de8302;
							background: #242424;
							width: 450px;
							height: 160px;
							padding: 20px 32px 20px 20px;
							box-sizing: border-box;
							position: relative;
							margin-top: -8px;
							.tx {
								width: 100px;
								height: 0;
								position: absolute;
								right: 0;
								top: -19px;
								z-index: 2;
								border-bottom: 20px solid #242424;
								border-left: 10px solid transparent;
							}
							.one {
								font-size: 30px;
								font-weight: 500;
								width: 382px;
								overflow: hidden; //超出的文本隐藏
								text-overflow: ellipsis; //溢出用省略号显示
								white-space: nowrap; //溢出不换行
								margin-bottom: 2px;
							}
							.two {
								font-size: 18px;
								font-weight: 400;
								width: 382px;
								overflow: hidden; //超出的文本隐藏
								text-overflow: ellipsis; //溢出用省略号显示
								white-space: nowrap; //溢出不换行
							}
							.three {
								font-size: 22px;
								font-weight: 400;
								margin-top: 18px;
								width: 100%;
								height: 38px;
								display: flex;
								align-items: center;
								justify-content: space-between;
								.left {
									display: flex;
									align-items: center;
									img {
										width: 38px;
										height: 38px;
										margin-right: 12px;
										border-radius: 50%;
									}
								}
								.right {
									width: 34px;
									height: 34px;
								}
							}
						}
					}
					.items:hover {
						.tx {
							border-bottom-color: #1f78d3;
						}
						.bottom {
							background-color: #1f78d3;
						}
					}
				}
			}
		}
		.dq_box {
			margin-top: 150px;
			padding: 0 240px;
			width: 100%;
			height: 618px;
			.title {
				width: 100%;
				height: 75px;
				text-align: center;
				line-height: 75px;
				font-size: 60px;
				font-weight: 700;
				margin-bottom: 80px;
			}
			.dq_reactive {
				width: 1440px;
				height: 463px;
				background: url('../assets/home/dq.png');
				background-size: 1440px 463px;
				position: relative;
				.icon_box {
					width: 74px;
					// height: 70px;
					box-sizing: border-box;
					position: absolute;
					.dq_img {
						width: 100%;
					}
				}
				.icon_box:nth-child(1) {
					right: 443.7px;
					top: 15px;
				}
				.icon_box:nth-child(2) {
					right: 96.77px;
					top: 29px;
				}
				.icon_box:nth-child(3) {
					left: 586px;
					top: -31px;
				}
				.icon_box:nth-child(4) {
					left: 234px;
					top: 48px;
				}
				.icon_box:nth-child(5) {
					left: 63px;
					top: 21px;
				}
				.icon_box:nth-child(6) {
					top: 164px;
					right: 279.77px;
				}
				.icon_box:nth-child(7) {
					left: 767px;
					top: 275px;
				}
				.icon_box:nth-child(8) {
					left: 420px;
					top: 272px;
				}
			}
		}
		.content {
			margin-top: 146px;
			width: 100%;
			height: 360px;
			padding: 76px 368px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: linear-gradient(to left, rgba(253, 170, 51, 0.6), rgba(31, 120, 211, 0.6));
			// color: #000000;
			.left {
				width: 732px;
				.one {
					font-weight: 600;
					font-size: 36px;
					margin-bottom: 14px;
				}
				.two {
					width: 692px;
					font-weight: 400;
					font-size: 22px;
					margin-bottom: 50px;
				}
			}
			.right {
				min-width: 180px;
				height: 48px;
				padding: 10px 48px;
				background: rgba(221, 142, 28, 1);
				font-size: 22px;
				line-height: 28px;
				color: #000000;
			}
			.right:hover {
				background: rgba(253, 170, 51, 1);
			}
		}
	}
}
</style>
