import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./language"
import Vue3Marquee from 'vue3-marquee'
import countTo from 'vue3-count-to'
import VueParticles from 'vue-particles'
import '@/utils/rem.js'
import 'vue-transitions-css'
import './assets/style/font/font.css';

const app = createApp(App);
app.use(i18n).use(Vue3Marquee).use(countTo).use(VueParticles).use(router).mount("#app");


router.beforeEach((to, from, next) => {
    /* 路由发生变化时遍历修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    // chrome
    document.body.scrollTop = 0;
    // firefox
    document.documentElement.scrollTop = 0;
    // safari
    window.pageYOffset = 0;
    next();
})